import dayjs from 'dayjs'
import { useAppSelector } from '../../../../store/hook';
import { CommonUtil } from '../../../../utils/CommonUtil';
import { FileUtil } from '../../../../utils/FileUtil';
import { CommonConstant } from '../../../../constants/CommonConstant';

export default function ItemInboxIcon(props: any) {
    const { item } = props
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    return (
        <li className={`${item.userUid == userInfo.userUid ? 'right' : ''}`}>
            <div className="conversation-list">
                <div className="chat-avatar">
                    {
                        (item.imgPath && item.imgPath != '') ? (
                            <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                onError={(event: any) => {
                                    event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                }}
                                className="rounded-circle avatar-xs" alt="" />
                        ) : (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                    {
                                        CommonUtil.generateCharacterAvatar(item.fullName)
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="user-chat-content">
                    <div className="ctext-wrap">
                        <div className="">
                            <ul className="list-inline message-img  mb-0">
                                <li className="list-inline-item message-img-list ms-0">
                                    <div>
                                        <a className="popup-img d-inline-block" title="">
                                            <img src={FileUtil.genarateUrlGetImage(item.messPath, CommonConstant.FILE_THUMB)}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../../../assets/images/icons8-image-96.png")
                                                }}
                                                className="img-thumb-icon-default-height" style={{ height: "94px" }} alt="" />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                            <p className="mb-0 font-size-12"><i className="ri-time-line"></i> <span className="">{(item.createdDate ? dayjs(item.createdDate).format('YYYY-MM-DD HH:mm') : 'underfined')}</span></p>
                        </div>
                    </div>
                    <div className="conversation-name">{item.userUid != userInfo.userUid ? item?.fullName : <>&nbsp;</>}</div>
                </div>
            </div>
        </li>
    )
}
