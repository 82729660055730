import { configureStore } from "@reduxjs/toolkit";

import userInfoSlice from '../reducers/userSlice'
import noticeSlice from '../reducers/noticeSlice'
import chatSlice from '../reducers/chatSlice'
import spinnerSlice from '../reducers/spinnerSlice'
import onlineSlice from '../reducers/onlineSlice'
import commentSlice from '../reducers/commentSlice'

const store = configureStore({
    reducer: {
        userInfo: userInfoSlice,
        notice: noticeSlice,
        chat: chatSlice,
        spinner: spinnerSlice,
        onlineUsers: onlineSlice,
        comment: commentSlice,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ serializableCheck: false })
    }
})


export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch