import React, { useEffect } from 'react'
import { FileUtil } from '../utils/FileUtil'
import { CommonConstant } from '../constants/CommonConstant'

export default function MagnificPopup(props: any) {
    const { item, onClosePre } = props
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClosePre()
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    return (
        <>
            <div className="mfp-bg mfp-img-mobile mfp-ready"></div>
            <div className="mfp-wrap mfp-close-btn-in mfp-img-mobile mfp-ready" tabIndex={-1}>
                <div className="mfp-container mfp-s-ready mfp-image-holder">
                    <button type="button" className="mfp-close me-2" onClick={() => {
                        onClosePre()
                    }}>×</button>
                    <div className="mfp-content">
                        <div className="mfp-figure">
                            <img src={FileUtil.genarateUrlGetImage(item.messPath, CommonConstant.FILE_ORIGIN)}
                                onError={(event: any) => {
                                    event.target.src = require("../../assets/images/not_image.png")
                                }}
                                className="rounded border" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
