import React, { useState, useEffect, useRef } from 'react'
import ItemNotification from './comp/ItemNotification'
import { CommonConstant } from '../../../constants/CommonConstant';
import { NotificationService } from '../../../services/chat/notifications/NotificationService';
import { useAppDispatch } from '../../../store/hook';
import { setInitNewNotice } from '../../../reducers/noticeSlice';
import ModalComp from '../../../components/ModalComp';
import PopupNotification from './popup/PopupNotification';
import PopupBirthDayNotification from './popup/PopupBirthDayNotification';
import NewsItemDetail from '../../newsfeed/NewsItemDetail';
import { useNavigate } from 'react-router-dom';

export default function Notification() {
    const [notices, setNotices] = useState<any>([])
    const [newNotice, setNewNotice] = useState<any>({})
    const listInnerRef = useRef<any>();
    const isLoad = useRef(true);
    const offset = useRef(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const loadMore = () => {
        isLoad.current = false;
        NotificationService.getInstance().getListNotification({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: "" })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    if (resp.data.responseData.length == CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    setNotices((prev: any) => {
                        return [...prev, ...resp.data.responseData]
                    });
                } else {

                }
            })
            .catch((err: any) => {

            })
        offset.current += CommonConstant.LIMIT;
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore()
                }
            }
        }
    };

    useEffect(() => {
        loadMore();
        const unsubSendNoti = NotificationService.getInstance()._sendNoticeModel.subscribe((resp: any) => {
            if (resp.id) {
                setNewNotice(resp);
                NotificationService.getInstance().change({});
            }
        })
        setTimeout(() => {
            dispatch(setInitNewNotice(0))
        }, 3000)
        return () => {
            unsubSendNoti.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (newNotice.id) {
            setNotices([newNotice, ...notices]);
        }
    }, [newNotice])

    useEffect(() => {
        listInnerRef.current = document.getElementById('_notification-list')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [notices])

    const removeNotice = (noticeId: number) => {
        var array = [...notices]
        var index = array.findIndex(e => e.id == noticeId)
        if (index == -1) {
            return;
        }
        array.splice(index, 1)
        setNotices(array);
    }

    const [item, setItem] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [showNewsfeed, setShowNewsfeed] = useState(false);
    const newsfeed = useRef<any>();
    const selectItem = (item: any) => {
        setItem(item)
    }
    useEffect(() => {
        if (item != null) {
            if (item.noticeType != "01-03" && item.noticeType != "01-04") {
                setShow(true);
            } else {
                let obj = {
                    noticeType: item.noticeType,
                    noticeId: item.id,
                    noticeKey: item.noticeKey
                }
                NotificationService.getInstance().getNewsfeed(obj).then((resp: any) => {
                    if (resp.data.status == CommonConstant.RESULT_OK) {
                        newsfeed.current = resp.data.responseData
                        setShowNewsfeed(true)
                    }
                }).catch((error: any) => {

                })
                if (item.isNew == CommonConstant.YES) {
                    NotificationService.getInstance().updateLastViewNotice(item.id).then((resp: any) => {

                    }).catch((error: any) => {

                    })
                }
            }
        }
    }, [item])

    useEffect(() => {
        if (show) {
            $("#showNotification").modal("show");
        }
        if (showNewsfeed) {
            $("#showNotificationNewsfeed").modal("show");
        }
    }, [show, showNewsfeed])

    return (
        <div>
            <div className="px-4 pt-4">
                <div className="user-chat-nav float-end">
                    <div title="Notification">
                        <span className="badge bg-warning rounded-pill">
                            {/* <i className="ri-notification-3-line"></i> */}
                        </span>
                    </div>
                </div>
                <h4 className="mb-4">Notification</h4>
                {
                    show && (
                        <ModalComp id="showNotification" onClose={() => {
                            $("#showNotification").modal("hide");
                            setShow(false)
                        }} title={(item != null && item.noticeType == CommonConstant.NOTICE_REQ_FREIND) ? "Friend request" : "Happy birthday"}>
                            {
                                (item != null && item.noticeType == CommonConstant.NOTICE_REQ_FREIND) ? <PopupNotification onClose={() => {
                                    $("#showNotification").modal("hide");
                                    setShow(false)
                                }} data={item} callBack={removeNotice} /> : <PopupBirthDayNotification data={item} />
                            }
                        </ModalComp>
                    )
                }

                {
                    showNewsfeed && (
                        <ModalComp id="showNotificationNewsfeed" onClose={() => {
                            $("#showNotificationNewsfeed").modal("hide");
                            setShowNewsfeed(false)
                        }} classSize="modal-lg">
                            <div className='feed-body' style={{ maxHeight: "100%", overflow: "auto" }}>
                                <NewsItemDetail item={newsfeed.current} goToMyPage={(userUid: string) => {
                                    $("#showNotificationNewsfeed").modal("hide");
                                    newsfeed.current = null;
                                    setShowNewsfeed(false)
                                    navigate("/chat/profile?profileId=" + userUid)
                                }} />
                            </div>
                        </ModalComp>
                    )
                }

            </div>
            <div id='_notification-list' className="chat-notification-list px-2" data-simplebar>
                <div>
                    <ul className="list-unstyled chat-notice contact-list chat-list chat-user-list">
                        {
                            notices.length > 0 &&
                            notices.map((p: any) => {
                                return <ItemNotification selectItem={selectItem} key={p.id} data={p} />
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
