import React, { useEffect, useRef, useState } from 'react'
import ItemGroup from './comp/ItemGroup';
import { CommonConstant } from '../../../constants/CommonConstant';
import ModalComp from '../../../components/ModalComp';
import NewGroupPopup from './comp/NewGroupPopup';
import { RoomService } from '../../../services/chat/mess/RoomService';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { MessService } from '../../../services/chat/mess/MessService';
import { setNewMessInc } from '../../../reducers/noticeSlice';

export default function Groups() {
    const listInnerRef = useRef<any>();
    const [groups, setGroups] = useState<any>([])
    const isLoad = useRef(true);
    const offset = useRef(0);
    const roomIdSel = useRef(0);

    const [showNewGroup, setShowNewGroup] = useState(false)
    const dispatch = useAppDispatch()

    const chat = useAppSelector(state => state.chat.selectedRoom)

    const loadMore = (type: number) => {
        isLoad.current = false;
        RoomService.getInstance().getListChatRoomGroup({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: "" })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    if (resp.data.responseData.length == CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    if (type == 0) {
                        setGroups(resp.data.responseData)
                    } else {
                        setGroups((prev: any) => {
                            return [...prev, ...resp.data.responseData]
                        });
                    }
                } else {

                }
            })
            .catch((err: any) => {

            })
        offset.current += CommonConstant.LIMIT;
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    useEffect(() => {
        loadMore(0);
    }, [])

    useEffect(() => {
        listInnerRef.current = document.getElementById('_groups-list')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [groups])

    useEffect(() => {
        if (chat.roomId != 0) {
            roomIdSel.current = chat.roomId
        }
    }, [chat])

    useEffect(() => {

        const tempSend = MessService.getInstance()._sendMess.subscribe((messDto: any) => {
            if (messDto.id) {
                var array: any = [...groups]
                var index = array.findIndex((e: any) => e.id == messDto.roomId)
                if (index == -1) return;
                array[index]['messDto'] = messDto
                let room = array[index];
                room['updatedDate'] = new Date();
                room['lastViewed'] = 'Y';
                array.splice(index, 1)
                setGroups([room, ...array])
                MessService.getInstance().change({})
            }
        })
        const tempReceive = MessService.getInstance()._receiveMess.subscribe((messDto: any) => {
            if (messDto.id) {

                if (messDto.roomId == roomIdSel.current) {
                    MessService.getInstance().updateLastView(roomIdSel.current).then((resp: any) => {

                    }).catch((err: any) => {

                    })
                }

                var array: any = [...groups]
                var index = array.findIndex((e: any) => e.id == messDto.roomId)
                if (index == -1) return;
                array[index]['messDto'] = messDto
                let room = array[index];
                room['updatedDate'] = new Date();
                if (array[index]['lastViewed'] == 'Y' && array[index]['id'] != roomIdSel.current) {
                    array[index]['lastViewed'] = 'N'
                }
                array.splice(index, 1)
                MessService.getInstance().changeReceiver({})
                setGroups([room, ...array])
            }
        })

        const unsubSendRoom = RoomService.getInstance()._sendRoomModel.subscribe((resp: any) => {
            if (resp.id) {
                setGroups([resp, ...groups]);
                RoomService.getInstance().change({});
            } else if (resp.leaveRoomId) {
                var array: any = [...groups]
                var index = array.findIndex((e: any) => e.id == resp.leaveRoomId);
                if (index == -1) return;
                array.splice(index, 1)
                setGroups(array);
                RoomService.getInstance().change({});
            }
        })

        return () => {
            unsubSendRoom.unsubscribe();
            tempSend.unsubscribe()
            tempReceive.unsubscribe()
        }
    }, [groups])

    const handleShowPopup = () => {
        setShowNewGroup(true)
    }

    useEffect(() => {
        if (showNewGroup) {
            $("#showNewGroupPopup").modal("show");
        }
    }, [showNewGroup])

    const createdRoomGroup = (roomDto: any) => {
        setGroups((prev: any) => {
            return [roomDto, ...prev]
        });
    }

    return (
        <div>
            {
                showNewGroup && (
                    <ModalComp id="showNewGroupPopup" onClose={() => {
                        $("#showNewGroupPopup").modal("hide");
                        setShowNewGroup(false)
                    }} title="Create new group">
                        <NewGroupPopup onClose={() => {
                            $("#showNewGroupPopup").modal("hide");
                            setShowNewGroup(false)
                        }} callBack={createdRoomGroup} />
                    </ModalComp>
                )
            }
            <div className="p-4">
                <div className="user-chat-nav float-end">
                    <div title="Add Groups">
                        <button type="button" className="btn btn-link text-decoration-none text-muted font-size-18 py-0 p-0">
                            <i className="ri-group-line me-1 ms-0"></i>
                        </button>
                    </div>
                </div>
                <h4 className="mb-4">Groups</h4>
                <div className="input-group rounded-3" style={{ textAlign: "center", cursor: "pointer" }} onClick={handleShowPopup}>
                    <span className="form-control bg-light text-grey-500 font-xssss fw-500">Create a chat group</span>
                </div>
            </div>
            <div id='_groups-list' className="px-2 chat-group-list" data-simplebar>
                <div>
                    <ul className="list-unstyled chat-list contact-list-curson">
                        {
                            groups.length > 0 &&
                            groups.map((p: any) => {
                                return <ItemGroup key={p.id} data={p} />
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
