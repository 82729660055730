import React from 'react'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { FileUtil } from '../../../../utils/FileUtil'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { useAppSelector } from '../../../../store/hook'

export default function UserFav(props: any) {
    const { item } = props
    const onlineUsers = useAppSelector(state => state.onlineUsers.onlineUsers)
    return (
        <div className='owl-item active user-fav'>
            <div className="item">
                <a className="user-status-box">
                    {
                        (item.imgPath && item.imgPath != '') ? (
                            <div className={`avatar-xs mx-auto d-block chat-user-img ${CommonUtil.checkOnline(onlineUsers, [{ userUid: item.userUid }])}`}>
                                <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="img-fluid rounded-circle" alt="" />
                                <span className="user-status"></span>
                            </div>
                        ) : (
                            <div className={`avatar-xs mx-auto d-block chat-user-img ${CommonUtil.checkOnline(onlineUsers, [{ userUid: item.userUid }])}`}>
                                <span className="img-fluid rounded-circle avatar-title rounded-circle bg-primary-subtle text-primary">
                                    {
                                        CommonUtil.generateCharacterAvatar(item.fullName)
                                    }
                                </span>
                                <span className="user-status"></span>
                            </div>
                        )
                    }

                    <h5 className="font-size-13 text-truncate mt-3 mb-1">
                        {
                            CommonUtil.getFirstName(item.fullName)
                        }
                    </h5>
                </a>
            </div>
        </div>
    )
}
