import axios from "axios";
import { Paging } from "../model/Paging";
import { ParamUtil, RequestParam } from "../utils/paramUtil";
import { ApiUrlUtil } from "../utils/apiUrlUtil";
import { HeadersUtil } from "../utils/headersUtil";

export class NewsService {

    private static _newsService: NewsService;

    public static getInstance(): NewsService {
        if (!NewsService._newsService) {
            NewsService._newsService = new NewsService();
        }
        return NewsService._newsService;
    }

    public getListNews(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/news/search", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public createNewsFeed(model: any) {
        return axios.post(process.env.REACT_APP_API_URL + "/vts/newsfeed/create", model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getNewsFeed(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/newsfeed/getList", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public interact(model: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/newsfeed/interact", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListComment(newsfeedId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/newsfeed/getList/${newsfeedId}/comment`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public saveComment(model: any) {
        return axios.post(process.env.REACT_APP_API_URL + "/vts/comment/save", model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public deleteComment(id: number) {
        return axios.delete(process.env.REACT_APP_API_URL + "/vts/comment/" + id + "/delete", {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getNewsFeedMySelf(model: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/newsfeed/getListMySelf", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public removeNewsfeed(id: number) {
        return axios.delete(process.env.REACT_APP_API_URL + "/vts/newsfeed/remove/" + id + "/removeNewfeed", {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListInteract(newsfeedId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/newsfeed/getList/${newsfeedId}/interact`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public saveStory(model: any) {
        return axios.post(process.env.REACT_APP_API_URL + "/vts/newsfeed/story/save", model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListStory(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/newsfeed/story/getList", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListStoryForProfile(model: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/newsfeed/story/getListForProfile", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public removeStory(id: number) {
        return axios.delete(process.env.REACT_APP_API_URL + "/vts/newsfeed/remove/" + id + "/removeStory", {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public setWatchedStory(storyId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/newsfeed/story/${storyId}/watched`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}