import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify';
import { RoomService } from '../../../../services/chat/mess/RoomService';
import ItemFriend from './ItemFriend';
import { CommonConstant } from '../../../../constants/CommonConstant';

export default function NewGroupPopup(props: any) {


    const { onClose, callBack } = props

    const [model, setModel] = useState<any>({ roomName: "" });
    const [keySearch, setKeySearch] = useState("");
    const [friends, setFriend] = useState<any>([])

    const handleChange = (event: any) => {
        setModel({
            ...model,
            [event.target.name]: event.target.value
        })
    }

    const loadMore = (type: number) => {
        isLoad.current = false;
        RoomService.getInstance().getFriend({ limit: 10, offset: offset.current, keySearch: keySearch }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                if (resp.data.responseData.length == CommonConstant.LIMIT) {
                    isLoad.current = true;
                }
                if (type == 0) {
                    setFriend(resp.data.responseData)
                } else {
                    setFriend((prev: any) => {
                        return [...prev, ...resp.data.responseData]
                    });
                }
            }
        }).catch((err: any) => {

        })
        offset.current += 10;
    }

    useEffect(() => {
        loadMore(0)
    }, [])

    const handleSave = () => {
        if (chk()) {
            let arr = friends.filter((e: any) => e['IS_CHECKED'] == 'Y')
            const obj = {
                roomName: model.roomName,
                listUser: arr
            }
            RoomService.getInstance().createRoomGroup(obj).then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    onClose()
                    callBack(resp.data.responseData)
                }
            }).catch((err: any) => {

            })
        }
    }

    const chk = () => {
        if (model.roomName == undefined || model.roomName == "") {
            toast.error("Group name cannot be empty")
            return false;
        }

        var index = friends.findIndex((e: any) => e.IS_CHECKED == 'Y')
        if (index == -1) {
            toast.error("No member selected yet")
            return false;
        }

        return true;
    }

    const listInnerRef = useRef<any>();
    const isLoad = useRef(true);
    const offset = useRef(0);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 > scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    useEffect(() => {
        listInnerRef.current = document.getElementById('_div-contacts-list');
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [friends])

    const handleChangeSearch = (event: any) => {
        setKeySearch(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            offset.current = 0;
            loadMore(0);
        }
    }

    const handleSearch = () => {
        offset.current = 0;
        loadMore(0);
    }

    const handleSelectUser = (isCheck: boolean, userUid: string) => {
        var array = [...friends]
        var index = array.findIndex(e => e.userUid == userUid)
        if (index == -1) {
            return;
        }
        if (isCheck) {
            array[index]['IS_CHECKED'] = 'Y';
        } else {
            array[index]['IS_CHECKED'] = 'N';
        }
        setFriend([...array])
    }

    return (
        <>
            <div className="modal-body p-4">
                <div className="mb-3">
                    <label className="form-label">Group name <strong className='color-red'>*</strong></label>
                    <input type="text" onChange={handleChange} name='roomName' value={model.roomName} className="form-control" placeholder="Enter group name" />
                </div>
                <div className="vts-scroll">
                    <label className="form-label">Group members <strong className='color-red'>*</strong></label>

                    <div className="search-box chat-search-box">
                        <div className="input-group bg-light  input-group-lg rounded-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-link text-decoration-none text-muted pe-1 ps-3" type="button" onClick={handleSearch}>
                                    <i className="ri-search-line search-icon font-size-18"></i>
                                </button>
                            </div>
                            <input type="text" value={keySearch} onKeyUp={handleKeyPress} onChange={handleChangeSearch} className="form-control bg-light" placeholder="Search users.." />
                        </div>
                    </div>

                    <div id='_div-contacts-list' className="px-2 div-contacts-list mt-1">
                        <ul className="list-unstyled chat-list contact-list-curson">
                            {
                                friends.length > 0 &&
                                friends.map((p: any) => {
                                    return <ItemFriend key={p.userUid} item={p} selectUser={handleSelectUser} />
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSave}>Create</button>
            </div>
        </>
    )
}
