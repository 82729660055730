import React, { useCallback, useEffect, useState, useRef } from 'react'
import { FileUtil } from '../../utils/FileUtil';
import { CommonConstant } from '../../constants/CommonConstant';
import { CommonUtil } from '../../utils/CommonUtil';
import Plyr from 'plyr-react'
import { NewsService } from '../../services/NewsService';
import CommentItem from './comp/CommentItem';
import { useAppSelector } from '../../store/hook';

export default function NewsItemDetail(props: any) {
    const { goToMyPage } = props
    const [item, setItem] = useState<any>(props.item);
    const [comments, setComments] = useState<any>([])
    const [showMedia, setShowMedia] = useState(false);

    useEffect(() => {
        NewsService.getInstance().getListComment(item.id).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                setComments(resp.data.responseData);
            }
        }).catch((error: any) => {

        })
    }, [])

    const viewImage = useRef<any>({})
    const viewImageIdx = useRef(0)

    const [plyrs, setPlyrs] = useState<any>([]);

    useEffect(() => {
        const newPlyrs = item.videos.map((p: any, index: number) => {
            return (
                <Plyr
                    key={index}
                    source={
                        {
                            type: 'video',
                            sources: [{ src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm }]
                        }}
                />
            );
        });
        setPlyrs(newPlyrs);
        return () => {
            // You may need to implement logic here to destroy Plyr instances if necessary
        };
    }, []);

    const generate = useCallback(() => {
        if (item.photos.length != 0) {
            return <>
                <div className="row ps-2 pe-2">
                    {
                        item.photos.map((p: any, index: number) => {
                            return <div key={p.atchFleSeq} className={`col-12 ${item.photos.length > 1 ? "col-sm-6" : ""} p-1`}><a data-lightbox="roadtrip" className='newfeed-post' onClick={() => {
                                viewImage.current = p
                                viewImageIdx.current = index
                                setShowMedia(true)
                            }}><img
                                    src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../assets/images/not_image.png")
                                    }}
                                    className="rounded-3 img-height" alt="image" style={{ maxWidth: "100%" }} /></a></div>
                        })
                    }
                </div>
            </>
        } else if (item.videos.length != 0) {
            return <div className="row ps-2 pe-2">
                {
                    // item.videos.map((p: any, index: number) => {
                    //     return <div key={p.atchFleSeq} className={`col-12 col-sm-12 p-1`}>
                    //         <Plyr
                    //             source={{
                    //                 type: 'video',
                    //                 sources: [{
                    //                     src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm
                    //                 }]
                    //             }} />
                    //     </div>
                    // })
                    plyrs.map((plyr: any, index: number) => (
                        <div className={`col-12 col-sm-12 p-1`} key={index}>
                            {plyr}
                        </div>
                    ))
                }
            </div>
        }
    }, [plyrs])

    const [commentText, setCommentText] = useState("");
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const handleChange = (event: any) => {
        setCommentText(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            NewsService.getInstance().saveComment({ content: commentText.trim(), newsfeedId: item.id }).then(resp => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    if (commentText.trim() != "") {
                        let obj: any = {
                            ID: resp.data.responseData.id,
                            CONTENT: commentText.trim(),
                            CREATED_DATE: new Date(),
                            USER_UID: userInfo.userUid,
                            childs: [],
                            commentBy: {
                                fullName: userInfo.fullName,
                                imgPath: userInfo.imgPath
                            }
                        }

                        setComments([
                            ...comments,
                            obj
                        ])
                        setCommentText("");
                    }
                }
            }).catch(error => {

            })
        }
    }

    const onClosePre = () => {

    }

    const generateClass = (interactType: string) => {
        // switch (interactType) {
        //     case CommonConstant.INTERACT_LIKE: {
        //         return "feather-thumbs-up bg-primary-gradiant"
        //     }
        //     case CommonConstant.INTERACT_FAVARITE: {
        //         return "feather-heart bg-red-gradiant"
        //     }
        //     case CommonConstant.INTERACT_03: {
        //         return "mdi mdi-emoticon-excited bg-2-gradiant"
        //     }
        //     case CommonConstant.INTERACT_04: {
        //         return "mdi mdi-emoticon-sad mdi-dark bg-3-gradiant"
        //     }
        //     default: {
        //         return "feather-thumbs-up bg-like"
        //     }
        // }

        switch (interactType) {
            case CommonConstant.INTERACT_LIKE: {
                // return "feather-thumbs-up bg-primary-gradiant"
                return <img className='vts-curson me-1' src={require("../../../assets/images/interact/icons8-facebook-like-48.png")} width={32} alt="image" />
            }
            case CommonConstant.INTERACT_FAVARITE: {
                // return "feather-heart bg-red-gradiant"
                return <img className='vts-curson me-1' src={require("../../../assets/images/interact/icons8-love-circled-48.png")} width={32} alt="image" />
            }
            case CommonConstant.INTERACT_03: {
                // return "mdi mdi-emoticon-excited bg-2-gradiant"
                return <img className='vts-curson me-1' src={require("../../../assets/images/interact/icons8-haha-94.png")} width={32} alt="image" />
            }
            case CommonConstant.INTERACT_04: {
                // return "mdi mdi-emoticon-sad mdi-dark bg-3-gradiant"
                return <img className='vts-curson me-1' src={require("../../../assets/images/interact/icons8-angry-94.png")} width={32} alt="image" />
            }
            default: {
                // return "feather-thumbs-up bg-like"
                return <img className='vts-curson me-1' src={require("../../../assets/images/interact/icons8-like-default.png")} width={27} alt="image" />
            }
        }
    }

    const [refresh, setRefresh] = useState(new Date().getTime());

    const lastOrNext = (type: number) => {
        let index = viewImageIdx.current + type
        if (index < 0) {
            index = index + item.photos.length
        } else if (index > item.photos.length - 1) {
            index = 0
        }
        viewImageIdx.current = index;
        viewImage.current = item.photos[index]
        setRefresh(new Date().getTime());
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (showMedia) {
                if (event.key === 'Escape') {
                    setShowMedia(false)
                } else if (event.key === 'ArrowRight' && item.photos.length > 1) {
                    lastOrNext(1)
                } else if (event.key === 'ArrowLeft' && item.photos.length > 1) {
                    lastOrNext(-1)
                }
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showMedia]);

    const extractUrls = (text: string) => {
        text = text.replace(/<[^>]*>/g, '')
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = text.match(urlRegex) || [];
        for (let i = 0; i < urls.length; i++) {
            text = text.replace(urls[i], `<a href='${urls[i]}' class='new-items-hover' target='_blank'>${urls[i]}</a>`)
        }
        text = text.replaceAll("\n", "<br />");
        return text;
    }

    return (
        <>
            {
                showMedia &&
                <>
                    <div className="mfp-bg mfp-img-mobile mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-img-mobile mfp-ready" tabIndex={-1}>
                        <div className="mfp-container mfp-s-ready mfp-image-holder">
                            <button type="button" className="mfp-close me-2" onClick={() => {
                                setShowMedia(false);
                            }}>×</button>
                            {
                                item.photos.length > 1 && <a className='me-2 last-next-img' onClick={() => lastOrNext(-1)}><i className='feather-chevron-left'></i></a>
                            }
                            <div className="mfp-content">
                                <div className="mfp-figure">
                                    <img src={FileUtil.genarateUrlGetImage(viewImage.current.newFleNm, CommonConstant.FILE_ORIGIN)}
                                        onError={(event: any) => {
                                            event.target.src = require("../../../assets/images/not_image.png")
                                        }}
                                        className="rounded border" alt="" />
                                </div>
                            </div>
                            {
                                item.photos.length > 1 && <a className='ms-2 last-next-img' onClick={() => lastOrNext(1)}><i className='feather-chevron-right'></i></a>
                            }
                        </div>
                    </div>
                </>
            }
            <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3" style={{ overflow: "auto" }}>
                <div className="card-body p-0 d-flex">
                    <figure className="avatar me-3">
                        <img src={FileUtil.genarateUrlGetImage(item.createdBy?.imgPath, CommonConstant.FILE_THUMB)}
                            onError={(event: any) => {
                                event.target.src = require("../../../assets/images/users/user-dummy-img.jpg")
                            }}
                            alt="image" className="shadow-sm rounded-circle w45" />
                    </figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">{item.createdBy?.fullName}  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{CommonUtil.dateComparation(new Date(item.pudDate))} <i className="feather-clock"></i></span></h4>
                </div>
                <div className="card-body p-0 me-lg-5">
                    <p className="fw-500 text-grey-500 lh-26 w-100 mb-1" style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: extractUrls(item.content) }}></p>
                </div>
                <div className="card-body p-0">
                    {
                        item.tags.length > 0 &&
                        item.tags.map((p: any, index: number) => {
                            return <a key={index} className="fw-600 text-primary me-2 vts-curson" style={{ fontSize: "12px" }}>#{p}</a>
                        })
                    }
                </div>
                <div className="card-body d-block p-0">
                    {
                        generate()
                    }
                </div>
                <div className="card-body d-flex p-0 mt-3 vts-interac">
                    <a className="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                        {/* <i className={`${item.interact == CommonConstant.INTERACT_FAVARITE ? 'feather-heart' : 'feather-thumbs-up'} vts-curson text-white ${item.interact == CommonConstant.INTERACT_FAVARITE ? ('bg-red-gradiant') : (item.interact == CommonConstant.INTERACT_LIKE ? 'bg-primary-gradiant' : 'bg-like')} me-1 btn-round-xs font-xss`}></i> */}
                        {/* <i className={`${generateClass(item.interact)} vts-curson text-white me-1 btn-round-xs font-xss`}></i> */}
                        {
                            generateClass(item.interact)
                        }
                        {item.totalInteract} Interact
                    </a>
                    <a className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss vts-curson"><i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span className="d-none-xss">{item.totalComment} Comment</span></a>
                    <a className="ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span className="d-none-xs">Share</span></a>
                </div>

                <div className="card-body d-block p-0">
                    <div className="form-group mb-0 icon-input mt-2">
                        <i className="feather-message-circle font-sm text-grey-400"></i>
                        <input type="text" value={commentText} onKeyUp={handleKeyPress} onChange={handleChange} placeholder="Comment..." style={{ width: "100%", outline: "none" }} className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl theme-dark-bg" />
                    </div>

                    <div className='mt-3'>
                        {
                            comments.length > 0 &&
                            comments.map((e: any) => {
                                return <CommentItem key={e.ID} item={e} removeCommentFun={(commentId: number) => {
                                    NewsService.getInstance().deleteComment(commentId).then(resp => {
                                        if (resp.data.status == CommonConstant.RESULT_OK) {
                                            const array: any = [...comments];
                                            const index = array.findIndex((element: any) => element.ID == commentId);
                                            if (index != -1) {
                                                array.splice(index, 1)
                                                setComments(array)
                                            }
                                        }
                                    }).catch(error => {

                                    })
                                }} goToMyPage={goToMyPage} />
                            })
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
