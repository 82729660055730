import React, { Fragment, useEffect, useState } from 'react'
import { FileUtil } from '../../../../utils/FileUtil'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { useAppSelector } from '../../../../store/hook'
import Plyr from 'plyr-react'
import dayjs from 'dayjs'

export default function ItemInboxMedia(props: any) {
    const { item, deleteMess } = props
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const handleDelete = () => {
        deleteMess(item.id)
    }

    const [plyrs, setPlyrs] = useState<any>([
        item.messType === CommonConstant.MESS_TYPE_VIDEO ? (
            <div className='img-thumb-video-default-height'>
                <Plyr
                    options={{
                        controls: [
                            "play",
                            "progress",
                            "current-time",
                            "volume",
                            "fullscreen",
                            "mute",
                        ]
                    }}
                    source={{
                        type: 'video',
                        sources: [{
                            src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + item.messPath
                        }]
                    }} />
            </div>
        ) : (
            <div style={{ height: "52px" }}>
                <Plyr
                    options={{
                        controls: [
                            "play",
                            "progress",
                            "current-time",
                            "volume",
                            "mute",
                        ]
                    }}
                    source={{
                        type: 'audio',
                        sources: [{
                            src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + item.messPath
                        }]
                    }} />
            </div>
        )
    ]);

    return (
        <li className={`${item.userUid === userInfo.userUid ? 'right' : ''}`}>
            <div className="conversation-list">
                <div className="chat-avatar">
                    {
                        (item.imgPath && item.imgPath !== '') ? (
                            <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                onError={(event: any) => {
                                    event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                }}
                                className="rounded-circle avatar-xs" alt="" />
                        ) : (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                    {
                                        CommonUtil.generateCharacterAvatar(item.fullName)
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="user-chat-content">
                    <div className="ctext-wrap">
                        <div className="ctext-wrap-content">
                            <ul className="list-inline message-img  mb-0">
                                <li className="list-inline-item message-img-list me-2 ms-0">
                                    {
                                        plyrs.map((plyr: any, index: number) => (
                                            <Fragment key={index}>{plyr}</Fragment>
                                        ))
                                        // item.messType === CommonConstant.MESS_TYPE_VIDEO ? (
                                        //     <div className='img-thumb-video-default-height'>
                                        //         <Plyr
                                        //             options={{
                                        //                 controls: [
                                        //                     "play",
                                        //                     "progress",
                                        //                     "current-time",
                                        //                     "volume",
                                        //                     "fullscreen",
                                        //                     "mute",
                                        //                 ]
                                        //             }}
                                        //             source={{
                                        //                 type: 'video',
                                        //                 sources: [{
                                        //                     src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + item.messPath
                                        //                 }]
                                        //             }} />
                                        //     </div>
                                        // ) : (
                                        //     <div style={{ height: "52px" }}>
                                        //         <Plyr
                                        //             options={{
                                        //                 controls: [
                                        //                     "play",
                                        //                     "progress",
                                        //                     "current-time",
                                        //                     "volume",
                                        //                     "mute",
                                        //                 ]
                                        //             }}
                                        //             source={{
                                        //                 type: 'audio',
                                        //                 sources: [{
                                        //                     src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + item.messPath
                                        //                 }]
                                        //             }} />
                                        //     </div>
                                        // )
                                    }
                                </li>
                            </ul>
                            <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{(item.createdDate ? dayjs(item.createdDate).format('YYYY-MM-DD HH:mm') : 'underfined')}</span></p>
                            <div>{item.mess}</div>
                        </div>
                        {
                            item.userUid === userInfo.userUid && (<div className="dropdown align-self-start">
                                <a className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ri-more-2-fill"></i>
                                </a>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" onClick={handleDelete}>Remove <i className="ri-delete-bin-line float-end text-muted"></i></a>
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="conversation-name">{item.userUid !== userInfo.userUid ? item?.fullName : <>&nbsp;</>}</div>
                </div>
            </div>
        </li>
    )
}
