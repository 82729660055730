import React from 'react'
import UserChatTopbar from '../box/UserChatTopbar'
import UserProfileSidebar from '../box/UserProfileSidebar'
import UserChatBottom from '../box/UserChatBottom'
import Conversation from '../box/Conversation'
import { useAppSelector } from '../../../store/hook'

export default function ChatContent() {

    const chat = useAppSelector(state => state.chat.selectedRoom)

    return (
        <>
            {/* {
                chat.roomId != 0 ? (
                    <>
                        <div className="w-100 overflow-hidden position-relative">
                            <UserChatTopbar />
                            <Conversation />
                            <UserChatBottom />
                        </div>
                        <UserProfileSidebar />
                    </>
                ) : (
                    <div className='text-truncate font-size-15 p-3'>No chat selected yet</div>
                )
            } */}
            <div className="w-100 overflow-hidden position-relative">
                <UserChatTopbar />
                <Conversation />
                <UserChatBottom />
            </div>
            <UserProfileSidebar />
        </>
    )
}
