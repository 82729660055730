import { useState } from 'react'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { FileUtil } from '../../../../utils/FileUtil'
import { useAppSelector } from '../../../../store/hook'

export default function ItemNotification(props: any) {

    const { data, selectItem } = props

    const [model, setModel] = useState(data)
    const onlineUsers = useAppSelector(state => state.onlineUsers.onlineUsers)

    return (
        <li style={{ padding: 0 }}>
            <a onClick={() => {
                selectItem(model)
                setModel((prev: any) => {
                    return {
                        ...prev,
                        isNew: CommonConstant.NO
                    }
                })
            }}>
                <div className="d-flex">
                    {
                        (model.imgPath && model.imgPath != '') ? (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: model.senderUid }])}`}>
                                <img src={FileUtil.genarateUrlGetImage(model.imgPath, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="rounded-circle avatar-xs" alt="" />
                                <span className="user-status"></span>
                            </div>
                        ) : (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: model.senderUid }])}`}>
                                <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {
                                            CommonUtil.generateCharacterAvatar(model.senderName)
                                        }
                                    </span>
                                </div>
                                <span className="user-status"></span>
                            </div>
                        )
                    }

                    <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15 mb-1">{model.senderName}</h5>
                        <p className="chat-user-message text-truncate mb-0">{model.content}</p>
                    </div>
                    <div className="font-size-11 text-truncate">{CommonUtil.dateComparation(new Date(model.createdDate))}</div>
                    <div className="unread-message">

                        {
                            model.isNew == CommonConstant.YES && (
                                <span className="badge bg-warning rounded-pill">
                                    <i className="ri-notification-3-line"></i>
                                </span>
                            )
                        }

                        {
                            model.noticeType == CommonConstant.NOTICE_REQ_FREIND ? (
                                <span className="badge vts-theme rounded-pill ms-1">
                                    <i className="ri-user-voice-line"></i>
                                </span>
                            ) : (
                                model.noticeType == CommonConstant.NOTICE_BIRTHDAY ? (
                                    (
                                        <span className="badge vts-theme rounded-pill ms-1">
                                            <i className="ri-cake-2-line"></i>
                                        </span>
                                    )
                                ) : (<></>)
                            )
                        }

                    </div>
                </div>
            </a>
        </li>
    )
}
