import axios from "axios";
import { ApiUrlUtil } from "../utils/apiUrlUtil";
import { HeadersUtil } from "../utils/headersUtil";

export class UserInfoService {

    private static _userInfoService: UserInfoService;

    public static getInstance(): UserInfoService {
        if (!UserInfoService._userInfoService) {
            UserInfoService._userInfoService = new UserInfoService();
        }
        return UserInfoService._userInfoService;
    }

    public updateInfo(model: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/update");
        return axios.post(url, model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public logout() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/logout");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getProfile(profileId: string) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/getProfile?profileId=" + profileId);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public changePassword(model: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/change-pass");
        return axios.post(url, model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public generateSecretKey() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/generate-secret-key");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public verifyTwoFA(model: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/verifyTwoFA");
        return axios.post(url, model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public sendOtpViaEmail() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/sendOtpViaEmail");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public verifyPwd(model: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/user/verifyPwd");
        return axios.post(url, model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}