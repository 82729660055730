import React, { useEffect, useRef, useState } from 'react'
import { CommonConstant } from '../../../constants/CommonConstant';
import { NewsService } from '../../../services/NewsService';
import ItemNews from './comp/ItemNews';

export default function News() {

    const listInnerRef = useRef<any>();
    const [news, setNews] = useState<any>([])
    const [keySearch, setKeySearch] = useState("");
    const isLoad = useRef(true);
    const offset = useRef(0);
    const [loadAgain, setLoadAgain] = useState(false)

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    const loadMore = (type: number) => {
        isLoad.current = false;
        NewsService.getInstance().getListNews({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: keySearch })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    setLoadAgain(false);
                    if (resp.data.responseData.length == CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    if (type == 0) {
                        setNews(resp.data.responseData)
                    } else {
                        setNews((prev: any) => {
                            return [...prev, ...resp.data.responseData]
                        });
                    }
                } else {
                    // toast.error("Server error")
                    setLoadAgain(true)
                }
            })
            .catch((err: any) => {
                // toast.error("Server error")
                setLoadAgain(true)
            })
        offset.current += 1;
    }

    useEffect(() => {
        loadMore(0);
    }, [])

    useEffect(() => {
        listInnerRef.current = document.getElementById('_contact-list')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [news])

    const handleChange = (event: any) => {
        setKeySearch(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            offset.current = 0;
            loadMore(0);
        }
    }

    const handleSearch = () => {
        offset.current = 0;
        loadMore(0);
    }
    return (
        <div>
            <div className="p-4">
                <div className="user-chat-nav float-end">
                    <div title="Add Contact">
                        <span className="rounded-pill">
                            {/* <i className="ri-newspaper-line"></i> */}
                        </span>
                    </div>
                </div>
                <h4 className="mb-4">News</h4>

                <div className="search-box chat-search-box">
                    <div className="input-group bg-light input-group-lg rounded-3">
                        <div className="input-group-prepend">
                            <button className="btn btn-link text-decoration-none text-muted pe-1 ps-3" type="button" onClick={handleSearch}>
                                <i className="ri-search-line search-icon font-size-18"></i>
                            </button>
                        </div>
                        <input type="text" value={keySearch} onKeyUp={handleKeyPress} onChange={handleChange} className="form-control bg-light" placeholder="Search news..." />
                    </div>
                </div>
            </div>
            <div id='_contact-list' className="px-2 chat-news-list" data-simplebar>
                <div>
                    {
                        !loadAgain ? (
                            <ul className="list-unstyled chat-list contact-list-curson">
                                {
                                    news.length > 0 &&
                                    news.map((p: any) => {
                                        return <ItemNews key={p.id} data={p} />
                                    })
                                }
                            </ul>
                        ) : (
                            <div style={{ textAlign: "center" }}>
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    offset.current = 0;
                                    loadMore(0);
                                }}>Load again</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
