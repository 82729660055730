// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBQzgtldhGorTZDXrEwpQ4nlGtkZjxCDUw",
    authDomain: "vinh-thinh-conical.firebaseapp.com",
    projectId: "vinh-thinh-conical",
    storageBucket: "vinh-thinh-conical.appspot.com",
    messagingSenderId: "571900728279",
    appId: "1:571900728279:web:2598bb7f5510f67b383192"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound: any) => {
    return getToken(messaging, { vapidKey: 'BDO68TCfqNEKFbxvlcreC6zYNeT0UMOo3DRl3uCyu0O5_GbW3uLQX7kr-ReM1athctb0iwoz-cW1Mn5lJJcX0LU' }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            // console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });