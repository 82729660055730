import React, { useEffect, useState, useRef } from 'react'
import { useAppSelector } from '../../../store/hook'
import { RoomService } from '../../../services/chat/mess/RoomService'
import { CommonConstant } from '../../../constants/CommonConstant'
import { FileUtil } from '../../../utils/FileUtil'
import { CommonUtil } from '../../../utils/CommonUtil'
import dayjs from 'dayjs'
import ItemAttached from './comp/ItemAttached'
import { MessService } from '../../../services/chat/mess/MessService'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { AseUtil } from '../../../utils/AseUtil'
import ModalComp from '../../../components/ModalComp'
import AddMemberPopup from './comp/AddMemberPopup'

export default function UserProfileSidebar() {

    const chat = useAppSelector(state => state.chat.selectedRoom)
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    const listInnerRef = useRef<any>();
    const [messAttach, setMessAttach] = useState<any>([])
    const isLoad = useRef(true);
    const offset = useRef(0);
    const onlineUsers = useAppSelector(state => state.onlineUsers.onlineUsers)

    const [roomDetail, setRoomDetail] = useState<any>({})

    useEffect(() => {
        if (chat.roomId != 0) {
            RoomService.getInstance().getDetailChatRoom(chat.roomId).then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    setRoomDetail(resp.data.responseData)
                }
            }).catch((err: any) => {

            })
            offset.current = 0;
            loadMore(0);
        }
    }, [chat])

    const genderRoomDetailAbout = () => {
        if (roomDetail.listUser && roomDetail.listUser.length > 0) {
            const detail = roomDetail.listUser.filter((e: any) => e.userUid != userInfo.userUid)
            return (
                <>
                    <div>
                        <p className="text-muted mb-1">Birthday</p>
                        <h5 className="font-size-14">{(detail[0]?.dob ? dayjs(detail[0].dob).format('YYYY-MM-DD') : 'unavailable')}</h5>
                    </div>

                    <div className="mt-4">
                        <p className="text-muted mb-1">Gender</p>
                        <h5 className="font-size-14">{CommonUtil.convertGender(detail[0]?.gender)}</h5>
                    </div>

                    <div className="mt-4">
                        <p className="text-muted mb-1">Phone</p>
                        <h5 className="font-size-14">{detail[0]?.phone || 'unavailable'}</h5>
                    </div>

                    <div className="mt-4">
                        <p className="text-muted mb-1">Email</p>
                        <h5 className="font-size-14 mb-0">{detail[0]?.email || 'unavailable'}</h5>
                    </div>
                </>
            )
        }
    }

    const handleRemoveUser = (user: any) => {
        Swal.fire({
            title: `Confirm`,
            text: `Do you want to remove ${user.fullName} from this conversation?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                RoomService.getInstance().removeUserFromGroup(
                    {
                        roomId: chat.roomId,
                        deleteUserUid: user.userUid,
                        mess: AseUtil.encryptedAES(userInfo.userUid, `${user.fullName} has left the chat.`)
                    })
                    .then((resp: any) => {
                        if (resp.data.status = CommonConstant.RESULT_OK) {
                            var array: any = [...roomDetail.listUser]
                            var index = array.findIndex((e: any) => e.userUid == user.userUid);
                            if (index == -1) return;
                            array.splice(index, 1)
                            setRoomDetail({
                                ...roomDetail,
                                listUser: array
                            })
                        } else {
                            toast.error("Leave the failed conversation")
                        }
                    }).catch((err: any) => {
                        toast.error("System error, please come back later")
                    })
            }
        })
    }

    const genderRoomDetailAboutGroup = () => {
        if (roomDetail.listUser && roomDetail.listUser.length > 0) {
            const detail = roomDetail.listUser.filter((e: any) => e.userUid != userInfo.userUid)
            return (
                <div>
                    <button type="button" className="btn btn-light btn-sm mb-1" onClick={handleShowPopup}>
                        Add members
                    </button>
                    <ul className="list-unstyled chat-list chat-user-list">
                        {
                            detail.length > 0 &&
                            detail.map((p: any) => (
                                <li key={p.userUid} className="unread">
                                    <a style={{ borderTop: 0, cursor: "pointer" }} title={`${roomDetail.createdBy == userInfo.userUid ? `Remove ${p.fullName}` : ''}`}>
                                        <div className="d-flex">
                                            {
                                                (p.imgPath && p.imgPath != '') ? (
                                                    <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: p.userUid }])}`}>
                                                        <img src={FileUtil.genarateUrlGetImage(p.imgPath, CommonConstant.FILE_THUMB)}
                                                            onError={(event: any) => {
                                                                event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                                            }}
                                                            className="rounded-circle avatar-xs" alt="" />
                                                        <span className="user-status"></span>
                                                    </div>
                                                ) : (
                                                    <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: p.userUid }])}`}>
                                                        <div className="avatar-xs">
                                                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                {
                                                                    CommonUtil.generateCharacterAvatar(p.fullName)
                                                                }
                                                            </span>
                                                        </div>
                                                        <span className="user-status"></span>
                                                    </div>
                                                )
                                            }
                                            <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="text-truncate font-size-15 mb-1">{p.fullName}</h5>
                                                <p className="chat-user-message text-truncate mb-0">Add by {p.createdByName}</p>
                                            </div>
                                            {
                                                roomDetail.createdBy == userInfo.userUid && (
                                                    <div className="contacts-lst">
                                                        <i className="ri-logout-box-r-line" onClick={() => handleRemoveUser(p)}></i>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            )
        }
    }

    const loadMore = (typeConversation: number) => {
        isLoad.current = false;
        MessService.getInstance().getListAttachByRoomId({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: "" }, chat.roomId)
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    if (resp.data.responseData.length == CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    if (typeConversation == 0) {
                        setMessAttach(resp.data.responseData);
                    } else {
                        setMessAttach([...messAttach, ...resp.data.responseData]);
                    }
                } else {

                }
            })
            .catch((err: any) => {

            })
        offset.current += CommonConstant.LIMIT;
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 >= scrollHeight;
            if (isNearBottom) {
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    useEffect(() => {
        listInnerRef.current = document.getElementById('profile-sidebar-right')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [messAttach])

    const [showNewGroup, setShowNewGroup] = useState(false)

    const addNewGroups = (arr: any) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i]['createdByName'] = userInfo.fullName
        }
        let array = [...arr, ...roomDetail.listUser]
        setRoomDetail({
            ...roomDetail,
            listUser: array
        })
    }

    const handleShowPopup = () => {
        setShowNewGroup(true)
    }

    useEffect(() => {
        if (showNewGroup) {
            $("#showMemberGroupPopup").modal("show");
        }
    }, [showNewGroup])

    return (
        <>
            {
                showNewGroup && (
                    <ModalComp id="showMemberGroupPopup" onClose={() => {
                        $("#showMemberGroupPopup").modal("hide");
                        setShowNewGroup(false)
                    }} title="Add members">
                        <AddMemberPopup onClose={() => {
                            $("#showMemberGroupPopup").modal("hide");
                            setShowNewGroup(false)
                        }} callBack={addNewGroups} />
                    </ModalComp>
                )
            }
            <div className="user-profile-sidebar">
                <div className="px-3 px-lg-4 pt-3 pt-lg-4">
                    <div className="user-chat-nav text-end">
                        <button type="button" className="btn nav-btn" id="user-profile-hide">
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                </div>
                <div className="text-center p-4 border-bottom">
                    <div className="mb-4 d-flex justify-content-center">
                        {
                            (roomDetail.roomImg && roomDetail.roomImg != '') ? (
                                <img src={FileUtil.genarateUrlGetImage(roomDetail.roomImg, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="rounded-circle avatar-lg" alt="" />
                            ) : (
                                <div className="rounded-circle avatar-lg">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary" style={{ fontSize: "xx-large" }}>
                                        {
                                            chat.roomType == CommonConstant.ROOM_SINGLE ? CommonUtil.generateCharacterAvatar(roomDetail?.roomName) : <i className="ri-group-line" style={{ fontSize: "xx-large" }}></i>
                                        }
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    <h5 className="font-size-16 mb-1 text-truncate">{roomDetail?.roomName}</h5>
                </div>
                <div id='profile-sidebar-right' className="p-4 user-profile-desc" data-simplebar>
                    <div className="accordion" id="myprofile">
                        <div className="accordion-item card border mb-2">
                            <div className="accordion-header" id="about3">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aboutprofile" aria-expanded="true" aria-controls="aboutprofile">
                                    <h5 className="font-size-14 m-0">
                                        <i className="ri-contacts-line me-2"></i> About
                                    </h5>
                                </button>
                            </div>
                            <div id="aboutprofile" className="accordion-collapse collapse show" aria-labelledby="about3" data-bs-parent="#myprofile">
                                <div className="accordion-body">
                                    {
                                        chat.roomType == CommonConstant.ROOM_SINGLE ? (
                                            genderRoomDetailAbout()
                                        ) : (
                                            genderRoomDetailAboutGroup()
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item card border">
                            <div className="accordion-header" id="attachfile3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#attachprofile" aria-expanded="false" aria-controls="attachprofile">
                                    <h5 className="font-size-14 m-0">
                                        <i className="ri-attachment-line me-2 ms-0 align-middle d-inline-block"></i> Attached Files
                                    </h5>
                                </button>
                            </div>
                            <div id="attachprofile" className="accordion-collapse collapse" aria-labelledby="attachfile3" data-bs-parent="#myprofile">
                                <div className="accordion-body">
                                    {
                                        messAttach.length != 0 && (
                                            messAttach.map((e: any) => {
                                                return <ItemAttached key={e.id} item={e} />
                                            })
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
