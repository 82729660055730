import QRCode from 'qrcode.react'
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../../store/hook';
import { UserInfoService } from '../../../../services/UserInfoService';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { toast } from 'react-toastify';
import { updateUserInfo } from '../../../../reducers/userSlice';
import { useDispatch } from 'react-redux';
import VerifyPwd from './VerifyPwd';

export default function EnableFA(props: any) {
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    const dispatch = useDispatch()
    const [model, setModel] = useState({ faKey: "", code: "", typeFa: "" })
    const otpAuth = useRef("");
    const handleChange = (event: any) => {
        setModel({
            ...model,
            [event.target.name]: event.target.value
        })
    }
    const [secretKey, setSecretKey] = useState<string>("");
    useEffect(() => {
        if (!userInfo.faEnable) {
            UserInfoService.getInstance()
                .generateSecretKey()
                .then((resp) => {
                    if (resp.data.status == CommonConstant.RESULT_OK) {
                        otpAuth.current = "otpauth://totp/" +
                            userInfo.email +
                            "?secret=" +
                            resp.data.responseData.secretKey +
                            "&issuer=" +
                            "AtwoM_Story";
                        setSecretKey(resp.data.responseData.secretKey)
                    } else {
                        props.onClose()
                    }
                })
                .catch((error) => {
                    props.onClose()
                });
        }
    }, [])

    const [sendEmail, setSendEmail] = useState(false)

    const handleSendOtpViaEmail = () => {
        setSendEmail(true)
        UserInfoService.getInstance().sendOtpViaEmail().then((resp) => {

        }).catch((error) => {

        })
    }

    const handleSave = () => {
        if (model.code == undefined || model.code.length != 6) {
            toast.error("Code cannot be blank")
            return false;
        }
        UserInfoService.getInstance().verifyTwoFA({ ...model, typeFa: sendEmail ? "OTP_EMAIL" : "OTP_GA" }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                dispatch(updateUserInfo({
                    ...userInfo,
                    faEnable: !userInfo.faEnable
                }))
                props.onClose()
            } else {
                toast.error("Verification code is invalid")
            }
        }).catch((error: any) => {
            toast.error("System error. Please come back later")
        })
    }

    const [isVerifyPwd, setIsVerifyPwd] = useState(false)

    return (
        <>
            {
                isVerifyPwd ? <>
                    <div className="modal-body p-4">
                        {
                            (!userInfo.faEnable && !sendEmail) && <div className='mb-3'>
                                <div className="text-center">
                                    <QRCode value={otpAuth.current} renderAs="canvas" />
                                    <p>{secretKey}</p>
                                </div>
                            </div>
                        }
                        <div className='mt-2'>
                            <label className="form-label">Please enter the 6 digit code in authenticator app</label>
                            <input type="text" onChange={handleChange} name='code' value={model.code} className="form-control" placeholder="Enter code" />
                        </div>
                        {
                            !sendEmail && <a className='vts-curson' onClick={handleSendOtpViaEmail} style={{ color: "#0058ff" }}>Try another way (send to email)</a>
                        }
                        {
                            sendEmail && <p>We have sent the otp code to your registered email. The code is valid for 180 seconds</p>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={handleSave}>Send</button>
                    </div>
                </> : <>
                    <VerifyPwd callBackFun={() => {
                        setIsVerifyPwd(true)
                    }} />
                </>
            }
        </>
    )
}
