import React, { useState, useRef, useEffect, useCallback } from 'react'
import { updateUserInfo } from '../../../../reducers/userSlice';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import Cropper from 'react-easy-crop';
import { FileUtil } from '../../../../utils/FileUtil';
import axios from 'axios';
import { HeadersUtil } from '../../../../utils/headersUtil';
import { ParamUtil, RequestParam } from '../../../../utils/paramUtil';
import { ApiUrlUtil } from '../../../../utils/apiUrlUtil';
import { showAndHideSpinner } from '../../../../reducers/spinnerSlice';

export default function EditCoverImg(props: any) {
    const { onClose } = props;

    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const dispatch = useAppDispatch();

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    const fileInputRef = useRef<any>();

    const [picture, setPicture] = useState<any>(null);
    const [imgData, setImgData] = useState<any>(userInfo?.coverImgPath);
    const [cropObj, setCropObj] = useState<any>();

    useEffect(() => {
        if (imgData && !imgData.startsWith("data:image/")) {
            axios.get(FileUtil.genarateUrlGetByte(imgData, CommonConstant.FILE_ORIGIN), {
                responseType: "blob",
                headers: HeadersUtil.getHeadersAuth()
            }).then(rep => {
                if (rep.status == 200) {
                    let file = new File([rep.data], imgData);
                    setPicture(file);
                }
            }).catch(err => {

            })
        }
    }, [imgData])

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCropObj(croppedAreaPixels)
    }, [])

    const onChangePicture = (e: any) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const saveFile = () => {
        const data = new FormData();
        data.append('file', picture);

        const params: RequestParam[] = ParamUtil.toRequestParams(cropObj);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/file/cover-img/upload.exclude`, params);
        dispatch(showAndHideSpinner(1))
        axios.post(url, data, {
            headers: HeadersUtil.getHeadersAuthFormData()
        }).then(rep => {
            if (rep.data.status == CommonConstant.RESULT_OK) {
                dispatch(updateUserInfo({
                    ...userInfo,
                    coverImgPath: rep.data.responseData.newFleNm
                }))
                onClose(rep.data.responseData.newFleNm);
            }
            dispatch(showAndHideSpinner(-1))
        }).catch(err => {
            dispatch(showAndHideSpinner(-1))
        })
    }

    return (
        <div className="modal-body p-4">
            <div style={{ height: '50vh' }}>
                <input ref={fileInputRef} type='file' accept="image/png, image/gif, image/jpeg, image/jpg" onChange={onChangePicture} style={{ visibility: 'hidden' }} />
                <div className='crop-container'>
                    <Cropper
                        image={(imgData && imgData != '') ? (imgData.startsWith("data:image/") ? imgData : FileUtil.genarateUrlGetImage(imgData, CommonConstant.FILE_ORIGIN)) : require("../../../../../assets/images/poster-1.png")}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <div className="crop-controls">
                    <button type="button" className="btn btn-primary rounded-pill me-2" onClick={() => {
                        fileInputRef.current.click();
                    }}><i className="ri-file-add-line fs-16 ">Upload</i></button>
                    {
                        imgData != null && (
                            <button type="button" className="btn btn-success rounded-pill" onClick={saveFile}><i className="mdi mdi-check fs-16">Save</i></button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
