import React, { Suspense, useState, useEffect } from 'react';
import { fetchToken, onMessageListener } from './firebase';
import './App.css';
import "./assets/css/style.css";
import "./assets/css/loading.css";
import "./assets/css/loader.css";

import "plyr-react/plyr.css";

import { useRoutes } from "react-router-dom";
import { indexRouter } from './app/routers/indexRouter';
import { ToastContainer } from 'react-toastify';

export const spinner = (
  <div className="progress-spinner text-center">
    <div className="lds-hourglass"></div>
  </div>
);

function App() {

  let router = useRoutes([
    indexRouter
  ]);

  const [isTokenFound, setTokenFound] = useState(false);
  // const [notification, setNotification] = useState<any>(null);

  useEffect(() => {
    // fetchToken(setTokenFound);
  }, [])

  useEffect(() => {
    if (isTokenFound) {

    }
  }, [isTokenFound])

  onMessageListener().then(payload => {
    // console.log(payload);
    // setNotification(payload)
  }).catch(err => {
    // console.log('failed: ', err)
  });


  return (
    <div>
      <ToastContainer />
      <Suspense fallback={spinner}>
        {router}
      </Suspense>
    </div>
  );
}

export default App;
