import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { UserInfoService } from '../../../../services/UserInfoService'
import { CommonConstant } from '../../../../constants/CommonConstant'

export default function VerifyPwd(props: any) {
    const { callBackFun } = props
    const [model, setModel] = useState({ pwd: "" })
    const handleChange = (event: any) => {
        setModel({
            ...model,
            [event.target.name]: event.target.value
        })
    }
    const handleSave = () => {
        if (chk()) {
            UserInfoService.getInstance().verifyPwd(model).then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    callBackFun()
                } else {
                    toast.error("Incorrect password")
                }
            }).catch((error: any) => {
                toast.error("System error. Please come back later")
            })
        }
    }

    const chk = () => {
        if (model.pwd == undefined || model.pwd == "") {
            toast.error("Password cannot be blank")
            return false;
        }
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\&])[0-9a-zA-Z!@#\$%\&]{8,}$/.test(model.pwd) === false) {
            toast.error("Password from 8-32 characters, including: uppercase letters, lowercase letters, numbers and special characters !, @, #, &, %, &");
            return false;
        }
        return true;
    }

    const [showPass, setShowPass] = useState(0)

    const handleHideShowPass = (typeNumber: number) => {
        setShowPass(typeNumber)
    }
    return (
        <>
            <div className="modal-body p-4">
                <div className="mb-3 vts-preview-pass-parent">
                    <label className="form-label">Password <strong className='color-red'>*</strong></label>
                    <input type={`${showPass == 1 ? "text" : "password"}`} onChange={handleChange} name='pwd' value={model.pwd || ""} className="form-control" placeholder="Enter password" />
                    {
                        showPass == 1 ? <i onClick={() => handleHideShowPass(0)} className="ri-eye-off-line vts-preview-pass-eyes"></i> :
                            <i onClick={() => handleHideShowPass(1)} className="ri-eye-line vts-preview-pass-eyes"></i>
                    }
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSave}>Next</button>
            </div>
        </>
    )
}
