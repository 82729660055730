import React, { useEffect, useState, useRef } from 'react'
import ItemInbox from './comp/ItemInbox'
import ItemInboxTypeImg from './comp/ItemInboxTypeImg'
import ItemInboxTypeFle from './comp/ItemInboxTypeFle'
import ItemInboxIcon from './comp/ItemInboxIcon'
import { CommonConstant } from '../../../constants/CommonConstant'
import ItemInboxMedia from './comp/ItemInboxMedia'
import { useAppSelector } from '../../../store/hook'
import { MessService } from '../../../services/chat/mess/MessService'
import MagnificPopup from '../../../components/MagnificPopup'
import ItemInboxNotice from './comp/ItemInboxNotice'
import { AseUtil } from '../../../utils/AseUtil'

export default function Conversation() {

    const listInnerRef = useRef<any>();
    const [mess, setMess] = useState<any>([])
    const isLoad = useRef(true);
    const isCrollToEnd = useRef(1); // 1 scroll to end, 0 set lại height, -1 doing nothing
    const indexCrollLoadMore = useRef(0)
    const offset = useRef(0);
    const numberNewMess = useRef(0);
    const roomIdSel = useRef(0);
    const [showMedia, setShowMedia] = useState(false);
    const selectedMess = useRef<any>({});

    const chat = useAppSelector(state => state.chat.selectedRoom)

    useEffect(() => {
        const tempSend = MessService.getInstance()._sendMess.subscribe((messDto: any) => {
            if (messDto.id) {
                isCrollToEnd.current = 1;
                numberNewMess.current += 1;
                let tempList = [...mess, messDto];
                setMess(tempList)
                MessService.getInstance().change({})
            }
        })

        const tempReceive = MessService.getInstance()._receiveMess.subscribe((messDto: any) => {
            if (messDto.id && (messDto.roomId === roomIdSel.current)) {
                let tempList = [...mess, messDto];
                numberNewMess.current += 1;
                isCrollToEnd.current = 1;
                setMess(tempList)
                MessService.getInstance().changeReceiver({})
            }
        })

        return () => {
            tempSend.unsubscribe();
            tempReceive.unsubscribe();
        }
    }, [mess])

    const loadMore = (typeConversation: number) => {
        offset.current += numberNewMess.current;
        isLoad.current = false;
        MessService.getInstance().getListMessByRoomId({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: "" }, chat.roomId)
            .then((resp: any) => {
                if (resp.data.status === CommonConstant.RESULT_OK) {
                    if (resp.data.responseData.length === CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    if (typeConversation === 0) {
                        setMess(resp.data.responseData);
                    } else {
                        setMess([...resp.data.responseData, ...mess]);
                    }
                } else {

                }
            })
            .catch((err: any) => {

            })
        numberNewMess.current = 0;
        offset.current += CommonConstant.LIMIT;
    }

    useEffect(() => {
        if (chat.roomId !== 0) {
            listInnerRef.current = null
            indexCrollLoadMore.current = 0
            isCrollToEnd.current = 1;
            roomIdSel.current = chat.roomId
            numberNewMess.current = 0;
            isLoad.current = true;
            offset.current = 0
            loadMore(0)
        }
    }, [chat])

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop === 0;
            if (isNearBottom) {
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    useEffect(() => {
        scrollToEnd()
    }, [mess])

    useEffect(() => {
        listInnerRef.current = document.getElementById('chat-box')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [mess])

    const scrollToEnd = () => {
        var objDiv = document.getElementById('chat-box')?.getElementsByClassName("simplebar-content-wrapper")[0];
        if (objDiv) {
            if (isCrollToEnd.current === 1) {
                objDiv.scrollTop = objDiv.scrollHeight;
                if (mess.length !== 0) {
                    isCrollToEnd.current = 0;
                }
            } else if (isCrollToEnd.current === 0) {
                objDiv.scrollTop = objDiv.scrollHeight - indexCrollLoadMore.current;
            } else {
                // isCrollToEnd.current = 0;
            }
        }
        indexCrollLoadMore.current = objDiv?.scrollHeight || 0;
    }

    const selectMediaMess = (item: any) => {
        selectedMess.current = item
        setShowMedia(true);
    }

    const handleDelete = (messId: number) => {
        var array: any = [...mess]
        var index = array.findIndex((e: any) => e.id === messId);
        if (index === -1) return;
        array[index]['messType'] = CommonConstant.MESS_TYPE_RECALL;
        array[index]['mess'] = AseUtil.encryptedAES(array[index]['userUid'], "Message has been recalled")
        array[index]['roomId'] = chat.roomId;
        MessService.getInstance().messageRecall(array[index]).then((resp: any) => {

        }).catch((err: any) => {

        })
        isCrollToEnd.current = -1;
        setMess(array);
    }

    const generateInboxType = (ele: any) => {
        switch (ele.messType) {
            case CommonConstant.MESS_TYPE_IMG: {
                return <ItemInboxTypeImg key={ele.id} item={ele} onSelectMess={selectMediaMess} deleteMess={handleDelete} />
            }
            case CommonConstant.MESS_TYPE_AUDIO:
            case CommonConstant.MESS_TYPE_VIDEO: {
                return <ItemInboxMedia key={ele.id} item={ele} deleteMess={handleDelete} />
            }
            case CommonConstant.MESS_TYPE_FLE: {
                return <ItemInboxTypeFle key={ele.id} item={ele} deleteMess={handleDelete} />
            }
            case CommonConstant.MESS_TYPE_ICON: {
                return <ItemInboxIcon key={ele.id} item={ele} />
            }
            case CommonConstant.MESS_TYPE_NOTICE: {
                return <ItemInboxNotice key={ele.id} item={ele} />
            }
            default: {
                return <ItemInbox key={ele.id} item={ele} deleteMess={handleDelete} />
            }
        }
    }

    return (
        <>
            {
                showMedia && <MagnificPopup item={selectedMess.current} onClosePre={() => {
                    setShowMedia(false);
                }} />
            }
            <div id='chat-box' className="chat-conversation p-3 p-lg-4" data-simplebar="init">
                <ul className="list-unstyled mb-0">
                    {
                        mess.length !== 0 && (
                            mess.map((e: any) => {
                                return generateInboxType(e)
                            })
                        )
                    }
                </ul>
            </div>
        </>
    )
}
