
import { Navigate } from "react-router-dom";
import LayoutChat from "../pages/chat/comp/LayoutChat";
import Newsfeed from "../pages/chat/comp/Newsfeed";
import Contacts from "../pages/chat/contacts/Contacts";
import Groups from "../pages/chat/groups/Groups";
import Messager from "../pages/chat/mess/Messager";
import News from "../pages/chat/news/News";
import Notification from "../pages/chat/notification/Notification";
import MyProfile from "../pages/chat/profile/MyProfile";
import ProfilePage from "../pages/chat/profile/ProfilePage";
import Settings from "../pages/chat/settings/Settings";

export const chatRouter: any = {
    path: "chat",
    element: <LayoutChat />,
    children: [
        { path: "", element: <Messager /> },
        { path: "profile", element: window.innerWidth >= 992 ? <MyProfile /> : <ProfilePage /> },
        { path: "myself", element: window.innerWidth < 992 ? <MyProfile /> : <Navigate to="/chat" replace /> },
        { path: "news", element: <News /> },
        { path: "notification", element: <Notification /> },
        { path: "groups", element: <Groups /> },
        { path: "contacts", element: <Contacts /> },
        { path: "settings", element: <Settings /> },
        { path: "newsfeed", element: <Newsfeed /> }
    ]
}