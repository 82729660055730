import React, { useState, useRef, useEffect } from 'react'
import ItemFriend from '../../groups/comp/ItemFriend';
import { toast } from 'react-toastify';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { RoomService } from '../../../../services/chat/mess/RoomService';
import { useAppSelector } from '../../../../store/hook';
import { AseUtil } from '../../../../utils/AseUtil';

export default function AddMemberPopup(props: any) {

    const { onClose, callBack } = props

    const chat = useAppSelector(state => state.chat.selectedRoom)
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const [keySearch, setKeySearch] = useState("");
    const [friends, setFriend] = useState<any>([

    ])

    const listInnerRef = useRef<any>();
    const isLoad = useRef(true);
    const offset = useRef(0);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 > scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    const loadMore = (type: number) => {
        isLoad.current = false;
        RoomService.getInstance().getFriendForGroup({ limit: 10, offset: offset.current, keySearch: keySearch }, chat.roomId).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                if (resp.data.responseData.length == CommonConstant.LIMIT) {
                    isLoad.current = true;
                }
                if (type == 0) {
                    setFriend(resp.data.responseData)
                } else {
                    setFriend((prev: any) => {
                        return [...prev, ...resp.data.responseData]
                    });
                }
            }
        }).catch((err: any) => {

        })
        offset.current += 10;
    }

    useEffect(() => {
        loadMore(0)
    }, [])

    useEffect(() => {
        listInnerRef.current = document.getElementById('_div-friends-list');
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [friends])

    const handleChangeSearch = (event: any) => {
        setKeySearch(event.target.value)
    }

    const handleSearch = () => {
        offset.current = 0;
        loadMore(0);
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            offset.current = 0;
            loadMore(0);
        }
    }

    const handleSelectUser = (isCheck: boolean, userUid: string) => {
        var array = [...friends]
        var index = array.findIndex(e => e.userUid == userUid)
        if (index == -1) {
            return;
        }
        if (isCheck) {
            array[index]['IS_CHECKED'] = 'Y';
        } else {
            array[index]['IS_CHECKED'] = 'N';
        }
        setFriend([...array])
    }

    const handleSave = () => {
        if (chk()) {
            let arr = friends.filter((e: any) => e['IS_CHECKED'] == 'Y')

            let tempFullName = ""

            for (let i = 0; i < arr.length; i++) {
                tempFullName += arr[i]['fullName'] + ", "
            }

            const obj = {
                listUser: arr,
                id: chat.roomId,
                roomName: chat.roomName,
                messDto: {
                    mess: AseUtil.encryptedAES(userInfo.userUid, userInfo.fullName + " added " + tempFullName.substring(0, tempFullName.length - 2))
                }
            }

            RoomService.getInstance().addMembers(obj).then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    onClose()
                    callBack(arr)
                }
            }).catch((err: any) => {

            })
        }
    }

    const chk = () => {
        var index = friends.findIndex((e: any) => e.IS_CHECKED == 'Y')
        if (index == -1) {
            toast.error("No member selected yet")
            return false;
        }

        return true;
    }

    return (
        <>
            <div className="modal-body p-4">
                <div className="vts-scroll">
                    <label className="form-label">Group members <strong className='color-red'>*</strong></label>

                    <div className="search-box chat-search-box">
                        <div className="input-group bg-light input-group-lg rounded-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-link text-decoration-none text-muted pe-1 ps-3" type="button" onClick={handleSearch}>
                                    <i className="ri-search-line search-icon font-size-18"></i>
                                </button>
                            </div>
                            <input type="text" value={keySearch} onKeyUp={handleKeyPress} onChange={handleChangeSearch} className="form-control bg-light" placeholder="Search users.." />
                        </div>
                    </div>

                    <div id='_div-friends-list' className="px-2 div-contacts-list mt-1">
                        <ul className="list-unstyled chat-list contact-list-curson">
                            {
                                friends.length > 0 &&
                                friends.map((p: any) => {
                                    return <ItemFriend key={p.userUid} item={p} selectUser={handleSelectUser} />
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSave}>Add members</button>
            </div>
        </>
    )
}
