import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    newNotice: 0,
    newMess: 0
}

const noticeSlice = createSlice({
    name: 'notice',
    initialState,
    reducers: {
        setNewNoticeInc: (state, action) => {
            state.newNotice++
        },
        setNewMessInc: (state, action) => {
            state.newMess++
        },
        setNewMessDec: (state, action) => {
            state.newMess--
        },
        setInitNewNotice: (state, action) => {
            state.newNotice = action.payload
        },
        setInitNewMess: (state, action) => {
            state.newMess = action.payload
        },
    }
})

export default noticeSlice.reducer
export const { setNewNoticeInc, setNewMessInc, setInitNewNotice, setInitNewMess, setNewMessDec } = noticeSlice.actions