import Plyr from 'plyr-react'
import React, { useState, useRef, useEffect } from 'react'
import { CommonConstant } from '../../../constants/CommonConstant'
import { CommonUtil } from '../../../utils/CommonUtil'
import { FileUtil } from '../../../utils/FileUtil'
import { NewsService } from '../../../services/NewsService'
import { useNavigate } from 'react-router-dom'

export default function StoryItem(props: any) {
    const { e } = props
    const [item, setItem] = useState<any>(e)
    const plyrRef = useRef<any>()
    const isWatched = useRef<string>(e.isWatched)

    const watched = (_id: number) => {
        if (isWatched.current == 'N') {
            NewsService.getInstance().setWatchedStory(_id).then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    isWatched.current = 'Y'
                }
            }).catch((error: any) => {

            })
        }
    }

    const navigate = useNavigate()

    const [plyrs, setPlyrs] = useState<any>([]);

    useEffect(() => {
        const newPlyrs = [
            <Plyr
                options={{
                    controls: [
                        "play",
                        "play-large",
                        "progress",
                        "current-time",
                        "duration",
                        "fullscreen",
                        "mute",
                    ]
                }}
                source={{
                    type: 'video',
                    sources: [{
                        src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + item.newFleNm
                    }]
                }}
            />
        ]
        setPlyrs(newPlyrs);
        return () => {
            // You may need to implement logic here to destroy Plyr instances if necessary
        };
    }, []);

    return (
        <>
            <div id={item.id} className="col-md-3 col-xss-6 pe-2 ps-2 col-6">
                <div className="card h300 d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                    {
                        plyrs.map((plyr: any, index: number) => (
                            <div key={index} onClick={() => {
                                watched(item.id);
                            }}>
                                {plyr}
                            </div>
                        ))
                    }
                    {/* <div onClick={() => {
                        watched(item.id);
                    }}>
                        <Plyr
                            ref={plyrRef}
                            options={{
                                controls: [
                                    "play",
                                    "play-large",
                                    "progress",
                                    "current-time",
                                    "duration",
                                    "fullscreen",
                                    "mute",
                                ]
                            }}
                            source={{
                                type: 'video',
                                sources: [{
                                    src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + item.newFleNm
                                }]
                            }} />
                    </div> */}
                    <div className="card-body p-1 d-flex position-absolute" style={{ marginTop: "0.25rem" }}>
                        <figure className={`avatar me-3 ${isWatched.current == 'N' ? "not-watched-story" : ""}`}>
                            <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                onError={(event: any) => {
                                    event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                }}
                                alt="image" className="shadow-sm rounded-circle w40" />
                        </figure>
                        <h4 className="fw-700 text-grey-900 font-xssss vts-curson">
                            <span onClick={() => {
                                navigate("/chat/profile?profileId=" + item.userUid)
                            }} className='new-items-hover'>{item.fullName}</span>
                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{CommonUtil.dateComparation(new Date(item.createdDate))}</span>
                        </h4>
                    </div>
                </div>
            </div>
        </>
    )
}
