import axios from "axios";
import { HeadersUtil } from "../../../utils/headersUtil";
import { ApiUrlUtil } from "../../../utils/apiUrlUtil";
import { Paging } from "../../../model/Paging";
import { ParamUtil, RequestParam } from "../../../utils/paramUtil";
import { ContactSearch } from "../../../model/ContactSearch";

export class ContactService {

    private static _contactService: ContactService;

    public static getInstance(): ContactService {
        if (!ContactService._contactService) {
            ContactService._contactService = new ContactService();
        }
        return ContactService._contactService;
    }

    public getListContacts(model: ContactSearch) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/contact/getListContact", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public reqFriend(model: any) {
        return axios.post(process.env.REACT_APP_API_URL + "/vts/contact/req-friend", model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListFav() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/contact/getListFav");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}