import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { SettingService } from '../../../services/chat/sett/SettingService';
import { CommonConstant } from '../../../constants/CommonConstant';
import { updateUserInfo } from '../../../reducers/userSlice';
import Swal from 'sweetalert2';
import { UserInfoService } from '../../../services/UserInfoService';
import Cookies from 'universal-cookie';
import { AuthConstant } from '../../../constants/AuthConstant';
import { toast } from 'react-toastify';
import ModalComp from '../../../components/ModalComp';
import ChangePassword from './comp/ChangePassword';
import EnableFA from './comp/EnableFA';

export default function Settings() {

    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    const dispatch = useAppDispatch();
    const [settingLight, setSettingLight] = useState(false)
    const [setting, setSetting] = useState(
        {
            lightTheme: false,
            publicProfile: false,
            publicActiveStatus: false
        }
    );

    useEffect(() => {
        if (userInfo?.userUid !== undefined) {
            setSetting({
                lightTheme: userInfo.lightTheme,
                publicProfile: userInfo.publicProfile,
                publicActiveStatus: userInfo.publicActiveStatus
            })
        }
    }, [userInfo])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setSettingLight(true);
        };
        const resizeListener = () => {
            var innerWidth = window.innerWidth;
            if (innerWidth < 992) {
                setSettingLight(true);
            } else {
                setSettingLight(false);
            }
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    const handleTwoFA = () => {
        setShowPopFA(true)
    }

    const handleOnChange = (type: number) => {
        SettingService.getInstance().updateSetting(
            {
                lightTheme: type === 3 ? !setting.lightTheme : setting.lightTheme,
                publicProfile: type === 1 ? !setting.publicProfile : setting.publicProfile,
                publicActiveStatus: type === 2 ? !setting.publicActiveStatus : setting.publicActiveStatus,
                typeUpdate: type
            })
            .then((resp: any) => {
                if (resp.data.status === CommonConstant.RESULT_OK) {
                    dispatch(updateUserInfo({
                        ...userInfo,
                        lightTheme: type === 3 ? !setting.lightTheme : setting.lightTheme,
                        publicProfile: type === 1 ? !setting.publicProfile : setting.publicProfile,
                        publicActiveStatus: type === 2 ? !setting.publicActiveStatus : setting.publicActiveStatus
                    }))
                }
            }).catch((err: any) => {

            })
    }

    const handleLogoutAllDevice = () => {
        Swal.fire({
            title: `Confirm`,
            text: `Do you want to sign out on all devices ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                const cookies = new Cookies()
                let doamin = window.location.origin;
                UserInfoService.getInstance().logout().then((resp: any) => {
                    if (resp.data.status === CommonConstant.RESULT_OK) {

                    }
                    cookies.remove(AuthConstant._TOKEN)
                    window.location.href = process.env.REACT_APP_AUTH_URL + "/auth/login?actionType=logout&redirectUri=" + doamin || ""
                }).catch((err: any) => {
                    toast.error("System error. Please come back later")
                    cookies.remove(AuthConstant._TOKEN)
                    window.location.href = process.env.REACT_APP_AUTH_URL + "/auth/login?actionType=logout&redirectUri=" + doamin || ""
                })
            }
        })
    }

    const [showEditProfile, setShowEditProfile] = useState(false)
    const [showPopFA, setShowPopFA] = useState(false)

    useEffect(() => {
        if (showEditProfile) {
            $("#showEditProfile").modal("show");
        }
        if (showPopFA) {
            $("#showPopFA").modal("show");
        }
    }, [showEditProfile, showPopFA])

    const handleChangePassword = () => {
        setShowEditProfile(true);
    }

    return (
        <div>
            {
                showEditProfile && (
                    <ModalComp id="showEditProfile" onClose={() => {
                        $("#showEditProfile").modal("hide");
                        setShowEditProfile(false)
                    }} title="Change password">
                        <ChangePassword onClose={() => {
                            $("#showEditProfile").modal("hide");
                            setShowEditProfile(false)
                        }} />
                    </ModalComp>
                )
            }
            {
                showPopFA && (
                    <ModalComp id="showPopFA" onClose={() => {
                        $("#showPopFA").modal("hide");
                        setShowPopFA(false)
                    }} title="2-step authentication">
                        <EnableFA onClose={() => {
                            $("#showPopFA").modal("hide");
                            setShowPopFA(false)
                        }} />
                    </ModalComp>
                )
            }
            <div className="px-4 pt-4">
                <h4 className="mb-4">Settings</h4>
                <div className='p-2 mb-4 setting-bg' >
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-13 mb-0 text-truncate">Allow people to request friends</h5>
                        </div>
                        <div className="ms-2 me-0">
                            <div className="form-check form-switch">
                                <input onChange={() => handleOnChange(1)} type="checkbox" checked={setting.publicProfile} className="form-check-input" id="isPublicProfile" />
                                <label className="form-check-label" htmlFor="isPublicProfile"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-2 mb-4 setting-bg' >
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-13 mb-0 text-truncate">Active status</h5>
                        </div>
                        <div className="ms-2 me-0">
                            <div className="form-check form-switch">
                                <input onChange={() => handleOnChange(2)} type="checkbox" checked={setting.publicActiveStatus} className="form-check-input" id="isActiveStatus" />
                                <label className="form-check-label" htmlFor="isActiveStatus"></label>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    userInfo?.authProvider === "local" && (
                        <div className='p-2 mb-4 setting-bg' >
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-13 mb-0 text-truncate">2-step authentication</h5>
                                </div>
                                <div className="ms-2 me-0">
                                    <div className="form-check form-switch">
                                        <input onChange={handleTwoFA} type="checkbox" checked={userInfo?.faEnable || false} className="form-check-input" id="faEnable" />
                                        <label className="form-check-label" htmlFor="faEnable"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    settingLight && (
                        <div className='p-2 mb-4 setting-bg' >
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-13 mb-0 text-truncate">Light theme</h5>
                                </div>
                                <div className="ms-2 me-0">
                                    <div className="form-check form-switch">
                                        <input onChange={() => handleOnChange(3)} type="checkbox" checked={setting.lightTheme} className="form-check-input" id="isLightTheme" />
                                        <label className="form-check-label" htmlFor="isLightTheme"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="user-profile-desc" data-simplebar>
                    <div className="mb-2" style={{ display: "flex", justifyContent: "space-between" }}>
                        {
                            userInfo?.authProvider === "local" && <div className='mb-2'>
                                <button type="button" className="btn btn-light btn-sm" onClick={handleChangePassword}>
                                    <i className="ri-key-2-line me-1 ms-0 align-middle"></i> Change password
                                </button>
                            </div>
                        }
                        <div className="float-end">
                            <button type="button" className="btn btn-light btn-sm" onClick={handleLogoutAllDevice}>
                                <i className="ri-logout-circle-r-line me-1 ms-0 align-middle"></i> Sign out on all devices
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
