import React, { useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { getUserInfo } from '../reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { WebSocketService } from '../services/WebSocketService';
import { setInitNewMess, setInitNewNotice, setNewMessInc, setNewNoticeInc } from '../reducers/noticeSlice';
import { NotificationService } from '../services/chat/notifications/NotificationService';
import { CommonConstant } from '../constants/CommonConstant';
import { RoomService } from '../services/chat/mess/RoomService';
import { MessService } from '../services/chat/mess/MessService';
import { getOnlineUsers, updateListOnlineUser } from '../reducers/onlineSlice';

export default function Layout() {
  const dispatch = useAppDispatch();
  const location = useLocation()
  const roomIdSel = useRef(0);
  const urlRef = useRef("")
  urlRef.current = location.pathname

  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const chat = useAppSelector(state => state.chat.selectedRoom)

  useEffect(() => {
    if (userInfo?.userUid !== undefined) {
      initSocket(userInfo.userUid);
    }
  }, [userInfo])

  useEffect(() => {
    // if (chat.roomId !== 0) {
    //   roomIdSel.current = chat.roomId
    // }
    roomIdSel.current = chat.roomId
  }, [chat])

  const initSocket = (userUid: string) => {
    WebSocketService.getInstance().initSocket();
    WebSocketService.getInstance().socketClient.connect({ username: userUid }, () => {
      WebSocketService.getInstance().socketClient.subscribe(
        '/user/queue/messages',
        (msg: any) => {
          const temp = JSON.parse(msg.body)
          MessService.getInstance().changeReceiver(temp);
          if (temp.roomId && (temp.roomId != roomIdSel.current)) {
            dispatch(setNewMessInc(1))
          }
        },
        (err: any) => {
        }
      );
      WebSocketService.getInstance().socketClient.subscribe(
        '/user/queue/notification',
        (msg: any) => {
          if (urlRef.current == "/chat/notification") {
            NotificationService.getInstance().change(JSON.parse(msg.body));
          }
          dispatch(setNewNoticeInc(1))
        },
        (err: any) => {
        }
      );
      WebSocketService.getInstance().socketClient.subscribe(
        '/user/queue/new-room',
        (msg: any) => {
          if (urlRef.current == "/chat" || urlRef.current == "/chat/groups") {
            RoomService.getInstance().change(JSON.parse(msg.body));
          }
          dispatch(setNewMessInc(1))
        },
        (err: any) => {
        }
      );
      WebSocketService.getInstance().socketClient.subscribe(
        '/user/queue/online',
        (msg: any) => {
          dispatch(updateListOnlineUser({ type: 1, userUid: msg.body }))
        },
        (err: any) => {
        }
      );
      WebSocketService.getInstance().socketClient.subscribe(
        '/user/queue/offline',
        (msg: any) => {
          dispatch(updateListOnlineUser({ type: 0, userUid: msg.body }))
        },
        (err: any) => {
        }
      );
    },
      (err: any) => {
        setTimeout(() => {
          initSocket(userUid)
        }, 10000)
      }
    );
  }

  const getNumberNewNotice = () => {
    NotificationService.getInstance().countNewNotice().then((resp: any) => {
      if (resp.data.status == CommonConstant.RESULT_OK) {
        dispatch(setInitNewNotice(resp.data.responseData))
      }
    }).catch((err: any) => {

    })
  }

  const getNumberNewMess = () => {
    MessService.getInstance().countNewMess().then((resp: any) => {
      if (resp.data.status == CommonConstant.RESULT_OK) {
        dispatch(setInitNewMess(resp.data.responseData))
      }
    }).catch((err: any) => {

    })
  }

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getOnlineUsers());
    getNumberNewNotice();
    getNumberNewMess();
  }, [])

  return (
    <Outlet />
  )
}
