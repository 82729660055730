import React from 'react'
import { useAppSelector } from '../../../../store/hook';
import { FileUtil } from '../../../../utils/FileUtil';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { CommonUtil } from '../../../../utils/CommonUtil';
import dayjs from 'dayjs';
import axios from 'axios';
import { HeadersUtil } from '../../../../utils/headersUtil';

export default function ItemInboxTypeFle(props: any) {
    const { item, deleteMess } = props
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const handleDelete = () => {
        deleteMess(item.id)
    }

    const downloadFile = () => {
        axios.get(FileUtil.genarateUrlByteDownload(item.messPath), {
            responseType: "blob",
            headers: HeadersUtil.getHeadersAuth()
        }).then(rep => {
            if (rep.status === 200) {
                let url: any = window.URL || window.webkitURL;
                let anchor = document.createElement("a");
                anchor.href = url.createObjectURL(rep.data);
                anchor.download = item.mess;
                document.body.append(anchor);
                anchor.click();
                anchor.remove();
                window.URL.revokeObjectURL(url);
            }
        }).catch(err => {

        })
    }

    return (
        <li className={`${item.userUid === userInfo.userUid ? 'right' : ''}`}>
            <div className="conversation-list">
                <div className="chat-avatar">
                    {
                        (item.imgPath && item.imgPath !== '') ? (
                            <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                onError={(event: any) => {
                                    event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                }}
                                className="rounded-circle avatar-xs" alt="" />
                        ) : (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                    {
                                        CommonUtil.generateCharacterAvatar(item.fullName)
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="user-chat-content">
                    <div className="ctext-wrap">
                        <div className="ctext-wrap-content">
                            <div className="card p-2 mb-2">
                                <div className="d-flex flex-wrap align-items-center attached-file">
                                    <div className="avatar-sm me-3 ms-0 attached-file-avatar">
                                        <div className="avatar-title bg-primary-subtle text-primary rounded font-size-20">
                                            <i className="ri-file-text-fill"></i>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <div className="text-start">
                                            <h5 className="font-size-14 text-truncate mb-1">{item.mess}</h5>
                                            <p className="text-muted text-truncate font-size-13 mb-0">{CommonUtil.convertByte(item.messSize)}</p>
                                        </div>
                                    </div>
                                    <div className="ms-4 me-0">
                                        <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                                            <div>
                                                <a className="fw-medium vts-color-bg cuson-pointer" onClick={downloadFile}>
                                                    <i className="ri-download-2-line"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{(item.createdDate ? dayjs(item.createdDate).format('YYYY-MM-DD HH:mm') : 'underfined')}</span></p>
                        </div>
                        {
                            item.userUid === userInfo.userUid && (<div className="dropdown align-self-start">
                                <a className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ri-more-2-fill"></i>
                                </a>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" onClick={handleDelete}>Remove <i className="ri-delete-bin-line float-end text-muted"></i></a>
                                </div>
                            </div>)
                        }
                    </div>
                    <div className="conversation-name">{item.userUid !== userInfo.userUid ? item?.fullName : <>&nbsp;</>}</div>
                </div>
            </div>
        </li>
    )
}
