import axios from "axios";
import { Paging } from "../../../model/Paging";
import { ApiUrlUtil } from "../../../utils/apiUrlUtil";
import { ParamUtil, RequestParam } from "../../../utils/paramUtil";
import { HeadersUtil } from "../../../utils/headersUtil";
import { BehaviorSubject, Subject } from 'rxjs';

export class NotificationService {

    private static _notification: NotificationService;
    public _sendNoticeModel = new Subject();

    public static getInstance(): NotificationService {
        if (!NotificationService._notification) {
            NotificationService._notification = new NotificationService();
        }
        return NotificationService._notification;
    }

    public getListNotification(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/notification/getListNotification", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public countNewNotice() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/notification/countNewNotice");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getNotificationDetail(model: any, noticeId: number) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/notification/getNotificationDetail/${noticeId}`, params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public reqFriend(model: any) {
        return axios.post(process.env.REACT_APP_API_URL + "/vts/notification/req-friend", model, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public change(noticeDto: any) {
        NotificationService._notification._sendNoticeModel.next(noticeDto);
    }

    public getNewsfeed(model: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/notification/getNewsfeed", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public updateLastViewNotice(noticeId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/notification/updateLastViewNotice/${noticeId}`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}