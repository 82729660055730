import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../store/hook';
import ModalComp from '../../../components/ModalComp';
import EditProfile from './comp/EditProfile';
import EditAvatar from './comp/EditAvatar';
import { CommonUtil } from '../../../utils/CommonUtil';
import dayjs from 'dayjs';
import { FileUtil } from '../../../utils/FileUtil';
import { CommonConstant } from '../../../constants/CommonConstant';

export default function MyProfile() {
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const [showEditProfile, setShowEditProfile] = useState(false)
    const [showEditAvatar, setShowEditAvatar] = useState(false)

    const handleEditProfile = () => {
        setShowEditProfile(true)
    }

    const handleEditAvatar = () => {
        setShowEditAvatar(true)
    }

    useEffect(() => {
        if (showEditAvatar) {
            $("#showEditAvatar").modal("show");
        }
        if (showEditProfile) {
            $("#showEditProfile").modal("show");
        }
    }, [showEditAvatar, showEditProfile])

    return (
        <div>
            <div className="px-4 pt-4">
                <h4 className="mb-0">My profile</h4>
            </div>

            {
                showEditProfile && (
                    <ModalComp id="showEditProfile" onClose={() => {
                        $("#showEditProfile").modal("hide");
                        setShowEditProfile(false)
                    }} title="Edit profile">
                        <EditProfile onClose={() => {
                            $("#showEditProfile").modal("hide");
                            setShowEditProfile(false)
                        }} />
                    </ModalComp>
                )
            }

            {
                showEditAvatar && (
                    <ModalComp id="showEditAvatar" onClose={() => {
                        $("#showEditAvatar").modal("hide");
                        setShowEditAvatar(false)
                    }} title="Edit avatar">
                        <EditAvatar onClose={() => {
                            $("#showEditAvatar").modal("hide");
                            setShowEditAvatar(false)
                        }} />
                    </ModalComp>
                )
            }

            <div className="text-center border-bottom p-4">
                <div className="mb-4 profile-user">

                    <img src={userInfo?.imgPath ? FileUtil.genarateUrlGetImage(userInfo.imgPath, CommonConstant.FILE_CROP) : require("../../../../assets/images/users/user-dummy-img.jpg")}
                        className="rounded-circle avatar-lg img-thumbnail" onError={(event: any) => {
                            event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                        }} />

                    <button type="button" className="btn btn-light bg-light avatar-xs p-0 rounded-circle profile-photo-edit"
                        onClick={handleEditAvatar}>
                        <i className="ri-pencil-fill"></i>
                    </button>
                </div>
                <h5 className="font-size-16 mb-1 text-truncate">{userInfo?.fullName}</h5>
            </div>
            <div className="p-4 user-profile-desc" data-simplebar>
                <div className="mb-2">
                    <div className="float-end">
                        <button type="button" className="btn btn-light btn-sm" onClick={handleEditProfile}>
                            <i className="ri-edit-fill me-1 ms-0 align-middle"></i> Edit
                        </button>
                    </div>
                    <div>
                        <p className="text-muted mb-1">Name</p>
                        <p className="font-size-14">
                            <span className="me-1">
                                <i className="ri-profile-line"></i>
                            </span>
                            {userInfo?.fullName}</p>
                    </div>

                    <div>
                        <p className="text-muted mb-1">Gender</p>
                        <p className="font-size-14">
                            <span className="me-1">
                                {
                                    !userInfo?.gender ? <i className="ri-men-line"></i> : <i className="ri-women-line"></i>
                                }
                            </span>
                            {CommonUtil.convertGender(userInfo?.gender)}</p>
                    </div>

                    <div className="mt-4">
                        <p className="text-muted mb-1">
                            Birthday
                        </p>
                        <p className="font-size-14">
                            <span className="me-1">
                                <i className="ri-cake-2-line"></i>
                            </span>
                            {(userInfo?.dob ? dayjs(userInfo.dob).format('YYYY-MM-DD') : 'unavailable') || "unavailable"}</p>
                    </div>

                    <div className="mt-4">
                        <p className="text-muted mb-1">
                            Phone
                        </p>
                        <p className="font-size-14">
                            <span className="me-1">
                                <i className="ri-phone-line"></i>
                            </span>
                            {userInfo?.phone || "unavailable"}</p>
                    </div>

                    <div className="mt-4">
                        <p className="text-muted mb-1">
                            Email
                        </p>
                        <p className="font-size-14">
                            <span className="me-1">
                                <i className="ri-mail-send-line"></i>
                            </span>
                            {userInfo?.email}</p>
                    </div>
                </div>
            </div >
        </div >
    )
}
