import React, { useState } from 'react'
import { CommonConstant } from '../../../constants/CommonConstant';
import { FileUtil } from '../../../utils/FileUtil';
import { CommonUtil } from '../../../utils/CommonUtil';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setRepplyCommentId } from '../../../reducers/commentSlice';
import { NewsService } from '../../../services/NewsService';
import Swal from 'sweetalert2';

export default function CommentItem(props: any) {
    const { removeCommentFun, goToMyPage } = props;
    const [item, setItem] = useState(props.item);
    const repplyCommentId = useAppSelector(state => state.comment.repplyCommentId)
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    const dispatch = useAppDispatch()
    const repplyComment = () => {
        dispatch(setRepplyCommentId(item.ID))
    }

    const [commentText, setCommentText] = useState("");

    const handleChange = (event: any) => {
        setCommentText(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            if (commentText.trim() != "") {

                NewsService.getInstance().saveComment({ content: commentText.trim(), upCommentId: repplyCommentId }).then(resp => {
                    if (resp.data.status == CommonConstant.RESULT_OK) {
                        let obj: any = {
                            ID: resp.data.responseData.id,
                            CONTENT: commentText.trim(),
                            CREATED_DATE: new Date(),
                            UP_COMMENT_ID: repplyCommentId,
                            USER_UID: userInfo.userUid,
                            childs: [],
                            commentBy: {
                                fullName: userInfo.fullName,
                                imgPath: userInfo.imgPath
                            }
                        }
                        setItem({
                            ...item,
                            childs: [...item.childs, obj]
                        })
                        dispatch(setRepplyCommentId(0))
                        setCommentText("");
                    }
                }).catch(error => {

                })
            }
        }
    }

    const removeComment = () => {
        Swal.fire({
            title: `Confirm`,
            text: `Do you want to delete this comment ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                removeCommentFun(item.ID)
            }
        })
    }

    const extractUrls = (text: string) => {
        text = text.replace(/<[^>]*>/g, '')
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = text.match(urlRegex) || [];
        for (let i = 0; i < urls.length; i++) {
            text = text.replace(urls[i], `<a href='${urls[i]}' class='new-items-hover' target='_blank'>${urls[i]}</a>`)
        }
        // text = text.replaceAll("\n", "<br/>");
        return text;
    }

    return (
        <div className="card-body p-0 d-flex">
            <figure className="avatar me-3" style={{ position: "relative" }}>
                <img src={FileUtil.genarateUrlGetImage(item.commentBy.imgPath, CommonConstant.FILE_THUMB)}
                    onError={(event: any) => {
                        event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                    }}
                    alt="image" className="shadow-sm rounded-circle w45" />
                <div className={`${item.childs.length > 0 ? 'vts-draw-line' : ''}`}></div>
                <div className={`${item.UP_COMMENT_ID != undefined ? 'vts-draw-line-ngang' : ''}`}></div>
            </figure>
            <div style={{ width: "100%" }}>
                <div>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1 vts-curson">
                        <span className='new-items-hover' onClick={() => {
                            goToMyPage(item.USER_UID)
                        }}>{item.commentBy.fullName}</span>
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{CommonUtil.dateComparation(new Date(item.CREATED_DATE))}
                            {
                                item.USER_UID == userInfo.userUid && <a onClick={removeComment} className="fw-600 text-vts ms-2 vts-curson">Remove</a>
                            }
                            <a onClick={repplyComment} className="fw-600 text-primary ms-2 vts-curson">Repply</a>
                        </span>
                    </h4>
                </div>
                <p className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500" dangerouslySetInnerHTML={{ __html: extractUrls(item.CONTENT) }}></p>
                {
                    repplyCommentId == item.ID &&
                    <div className="form-group mb-1 icon-input">
                        <i className="feather-message-circle font-sm text-grey-400"></i>
                        <input type="text" value={commentText} onKeyUp={handleKeyPress} onChange={handleChange} placeholder="Comment..." style={{ width: "100%", outline: "none" }} className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl theme-dark-bg" />
                    </div>
                }

                {
                    item.childs.length > 0 &&
                    item.childs.map((e: any) => {
                        return <CommentItem key={e.ID} item={e} removeCommentFun={(commentId: number) => {
                            NewsService.getInstance().deleteComment(commentId).then(resp => {
                                if (resp.data.status == CommonConstant.RESULT_OK) {
                                    const array: any = [...item.childs];
                                    const index = array.findIndex((element: any) => element.ID == commentId);
                                    if (index != -1) {
                                        array.splice(index, 1)
                                        setItem({
                                            ...item,
                                            childs: array
                                        })
                                    }
                                }
                            }).catch(error => {

                            })
                        }} goToMyPage={goToMyPage} />
                    })
                }

            </div>
        </div>
    )
}
