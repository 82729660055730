import React, { useState, useEffect } from 'react'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { NotificationService } from '../../../../services/chat/notifications/NotificationService'

export default function PopupBirthDayNotification(props: any) {
    const { data } = props

    const [model, setModel] = useState(data)

    useEffect(() => {
        NotificationService.getInstance().getNotificationDetail({ isNew: data.isNew, noticeType: data.noticeType }, data.id).then((resp: any) => {

        }).catch((err: any) => {

        })
    }, [])

    return (
        <div className="modal-body">
            <div className="text-center border-bottom">
                <div className="mb-4">
                    <img src={require("../../../../../assets/images/happy_birthday.gif")}
                        onError={(event: any) => {
                            event.target.src = require("../../../../../assets/images/happy_birthday.gif")
                        }}
                        alt="" style={{maxWidth: "400px"}} />
                </div>

                <div className="p-4">
                    <div className="text-muted">
                        <p className="mb-4">{model.content}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
