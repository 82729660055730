import React from 'react'
import { FileUtil } from '../../../../utils/FileUtil'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { useAppSelector } from '../../../../store/hook'

export default function ItemFriend(props: any) {
    const { item, selectUser } = props

    const onlineUsers = useAppSelector(state => state.onlineUsers.onlineUsers)

    const handleOnChange = (event: any) => {
        if (event.target.checked) {
            selectUser(true, item.userUid)
        } else {
            selectUser(false, item.userUid)
        }
    }

    return (
        <li className="unread">
            <a>
                <div className="d-flex">
                    {
                        (item.imgPath && item.imgPath != '') ? (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: item.userUid }])}`}>
                                <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="rounded-circle avatar-xs" alt="" />
                                <span className="user-status"></span>
                            </div>
                        ) : (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: item.userUid }])}`}>
                                <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {
                                            CommonUtil.generateCharacterAvatar(item.fullName)
                                        }
                                    </span>
                                </div>
                                <span className="user-status"></span>
                            </div>
                        )
                    }
                    <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15 mb-1">{item.fullName}</h5>
                        <p className="chat-user-message text-truncate mb-0"><i className="ri-cake-2-line align-middle me-1 ms-0"></i> {item.dob || "unavailable"}</p>
                    </div>
                    <div className="contacts-lst">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={item.IS_CHECKED == 'Y' ? true : false} onChange={handleOnChange} />
                        </div>
                    </div>
                </div>
            </a>
        </li>
    )
}
