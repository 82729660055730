import axios from "axios";
import { HeadersUtil } from "../../../utils/headersUtil";
import { ApiUrlUtil } from "../../../utils/apiUrlUtil";
import { Paging } from "../../../model/Paging";
import { ParamUtil, RequestParam } from "../../../utils/paramUtil";
import { BehaviorSubject, Subject } from "rxjs";

export class RoomService {

    private static _roomService: RoomService;
    public _sendRoomModel = new Subject();

    public static getInstance(): RoomService {
        if (!RoomService._roomService) {
            RoomService._roomService = new RoomService();
        }
        return RoomService._roomService;
    }

    public getListRooms(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/room/getListRoom", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public change(roomDto: any) {
        RoomService._roomService._sendRoomModel.next(roomDto);
    }

    public getDetailChatRoom(roomId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/room/${roomId}/getDetailChatRoom`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListChatRoomGroup(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/room/getListChatRoomGroup", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getFriend(model: Paging) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/room/getFriend", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public createRoomGroup(roomDto: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/room/create-room-group`);
        return axios.post(url, roomDto, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public deleteConversation(roomId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/room/${roomId}/delete-conversation`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public leaveGroup(roomId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/room/${roomId}/leave-group`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public removeUserFromGroup(data: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/room/removeUserFromGroup");
        return axios.post(url, data, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getFriendForGroup(model: Paging, roomId: number) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/room/${roomId}/getFriendForGroup`, params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public addMembers(roomDto: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/room/addMembers`);
        return axios.post(url, roomDto, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}