import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { SettingService } from '../../../services/chat/sett/SettingService'
import { FileUtil } from '../../../utils/FileUtil'
import { CommonConstant } from '../../../constants/CommonConstant'
import Cookies from 'universal-cookie'
import { AuthConstant } from '../../../constants/AuthConstant'
import { setSelectedRoom } from '../../../reducers/chatSlice'

export default function SideBarLeft() {

    const [theme, setTheme] = useState('light')
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    const url = location.pathname

    const notice = useAppSelector(state => state.notice)
    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);

    useEffect(() => {
        if (userInfo?.userUid !== undefined) {
            setTheme(userInfo.lightTheme ? 'light' : 'dark')
        }
    }, [userInfo])

    const handleTheme = () => {
        SettingService.getInstance().updateSetting({ lightTheme: theme == 'light' ? false : true, publicProfile: userInfo?.publicProfile, publicActiveStatus: userInfo?.publicActiveStatus }).then((resp: any) => {

        }).catch((err: any) => {

        })
        setTheme((prev: string) => {
            return prev === 'light' ? 'dark' : 'light';
        })
    }

    useEffect(() => {
        document.body.setAttribute('data-bs-theme', theme);
    }, [theme])

    const handleNavigate = (url: string) => {
        navigate(url)
    }

    const genderNotice = (num: number) => {
        if (num > 9) {
            return "+9";
        } else {
            return num
        }
    }

    const handleLogout = () => {
        const cookies = new Cookies()
        cookies.remove(AuthConstant._TOKEN)
        let doamin = window.location.origin;
        window.location.href = process.env.REACT_APP_AUTH_URL + "/auth/login?actionType=logout&redirectUri=" + doamin || ""
    }

    return (
        <div className="side-menu flex-lg-column me-lg-1 ms-lg-0">
            <div className="navbar-brand-box">
                <a href="" className="logo logo-dark" style={{ display: `${theme === 'light' ? 'none' : 'block'}` }}>
                    <span className="logo-sm">
                        <img src={require("../../../../assets/images/logo-11-1.png")} alt="" height="25" onError={(event: any) => {
                            event.target.src = require("../../../../assets/images/logo-11-1.png")
                        }} />
                    </span>
                </a>

                <a href="" className="logo logo-light" style={{ display: `${theme === 'light' ? 'block' : 'none'}` }}>
                    <span className="logo-sm">
                        <img src={require("../../../../assets/images/logo-11-1.png")} alt="" height="25" onError={(event: any) => {
                            event.target.src = require("../../../../assets/images/logo-11-1.png")
                        }} />
                    </span>
                </a>
            </div>

            <div className="flex-lg-column my-auto">
                <ul className="nav nav-pills side-menu-nav justify-content-center" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link ${url === '/chat/newsfeed' ? 'active' : ''}`}
                            onClick={() => {
                                if (window.innerWidth >= 992) {
                                    dispatch(setSelectedRoom({
                                        roomId: 0,
                                        roomType: "",
                                        roomName: "",
                                        roomImg: ""
                                    }))
                                    if (url.startsWith("/chat/profile")) {
                                        handleNavigate("/chat")
                                    }
                                } else {
                                    handleNavigate("/chat/newsfeed")
                                }
                            }}>
                            <i className="ri-dashboard-line"></i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link menu-position-relative ${url === '/chat/notification' ? 'active' : ''}`} onClick={() => handleNavigate("/chat/notification")}>
                            <i className="ri-notification-2-line">
                                {
                                    notice.newNotice > 0 && <span className="badge badge-notification">{genderNotice(notice.newNotice)}</span>
                                }
                            </i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link menu-position-relative ${url === '/chat' ? 'active' : ''}`} onClick={() => handleNavigate("/chat")}>
                            <i className="ri-message-3-line">
                                {
                                    notice.newMess > 0 && <span className="badge badge-notification">{genderNotice(notice.newMess)}</span>
                                }
                            </i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${url === '/chat/groups' ? 'active' : ''}`} onClick={() => handleNavigate("/chat/groups")}>
                            <i className="ri-group-line"></i>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${url === '/chat/contacts' ? 'active' : ''}`} onClick={() => handleNavigate("/chat/contacts")}>
                            <i className="ri-contacts-line"></i>
                        </a>
                    </li>
                    <li className="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none">
                        <a className="nav-link dropdown-toggle active" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="chat-user-img online align-self-center ms-0">
                                <img src={userInfo?.imgPath ? FileUtil.genarateUrlGetImage(userInfo.imgPath, CommonConstant.FILE_THUMB) : require("../../../../assets/images/users/user-dummy-img.jpg")} alt="" className="profile-user rounded-circle mb-5px" onError={(event: any) => {
                                    event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                }} />
                                <span className="user-status"></span>
                            </div>
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={() => handleNavigate("/chat/profile?profileId=" + userInfo.userUid)}>My profile <i className="ri-profile-line float-end text-muted"></i></a>
                            <a className="dropdown-item" onClick={() => handleNavigate("/chat/settings")}>Setting <i className="ri-settings-3-line float-end text-muted"></i></a>
                            <a className="dropdown-item" onClick={() => handleNavigate("/chat/news")}>News <i className="ri-newspaper-line float-end text-muted"></i></a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={handleLogout}>Log out <i className="ri-logout-circle-r-line float-end text-muted"></i></a>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="flex-lg-column d-none d-lg-block">
                <ul className="nav side-menu-nav justify-content-center">
                    <li className="nav-item">
                        <a className={`nav-link ${url === '/chat/news' ? 'active' : ''}`} onClick={() => handleNavigate("/chat/news")}>
                            <i className="ri-newspaper-line"></i>
                        </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className={`nav-link ${url === '/chat/settings' ? 'active' : ''}`} onClick={() => handleNavigate("/chat/settings")}>
                            <i className="ri-settings-2-line"></i>
                        </a>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link light-dark-mode" onClick={handleTheme}>
                            <i className='ri-sun-line theme-mode-icon'></i>
                        </a>
                    </li>

                    <li className="nav-item btn-group dropup profile-user-dropdown">
                        <a className="nav-link dropdown-toggle active" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="chat-user-img online align-self-center ms-0">
                                <img src={userInfo?.imgPath ? FileUtil.genarateUrlGetImage(userInfo.imgPath, CommonConstant.FILE_THUMB) : require("../../../../assets/images/users/user-dummy-img.jpg")} alt="" className="profile-user rounded-circle mb-5px" onError={(event: any) => {
                                    event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                }} />
                                <span className="user-status"></span>
                            </div>
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={() => handleNavigate("/chat/profile?profileId=" + userInfo.userUid)}>My profile <i className="ri-profile-line float-end text-muted"></i></a>
                            <a className="dropdown-item" onClick={() => handleNavigate("/chat/settings")}>Setting <i className="ri-settings-3-line float-end text-muted"></i></a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={handleLogout}>Log out <i className="ri-logout-circle-r-line float-end text-muted"></i></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
