
const genarateUrl = (newFleNm: string, typeUse: string) => {
    return process.env.REACT_APP_API_MEDIA_URL + `/public/getImage?typeUse=${typeUse}&atchFleSeqNm=${newFleNm}`
}

const genarateUrlByte = (newFleNm: string, typeUse: string) => {
    return process.env.REACT_APP_API_URL + `/file/get-byte-array?typeUse=${typeUse}&atchFleSeqNm=${newFleNm}`
}

const genarateUrlByteDownload = (newFleNm: string) => {
    return process.env.REACT_APP_API_URL + `/file/get-byte-array/download?atchFleSeqNm=${newFleNm}`
}

export const FileUtil = {
    genarateUrlGetImage: genarateUrl,
    genarateUrlGetByte: genarateUrlByte,
    genarateUrlByteDownload: genarateUrlByteDownload
}