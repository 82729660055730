import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { UserInfoService } from '../../../../services/UserInfoService'
import { CommonConstant } from '../../../../constants/CommonConstant'
import Cookies from 'universal-cookie'
import { AuthConstant } from '../../../../constants/AuthConstant'

export default function ChangePassword(props: any) {
    const [model, setModel] = useState({ oldPassword: "", newPassword: "", repeatPassword: "" })
    const handleChange = (event: any) => {
        setModel({
            ...model,
            [event.target.name]: event.target.value
        })
    }
    const handleSave = () => {
        if (chk()) {
            Swal.fire({
                title: `Confirm`,
                text: `Do you want to change your password ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#89B449',
                cancelButtonColor: '#E68A8C',
                confirmButtonText: "Yes",
                cancelButtonText: "No"
            }).then((result) => {
                if (result.value) {
                    UserInfoService.getInstance().changePassword(model).then((resp: any) => {
                        if (resp.data.status == CommonConstant.RESULT_OK) {
                            toast.success("Password changed successfully");
                            const cookies = new Cookies()
                            let doamin = window.location.origin;
                            cookies.remove(AuthConstant._TOKEN)
                            setTimeout(() => {
                                window.location.href = process.env.REACT_APP_AUTH_URL + "/auth/login?actionType=logout&redirectUri=" + doamin || ""
                            }, 1000)
                        } else {
                            toast.error("Password change failed")
                        }
                    }).catch((error: any) => {
                        toast.error("System error. Please come back later")
                    })
                }
            })
        }
    }

    const chk = () => {
        if (model.oldPassword == undefined || model.oldPassword == "") {
            toast.error("Old password cannot be blank")
            return false;
        }
        if (model.newPassword == undefined || model.newPassword == "") {
            toast.error("New password cannot be left blank")
            return false;
        }
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\&])[0-9a-zA-Z!@#\$%\&]{8,}$/.test(model.newPassword) === false) {
            toast.error("Password from 8-32 characters, including: uppercase letters, lowercase letters, numbers and special characters !, @, #, &, %, &");
            return false;
        }
        if (model.newPassword !== model.repeatPassword) {
            toast.error("Passwords do not match")
            return false;
        }
        return true;
    }

    const [showPass, setShowPass] = useState(0)

    const handleHideShowPass = (typeNumber: number) => {
        setShowPass(typeNumber)
    }

    return (
        <>
            <div className="modal-body p-4">
                <form>
                    <div className="mb-3 vts-preview-pass-parent">
                        <label className="form-label">Old password <strong className='color-red'>*</strong></label>
                        <input type={`${showPass == 1 ? "text" : "password"}`} onChange={handleChange} name='oldPassword' value={model.oldPassword || ""} className="form-control" placeholder="Enter old password" />
                        {
                            showPass == 1 ? <i onClick={() => handleHideShowPass(0)} className="ri-eye-off-line vts-preview-pass-eyes"></i> :
                                <i onClick={() => handleHideShowPass(1)} className="ri-eye-line vts-preview-pass-eyes"></i>
                        }
                    </div>
                    <div className="mb-3 vts-preview-pass-parent">
                        <label className="form-label">New password <strong className='color-red'>*</strong></label>
                        <input type={`${showPass == 2 ? "text" : "password"}`} onChange={handleChange} name='newPassword' value={model.newPassword || ""} className="form-control" placeholder="Enter new password" />
                        {
                            showPass == 2 ? <i onClick={() => handleHideShowPass(0)} className="ri-eye-off-line vts-preview-pass-eyes"></i> :
                                <i onClick={() => handleHideShowPass(2)} className="ri-eye-line vts-preview-pass-eyes"></i>
                        }
                    </div>
                    <div className="mb-3 vts-preview-pass-parent">
                        <label className="form-label">Repeat new password <strong className='color-red'>*</strong></label>
                        <input type={`${showPass == 3 ? "text" : "password"}`} onChange={handleChange} name='repeatPassword' value={model.repeatPassword || ""} className="form-control" placeholder="Enter repeat password" />
                        {
                            showPass == 3 ? <i onClick={() => handleHideShowPass(0)} className="ri-eye-off-line vts-preview-pass-eyes"></i> :
                                <i onClick={() => handleHideShowPass(3)} className="ri-eye-line vts-preview-pass-eyes"></i>
                        }
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
            </div>
        </>
    )
}
