import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { CommonUtil } from '../../../../utils/CommonUtil';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { FileUtil } from '../../../../utils/FileUtil';
import { setSelectedRoom } from '../../../../reducers/chatSlice';
import { setNewMessDec } from '../../../../reducers/noticeSlice';
import { AseUtil } from '../../../../utils/AseUtil';

export default function ItemMess(props: any) {

    const { data } = props

    const [model, setModel] = useState(data)

    useEffect(() => {
        setModel(data)
    }, [data.updatedDate])

    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);
    const chat = useAppSelector(state => state.chat.selectedRoom)
    const onlineUsers = useAppSelector(state => state.onlineUsers.onlineUsers)
    const dispatch = useAppDispatch()

    const renderMess = (messDto: any) => {
        if (!messDto) return "";
        if (messDto.messType === CommonConstant.MESS_TYPE_TEXT || messDto.messType === CommonConstant.MESS_TYPE_NOTICE || messDto.messType === CommonConstant.MESS_TYPE_RECALL) {
            return messDto.userUid === userInfo.userUid ? ("You: " + AseUtil.decryptedAES(messDto.userUid, messDto.mess || "")) : AseUtil.decryptedAES(messDto.userUid, messDto.mess || "")
        } else {
            const obj = CommonUtil.convertMessTypeMedia(messDto.messType);
            if (obj.text === undefined) return ""
            return (
                <>
                    {messDto.userUid === userInfo.userUid ? "You: " : ""} <i className={`${obj.icon} align-middle ms-0`}></i> {obj.text}
                </>
            )
        }
    }

    const selectedRoom = () => {
        if (chat.roomId !== model.id) {
            dispatch(setSelectedRoom({
                roomId: model.id,
                roomType: model.roomType,
                roomName: model.roomName,
                roomImg: model.roomImg
            }))
            if (CommonConstant.NO === model.lastViewed) {
                setModel({
                    ...model,
                    lastViewed: CommonConstant.YES
                })
                dispatch(setNewMessDec(1))
            }
        } else {
            if (CommonConstant.NO === model.lastViewed) {
                setModel({
                    ...model,
                    lastViewed: CommonConstant.YES
                })
            }
        }
    }

    return (
        <li className={`${chat.roomId === model.id ? 'active' : ''}`}>
            <a className='cuson-pointer' onClick={selectedRoom}>
                <div className="d-flex">
                    {
                        (model.roomImg && model.roomImg !== '') ? (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, model.listUser)}`}>
                                <img src={FileUtil.genarateUrlGetImage(model.roomImg, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="rounded-circle avatar-xs" alt="" />
                                <span className="user-status"></span>
                            </div>
                        ) : (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, model.listUser)}`}>
                                <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {
                                            model.roomType === CommonConstant.ROOM_SINGLE ? CommonUtil.generateCharacterAvatar(model.roomName) : <i className="ri-group-line"></i>
                                        }
                                    </span>
                                </div>
                                <span className="user-status"></span>
                            </div>
                        )
                    }

                    <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15 mb-1">{model.roomName}</h5>
                        <p className="chat-user-message text-truncate mb-0">
                            {
                                renderMess(model.messDto)
                            }
                        </p>
                    </div>
                    <div className="font-size-11 text-truncate">{CommonUtil.dateComparation(new Date(model.updatedDate))}</div>
                    {
                        CommonConstant.NO === model.lastViewed && (
                            <div className="unread-message">
                                <span className="badge badge-soft-danger rounded-pill">N</span>
                            </div>
                        )
                    }
                </div>
            </a>
        </li>
    )
}
