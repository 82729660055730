import { Navigate } from "react-router-dom";
import Layout from "../components/Layout";
import { chatRouter } from "./chatRouter";
import AuthGuard from "../guard/AuthGuard";

export const indexRouter: any = {
    path: "",
    element: <AuthGuard><Layout /></AuthGuard>,
    children: [
        { path: "", element: <Navigate to="/chat" replace /> },
        chatRouter
    ]
}
