import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../store/hook';
import { CommonConstant } from '../../../constants/CommonConstant';
import { NewsService } from '../../../services/NewsService';
import ModalComp from '../../../components/ModalComp';
import NewsItemDetail from '../../newsfeed/NewsItemDetail';
import NewsItem from '../../newsfeed/NewsItem';
import { FileUtil } from '../../../utils/FileUtil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserInfoService } from '../../../services/UserInfoService';
import { CommonUtil } from '../../../utils/CommonUtil';
import dayjs from 'dayjs';
import EditCoverImg from './comp/EditCoverImg';
import Plyr from 'plyr-react';
import Swal from 'sweetalert2';

export default function ProfilePage() {


    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);
    const [list, setList] = useState<any>([]);
    const [listImg, setListImg] = useState<any>([]);
    const [listStory, setListStory] = useState<any>([]);

    const listInnerRef = useRef<any>();
    const isLoad = useRef(true);
    const offset = useRef(0);

    const map = useRef<any>(new Map())

    const genMap = (items: any, post: any) => {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            map.current.set(item['atchFleSeq'], post)
        }
    }

    const getNewsFeed = (type: number, profileId: string) => {
        isLoad.current = false;
        NewsService.getInstance().getNewsFeedMySelf({ limit: CommonConstant.LIMIT, offset: offset.current, profileId: profileId }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                if (resp.data.responseData.length == CommonConstant.LIMIT) {
                    isLoad.current = true;
                }
                let array = resp.data.responseData;
                if (type == 0) {
                    setList(array)
                    let photos: any = [];
                    for (let i = 0; i < array.length; i++) {
                        let item = array[i];
                        genMap(item['photos'], item);
                        photos = [...photos, ...item['photos']]
                    }
                    setListImg(photos);
                } else {
                    setList([...list, ...array])
                    let photos: any = [];
                    for (let i = 0; i < array.length; i++) {
                        let item = array[i];
                        genMap(item['photos'], item);
                        photos = [...photos, ...item['photos']]
                    }
                    setListImg([...listImg, ...photos]);
                }
            }
        }).catch((error: any) => {

        })
        offset.current++;
    }

    useEffect(() => {
        listInnerRef.current = document.getElementById('__newsfeed_ofself');
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [list])

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight >= scrollHeight;

            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    getNewsFeed(1, temp.current)
                }
            }
        }
    };

    const [showEditCover, setShowEditCover] = useState(false)

    const handleEditCover = () => {
        setShowEditCover(true)
    }

    const [showDetail, setShowNewsFeedDetail] = useState(false)
    useEffect(() => {
        if (showDetail) {
            $("#showNewsFeedDetailPopup").modal("show");
        }
        if (showEditCover) {
            $("#showEditCoverImg").modal("show");
        }
    }, [showDetail, showEditCover])

    const selectItem = useRef<any>();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const temp = useRef("");
    const profileId = searchParams.get("profileId");

    const [profile, setProfile] = useState<any>();
    const isLoadMore = useRef(true)
    const offsetStory = useRef(0)

    useEffect(() => {
        if (profileId != null) {
            UserInfoService.getInstance().getProfile(profileId).then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    setProfile(resp.data.responseData)
                } else {
                    navigate("/chat")
                }
            }).catch((error: any) => {
                navigate("/chat")
            })
            offset.current = 0;
            offsetStory.current = 0;
            isLoadMore.current = true;
            getNewsFeed(0, profileId)
            temp.current = profileId;
            setListStory((prev: any) => {
                return []
            });
            getListStory()
        }
        $("#__newsfeed_ofself").animate({ scrollTop: 0 }, "slow");
    }, [profileId])

    const getListStory = () => {
        NewsService.getInstance().getListStoryForProfile({ profileId: profileId, limit: 3, offset: offsetStory.current }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                if (resp.data.responseData.length < 3) {
                    isLoadMore.current = false;
                }
                // setListStory([...listStory, ...resp.data.responseData])
                setListStory((prev: any) => {
                    return [
                        ...prev,
                        ...resp.data.responseData
                    ]
                })
                offsetStory.current += 3
            }
        }).catch((error: any) => {

        })
    }

    const loadmore = () => {
        if (isLoadMore.current) {
            getListStory()
        }
    }

    const removeStory = (_id: number) => {
        Swal.fire({
            title: `Confirm`,
            text: `Do you want to delete this story ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                const array: any = [...listStory];
                const index = array.findIndex((element: any) => element.id == _id);
                if (index != -1) {
                    array.splice(index, 1)
                    setListStory(array)
                }
                NewsService.getInstance().removeStory(_id).then(resp => {
                    if (resp.data.status == CommonConstant.RESULT_OK) {

                    }
                }).catch(error => {

                })
            }
        })
    }

    const viewDetail = (atchFleSeq: string) => {
        selectItem.current = map.current.get(atchFleSeq)
        setShowNewsFeedDetail(true);
    }

    const [plyrs, setPlyrs] = useState<any>([])

    useEffect(() => {
        const newPlyrs = listStory.map((p: any, index: number) => {
            return (
                {
                    id: p.id,
                    createdDate: p.createdDate,
                    jsxEle: <Plyr
                        options={{
                            controls: [
                                "play",
                                "play-large",
                                "progress",
                                "current-time",
                                "duration",
                                "volume",
                                "fullscreen",
                                "mute"
                            ]
                        }}
                        source={{
                            type: 'video',
                            sources: [{
                                src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm
                            }]
                        }}
                    />
                }
            );
        });
        setPlyrs(newPlyrs);
        return () => {
            // You may need to implement logic here to destroy Plyr instances if necessary
        };
    }, [listStory]);

    return (
        <div id='__newsfeed_ofself' className={`${window.innerWidth >= 992 ? 'row' : ''} feed-body`} style={{ height: "100vh", overflow: "auto", justifyContent: "center" }}>
            {
                showDetail && (
                    <ModalComp id="showNewsFeedDetailPopup" onClose={() => {
                        $("#showNewsFeedDetailPopup").modal("hide");
                        selectItem.current = null;
                        setShowNewsFeedDetail(false)
                    }} classSize="modal-lg">
                        <NewsItemDetail item={selectItem.current} goToMyPage={(userUid: string) => {
                            $("#showNewsFeedDetailPopup").modal("hide");
                            selectItem.current = null;
                            setShowNewsFeedDetail(false)
                            navigate("/chat/profile?profileId=" + userUid)
                        }} />
                    </ModalComp>
                )
            }

            {
                showEditCover && (
                    <ModalComp id="showEditCoverImg" onClose={() => {
                        $("#showEditCoverImg").modal("hide");
                        setShowEditCover(false)
                    }} title="Edit cover image">
                        <EditCoverImg onClose={(coverImgPath: string) => {
                            $("#showEditCoverImg").modal("hide");
                            setShowEditCover(false)
                            setProfile({
                                ...profile,
                                coverImgPath: coverImgPath
                            })
                        }} />
                    </ModalComp>
                )
            }
            <div className='main-content right-chat-active'>
                <div className='middle-sidebar-bottom'>
                    <div className='middle-sidebar-left'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3" style={{ position: "relative" }}>
                                        <img src={profile?.coverImgPath ? FileUtil.genarateUrlGetImage(profile.coverImgPath, CommonConstant.FILE_CROP) : require("../../../../assets/images/poster-1.png")}
                                            alt="image" style={{ width: "100%" }} />
                                        {
                                            userInfo?.userUid == profileId &&
                                            <button onClick={handleEditCover} type="button" style={{ position: "absolute", right: "10px", bottom: "10px" }} className="btn btn-light bg-light avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <i className="ri-pencil-fill"></i>
                                            </button>
                                        }
                                    </div>
                                    <div className="card-body p-0 position-relative">
                                        <figure className="avatar position-absolute w100 z-index-1" style={{ top: "-40px", left: "30px" }}>

                                            <img src={profile?.imgPath ? FileUtil.genarateUrlGetImage(profile.imgPath, CommonConstant.FILE_CROP) : require("../../../../assets/images/users/user-dummy-img.jpg")}
                                                className="float-right p-1 bg-white rounded-circle w-100" onError={(event: any) => {
                                                    event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                                }} />
                                        </figure>
                                        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{profile?.fullName} {(window.innerWidth < 992 && profileId == userInfo.userUid) && <i onClick={() => {
                                            navigate("/chat/myself")
                                        }} className="btn-round-sm feather-edit-3 bg-greylight"></i>} <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{profile?.email}</span></h4>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-xxl-4 col-lg-4'>
                                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                    <div className="card-body d-block p-4">
                                        <h4 className="fw-700 font-xsss text-grey-900">About</h4>
                                    </div>
                                    <div className="card-body border-top-xs d-flex">
                                        <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-700 text-grey-900 font-xssss mt-0">Birthday
                                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{(profile?.dob ? dayjs(profile.dob).format('YYYY-MM-DD') : 'unavailable') || "unavailable"}</span></h4>
                                    </div>

                                    <div className="card-body d-flex pt-0">
                                        <i className="feather-phone text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-700 text-grey-900 font-xssss mt-0">Phone
                                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{profile?.phone || "unavailable"}</span></h4>
                                    </div>
                                    <div className="card-body d-flex pt-0">
                                        <i className="feather-users text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-700 text-grey-900 font-xssss mt-1">Gender
                                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{CommonUtil.convertGender(profile?.gender)}</span></h4>
                                    </div>
                                </div>

                                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                    <div className="card-body d-flex align-items-center p-4">
                                        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Stories</h4>
                                    </div>
                                    <div className="card-body d-block pt-0 pb-2">
                                        <div className="row">
                                            {
                                                listStory.length > 0 &&
                                                plyrs.map((plyr: any) => {
                                                    return <div key={plyr.id} className="col-12 mb-2 pe-1">

                                                        <div className='p-2' style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <a className="fw-600 text-grey-500 vts-curson"><i className="feather-clock"></i> {CommonUtil.dateComparation(new Date(plyr.createdDate))}</a>
                                                            {
                                                                userInfo?.userUid == profileId && <a className="fw-600 text-grey-500 vts-curson" onClick={() => removeStory(plyr.id)}>Remove <i className='feather-chevrons-down'></i></a>
                                                            }
                                                        </div>
                                                        {
                                                            plyr.jsxEle
                                                        }
                                                        {/* <Plyr
                                                            options={{
                                                                controls: [
                                                                    "play",
                                                                    "play-large",
                                                                    "progress",
                                                                    "current-time",
                                                                    "duration",
                                                                    "volume",
                                                                    "fullscreen",
                                                                    "mute"
                                                                ]
                                                            }}
                                                            source={{
                                                                type: 'video',
                                                                sources: [{
                                                                    src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm
                                                                }]
                                                            }}
                                                        /> */}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        isLoadMore.current && (
                                            <div className='p-2' style={{ textAlign: "right" }}>
                                                <a className="fw-600 text-primary ms-2 vts-curson" onClick={loadmore}>Show more</a>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                    <div className="card-body d-flex align-items-center p-4">
                                        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Photos</h4>
                                    </div>
                                    <div className="card-body d-block pt-0 pb-2">
                                        <div className="row">
                                            {
                                                listImg.length > 0 &&
                                                listImg.map((p: any) => {
                                                    return <div key={p.atchFleSeq} className="col-6 mb-2 pe-1">
                                                        <a data-lightbox="roadtrip" onClick={() => viewDetail(p.atchFleSeq)} className='newfeed-post'><img src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                                            onError={(event: any) => {
                                                                event.target.src = require("../../../../assets/images/not_image.png")
                                                            }} alt="image" className="img-fluid rounded-3 w-100 img-height" /></a></div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-xl-8 col-xxl-8 col-lg-8 mb-3'>
                                {
                                    list.length > 0 ?
                                        list.map((p: any) => {
                                            return <NewsItem key={p.id} item={p} selectItem={(item: any) => {
                                                selectItem.current = item
                                                setShowNewsFeedDetail(true);
                                            }} removeNewsfeedFnc={(newsfeedId: number) => {
                                                const array: any = [...list];
                                                const index = array.findIndex((element: any) => element.id == newsfeedId);
                                                if (index != -1) {
                                                    array.splice(index, 1)
                                                    setList(array)
                                                }
                                                NewsService.getInstance().removeNewsfeed(newsfeedId).then(resp => {
                                                    if (resp.data.status == CommonConstant.RESULT_OK) {

                                                    }
                                                }).catch(error => {

                                                })
                                            }} />
                                        }) :
                                        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">There are no posts yet.</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
