import React, { useEffect, useRef, useState } from 'react'
import '../../../../assets/css/newsfeed.css'
import '../../../../assets/css/themify-icons.css'
import '../../../../assets/css/feather.css'
import NewsItem from '../../newsfeed/NewsItem'
import NewsHeader from '../../newsfeed/NewsHeader'
import { NewsService } from '../../../services/NewsService'
import { CommonConstant } from '../../../constants/CommonConstant'
import { useAppSelector } from '../../../store/hook'
import ModalComp from '../../../components/ModalComp'
import NewsItemDetail from '../../newsfeed/NewsItemDetail'
import { useNavigate } from 'react-router-dom'
import Story from '../../newsfeed/Story'
import NewPost from '../../newsfeed/comp/NewPost'

export default function Newsfeed() {

    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);
    const [list, setList] = useState<any>([]);

    const listInnerRef = useRef<any>();
    const isLoad = useRef(true);
    const offset = useRef(0);
    const keySearch = useRef("");

    useEffect(() => {
        getNewsFeed(0)
    }, [])

    const getNewsFeed = (type: number) => {
        isLoad.current = false;
        NewsService.getInstance().getNewsFeed({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: keySearch.current }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                if (resp.data.responseData.length == CommonConstant.LIMIT) {
                    isLoad.current = true;
                }
                if (type == 0) {
                    setList(resp.data.responseData)
                } else {
                    let array = resp.data.responseData;
                    let result = array.filter((e: any) => {
                        const index = list.findIndex((obj: any) => obj.id == e.id)
                        return index == -1;
                    })
                    setList([...list, ...result])
                }
            }
        }).catch((error: any) => {

        })
        offset.current++;
    }

    useEffect(() => {
        listInnerRef.current = document.getElementById('__newsfeed');
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [list])

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    getNewsFeed(1)
                }
            }
        }
    };

    const [showDetail, setShowNewsFeedDetail] = useState(false)
    useEffect(() => {
        if (showDetail) {
            $("#showNewsFeedDetailPopup").modal("show");
        }
    }, [showDetail])

    const selectItem = useRef<any>();
    const navigate = useNavigate()

    const [showNewPost, setShowNewPost] = useState(false)

    useEffect(() => {
        if (showNewPost) {
            $("#showNewGroupPopup").modal("show");
        }
    }, [showNewPost])

    const createPost = () => {
        setShowNewPost(true)
    }

    return (
        <>
            <div className={`${window.innerWidth >= 992 ? 'row' : ''} feed-body`} style={{ justifyContent: "center" }}>
                <div className='col-xl-8 col-xxl-9 col-lg-8' style={{ position: "fixed", zIndex: 9 }}>
                    <NewsHeader onSearch={(key: any) => {
                        offset.current = 0;
                        keySearch.current = key;
                        getNewsFeed(0);
                    }} textSearch={keySearch.current} />
                </div>
            </div>
            <div id='__newsfeed' className={`${window.innerWidth >= 992 ? 'row' : ''} feed-body`} style={{ height: "100vh", overflow: "auto", justifyContent: "center", paddingTop: "96px" }}>
                {
                    showDetail && (
                        <ModalComp id="showNewsFeedDetailPopup" onClose={() => {
                            $("#showNewsFeedDetailPopup").modal("hide");
                            selectItem.current = null;
                            setShowNewsFeedDetail(false)
                        }} classSize="modal-lg">
                            <NewsItemDetail item={selectItem.current} goToMyPage={(userUid: string) => {
                                $("#showNewsFeedDetailPopup").modal("hide");
                                selectItem.current = null;
                                setShowNewsFeedDetail(false)
                                navigate("/chat/profile?profileId=" + userUid)
                            }} />
                        </ModalComp>
                    )
                }
                {
                    showNewPost && (
                        <ModalComp id="showNewGroupPopup" onClose={() => {
                            $("#showNewGroupPopup").modal("hide");
                            setShowNewPost(false)
                        }} title="" classSize="modal-lg">
                            <NewPost callBack={(newsFeed: any) => {
                                $("#showNewGroupPopup").modal("hide");
                                setShowNewPost(false)
                                newsFeed['pudDate'] = new Date()
                                newsFeed['createdBy'] = {
                                    fullName: userInfo.fullName,
                                    imgPath: userInfo.imgPath,
                                    phone: userInfo.phone,
                                    userUid: userInfo.userUid
                                }
                                setList([newsFeed, ...list])
                            }} />
                        </ModalComp>
                    )
                }
                <div className='col-xl-8 col-xxl-9 col-lg-8'>
                    <div className="card-body p-0 mt-1 align-self-end" style={{ position: "fixed", right: "25px", bottom: "75px", zIndex: 10 }}>
                        <a onClick={createPost} className="font-xssss fw-600 text-grey-500 vts-curson card-body p-0 d-flex align-items-center">
                            <i className="feather-edit-3 btn-round-md bg-gold-gradiant" style={{ color: "#fff", fontSize: "20px" }}></i>
                        </a>
                    </div>
                    <Story />
                    {
                        list.length > 0 &&
                        list.map((p: any) => {
                            return <NewsItem key={p.id} item={p} selectItem={(item: any) => {
                                selectItem.current = item
                                setShowNewsFeedDetail(true);
                            }} tagClick={(tagNm: string) => {
                                $("#__newsfeed").animate({ scrollTop: 0 }, "slow");
                                offset.current = 0;
                                keySearch.current = tagNm;
                                getNewsFeed(0);
                            }} />
                        })
                    }
                </div>
            </div>
        </>
    )
}
