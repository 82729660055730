import React, { useState, useRef, useCallback, useEffect } from 'react'
import { MessService } from '../../../services/chat/mess/MessService';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { CommonConstant } from '../../../constants/CommonConstant';
import { AseUtil } from '../../../utils/AseUtil';
import { WebSocketService } from '../../../services/WebSocketService';
import { CommonUtil } from '../../../utils/CommonUtil';
import axios from 'axios';
import { HeadersUtil } from '../../../utils/headersUtil';
import { showAndHideSpinner } from '../../../reducers/spinnerSlice';
import { CommonService } from '../../../services/CommonService';
import { FileUtil } from '../../../utils/FileUtil';

export default function UserChatBottom() {

    const [text, setText] = useState("");
    const inputFileRef = useRef<any>()

    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const chat = useAppSelector(state => state.chat.selectedRoom)
    const dispatch = useAppDispatch()

    const handleChange = (event: any) => {
        setText(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleSend()
        }
    }

    const handleSend = () => {

        const temp = {
            createdDate: new Date(),
            fullName: userInfo.fullName,
            imgPath: userInfo.imgPath,
            id: new Date().getTime(),
            messType: CommonConstant.MESS_TYPE_TEXT,
            userUid: userInfo.userUid,
            mess: AseUtil.encryptedAES(userInfo.userUid, text),
            roomId: chat.roomId
        }

        // push to backend
        WebSocketService.getInstance().socketClient.send("/app/send-mess", {}, JSON.stringify(temp));

        // push to conversation
        MessService.getInstance().change(temp)

        setText("")
    }

    const onPasteMarkdown = (event: any) => {
        let file = event.clipboardData.files[0];
        if (file !== null && file !== undefined && CommonUtil.checkImageType(file.type)) {
            sendFile(file);
        }
    }

    const handleChangeFile = (e: any) => {
        const file = e.currentTarget.files[0];
        if (file !== null && file !== undefined) {
            if (CommonUtil.checkImageType(file.type)) {
                sendFile(file);
            } else {
                var fileKey = CommonUtil.generateUUID();
                var numChunk = 0;
                processFile(file, 0, fileKey, numChunk);
            }
        }
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    const send = (piece: any, start: any, end: any, fileKey: string) => {
        const data = new FormData();
        data.append('file', piece);
        data.append('start', start);
        data.append('end', end);
        axios.post(process.env.REACT_APP_API_URL + "/file/chunk/upload.exclude?fileKey=" + fileKey, data, {
            headers: HeadersUtil.getHeadersAuthFormData(),
            onUploadProgress: (progressEvent: any) => {

            },
        }).then(rep => {
            if (rep.data.status === CommonConstant.RESULT_OK) {

            }
        }).catch(err => {

        })
    }

    const processFile = async (file: any, start: any, fileKey: string, numChunk: number) => {
        var size = file.size;
        var sliceSize = (2097152 + (2 * 1024)); // 2MB + 2Kb
        var end = start + sliceSize;

        if (size - end < 0) {
            end = size;
        }

        var s = file.slice(start, end);

        send(s, start, end, fileKey + "_" + numChunk);

        if (end < size) {
            numChunk++;
            await sleep(50);
            processFile(file, end, fileKey, numChunk);
        } else {
            callJoinFiles(file, fileKey, numChunk);
        }
    }

    const callJoinFiles = (file: any, fileKey: string, numChunk: number) => {
        let fileName = file.name;
        let fileSize = file.size;
        let obj = {
            fleNm: fileName,
            fleSz: fileSize,
            fleKey: fileKey,
            fleChunk: numChunk
        }
        dispatch(showAndHideSpinner(1))
        axios.post(process.env.REACT_APP_API_URL + "/file/chunk/merge.exclude", obj, {
            headers: HeadersUtil.getHeadersAuth()
        }).then(rep => {
            if (rep.data.status === CommonConstant.RESULT_OK) {
                // let fleEx: string = rep.data.responseData.fleTp
                // tccoFile
                // rep.data.responseData
                handleSendMedia(rep.data.responseData.newFleNm, rep.data.responseData.fleNm, rep.data.responseData.fleSz)
            }
            dispatch(showAndHideSpinner(-1))
        }).catch(err => {
            dispatch(showAndHideSpinner(-1))
        })
    }

    const handleClickAttch = () => {
        inputFileRef.current.click()
    }

    const sendFile = (file: any) => {
        dispatch(showAndHideSpinner(1))
        const data = new FormData();
        data.append('file', file);
        axios.post(process.env.REACT_APP_API_URL + "/file/media/upload.exclude", data, {
            headers: HeadersUtil.getHeadersAuthFormData(),
            onUploadProgress: (progressEvent: any) => {
                // const percentCompleted = Math.round(
                //     (progressEvent.loaded * 100) / progressEvent.total
                // );
                // console.log(percentCompleted);
            },
        }).then(rep => {
            dispatch(showAndHideSpinner(-1))
            if (rep.data.status === CommonConstant.RESULT_OK) {
                handleSendMedia(rep.data.responseData.newFleNm, rep.data.responseData.fleNm, rep.data.responseData.fleSz)
            }
        }).catch(err => {
            dispatch(showAndHideSpinner(-1))
        })
    }

    const genarateFleType = (newFleNm: string) => {
        let extension = newFleNm.split(".")
        switch (extension[1].toLowerCase()) {
            case "jpg":
            case "png":
            case "gif":
            case "jpeg":
                return CommonConstant.MESS_TYPE_IMG;
            case "mp3":
            case "wma":
            case "wav":
                return CommonConstant.MESS_TYPE_AUDIO;
            case "mp4":
            case "avi":
            case "mov":
                return CommonConstant.MESS_TYPE_VIDEO;
            default:
                return CommonConstant.MESS_TYPE_FLE;
        }
    }

    const handleSendMedia = (newFleNm: string, fleNm: string, fleSz: number) => {

        const temp = {
            createdDate: new Date(),
            fullName: userInfo.fullName,
            imgPath: userInfo.imgPath,
            id: new Date().getTime(),
            messType: genarateFleType(newFleNm),
            userUid: userInfo.userUid,
            roomId: chat.roomId,
            messPath: newFleNm,
            mess: fleNm,
            messSize: fleSz
        }

        // push to backend
        WebSocketService.getInstance().socketClient.send("/app/send-mess", {}, JSON.stringify(temp));

        // push to conversation
        MessService.getInstance().change(temp)

    }

    const [focusInterac, setForcusInterac] = useState(0);

    const clickInterac = (type: number) => {
        setForcusInterac(focusInterac == type ? 0 : type)
    }

    const eventListener = useCallback((e: any) => {
        var container = $(".vts-interac2");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            setForcusInterac(0)
        }
    }, [])

    const [listIcons, setListIcons] = useState([])

    useEffect(() => {
        CommonService.getInstance().getTccoStdByUpCommCd({ upCommCd: "05" })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    setListIcons(resp.data.responseData);
                } else {
                    setListIcons([])
                }
            })
            .catch((err: any) => {

            })
        document.addEventListener('mouseup', eventListener);
        return () => {
            document.removeEventListener('mouseup', eventListener);
        }
    }, [])

    const sendIcons = (newFleNm: any) => {
        const temp = {
            createdDate: new Date(),
            fullName: userInfo.fullName,
            imgPath: userInfo.imgPath,
            id: new Date().getTime(),
            messType: CommonConstant.MESS_TYPE_ICON,
            userUid: userInfo.userUid,
            roomId: chat.roomId,
            messPath: newFleNm,
        }

        // push to backend
        WebSocketService.getInstance().socketClient.send("/app/send-mess", {}, JSON.stringify(temp));

        // push to conversation
        MessService.getInstance().change(temp)
    }

    const genarateItem = (listItems: any, indexKey: number) => {
        return <ul key={indexKey} className="emojis list-inline mb-0">
            {
                listItems.map((e: any) => {
                    return <li key={e.atchFleSeq} className="emoji list-inline-item">
                        <img className='vts-curson' onClick={() => sendIcons(e.imgPath)}
                            src={FileUtil.genarateUrlGetImage(e.imgPath, CommonConstant.FILE_THUMB)}
                            onError={(event: any) => {
                                event.target.src = require("../../../../assets/images/icons8-image-96.png")
                            }}
                            width={32} alt="image" />
                    </li>
                })
            }
        </ul>
    }

    const genarateIcons = () => {
        let checkPoint = Math.floor(listIcons.length / 6);
        let root = []
        for (let i = 0; i <= checkPoint; i++) {
            let items = listIcons.slice(i * 6, i * 6 + 6)
            root.push(genarateItem(items, i))
        }
        return root;
    }

    return (
        <div className="chat-input-section p-3 p-lg-4 border-top mb-0">

            <div className="row g-0">
                <div className="col">
                    <input type="text" value={text}
                        onPaste={(event: any) => onPasteMarkdown(event)}
                        onKeyUp={handleKeyPress}
                        onChange={handleChange}
                        className="form-control form-control-lg bg-light border-light"
                        placeholder="Enter Message..." />
                </div>
                <div className="col-auto">
                    <div className="chat-input-links ms-md-2 me-md-0">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Emoji">
                                <button type="button" onClick={() => clickInterac(1)} className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                    <i className="ri-emotion-happy-line"></i>
                                </button>
                            </li>
                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Attached File">
                                <button type="button" onClick={handleClickAttch} className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                    <i className="ri-attachment-line"></i>
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button type="submit" className="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light" onClick={handleSend}>
                                    <i className="ri-send-plane-line"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body d-flex p-0 vts-interac2">
                    <div className={`emoji-wrap ${focusInterac == 1 ? 'active' : ''}`} style={{ right: "1rem" }}>
                        {
                            genarateIcons()
                        }
                    </div>
                </div>
                <input type="file" ref={inputFileRef} onChange={handleChangeFile} hidden id="blob-dragged-file-input" />
            </div>
        </div>
    )
}
