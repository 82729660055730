import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { FileUtil } from '../../../utils/FileUtil'
import { CommonConstant } from '../../../constants/CommonConstant'
import { CommonUtil } from '../../../utils/CommonUtil'
import { setSelectedRoom } from '../../../reducers/chatSlice'
import Swal from 'sweetalert2'
import { RoomService } from '../../../services/chat/mess/RoomService'
import { toast } from 'react-toastify'

export default function UserChatTopbar() {

    const chat = useAppSelector(state => state.chat.selectedRoom)

    const dispatch = useAppDispatch()

    useEffect(() => {
        $("#user-profile-hide").on("click", function () {
            $(".user-profile-sidebar").hide()
        })
        $("#userProfileTopbarShow").on("click", function () {
            $(".user-profile-sidebar").show()
        })
        if (window.innerWidth >= 992) {
            $(".user-profile-sidebar").show()
        }
    }, [])

    const handleDeleteConversation = () => {
        Swal.fire({
            title: `Confirm`,
            text: `Do you want to delete this conversation ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                RoomService.getInstance().deleteConversation(chat.roomId).then((resp: any) => {
                    if (resp.data.status = CommonConstant.RESULT_OK) {
                        dispatch(setSelectedRoom({
                            roomId: 0,
                            roomType: "",
                            roomName: "",
                            roomImg: ""
                        }))
                    } else {
                        toast.error("Deleting the conversation failed")
                    }
                }).catch((err: any) => {
                    toast.error("System error, please come back later")
                })
            }
        })
    }

    const handleLeaveConversation = () => {
        Swal.fire({
            title: `Confirm`,
            text: `Do you want to leave this conversation ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                RoomService.getInstance().leaveGroup(chat.roomId).then((resp: any) => {
                    if (resp.data.status = CommonConstant.RESULT_OK) {
                        RoomService.getInstance().change({ leaveRoomId: chat.roomId })
                        dispatch(setSelectedRoom({
                            roomId: 0,
                            roomType: "",
                            roomName: "",
                            roomImg: ""
                        }))
                    } else {
                        toast.error("Leave the failed conversation")
                    }
                }).catch((err: any) => {
                    toast.error("System error, please come back later")
                })
            }
        })
    }

    return (
        <div className="p-3 p-lg-4 border-bottom user-chat-topbar">
            <div className="row align-items-center">
                <div className="col-sm-4 col-8">
                    <div className="d-flex align-items-center">
                        <div className="d-block d-lg-none me-2 ms-0">
                            <a className="user-chat-remove text-muted font-size-16 p-2" onClick={() => {
                                dispatch(setSelectedRoom({
                                    roomId: 0,
                                    roomType: '',
                                    roomName: '',
                                    roomImg: ''
                                }))
                            }}><i className="ri-arrow-left-s-line"></i></a>
                        </div>
                        <div className="me-3 ms-0">
                            {
                                (chat.roomImg && chat.roomImg != '') ? (
                                    <img src={FileUtil.genarateUrlGetImage(chat.roomImg, CommonConstant.FILE_THUMB)}
                                        onError={(event: any) => {
                                            event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                                        }}
                                        className="rounded-circle avatar-xs" alt="" />
                                ) : (
                                    <div className="avatar-xs">
                                        <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                            {
                                                chat.roomType == CommonConstant.ROOM_SINGLE ? CommonUtil.generateCharacterAvatar(chat.roomName) : <i className="ri-group-line"></i>
                                            }
                                        </span>
                                    </div>
                                )

                            }
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-16 mb-0 text-truncate"><a className="text-reset user-profile-show">{chat?.roomName}</a></h5>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8 col-4">
                    <ul className="list-inline user-chat-nav text-end mb-0">
                        <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                            <button type="button" className="btn nav-btn user-profile-show" id='userProfileTopbarShow'>
                                <i className="ri-contacts-line"></i>
                            </button>
                        </li>
                        <li className="list-inline-item">
                            <div className="dropdown">
                                <button className="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ri-more-fill"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item" onClick={handleDeleteConversation}>Delete conversation<i className="ri-delete-bin-line float-end text-muted"></i></a>
                                    {
                                        chat.roomType == CommonConstant.ROOM_GROUP && <a className="dropdown-item" onClick={handleLeaveConversation}>Leave <i className="ri-user-shared-line float-end text-muted"></i></a>
                                    }
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
