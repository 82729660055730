import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    selectedRoom: {
        roomId: 0,
        roomType: '',
        roomName: '',
        roomImg: ''
    }
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setSelectedRoom: (state, action) => {
            state.selectedRoom = action.payload
        },
    }
})

export default chatSlice.reducer
export const { setSelectedRoom } = chatSlice.actions