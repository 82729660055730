import React from 'react'

export default function ModalComp(props: any) {
    const { id, children, title, onClose, classSize } = props;
    return (
        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id={id} tabIndex={-1} role="dialog" aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${classSize}`}>
                <div className="modal-content vts-scroll">
                    <div className="modal-header">
                        <h5 className="modal-title font-size-16">{title}</h5>
                        <button type="button" className="btn-close" onClick={() => {
                            onClose();
                        }} aria-label="Close"></button>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}
