import React from 'react'

export default function ItemNews(props: any) {
    const { data } = props

    const formatDate = (value: Date) => {
        if (value == null || value == undefined) {
            return "";
        }
        // value.setHours(value.getHours());
        return value.getFullYear() + "-" + format("0" + (value.getMonth() + 1)) + "-" + format("0" + value.getDate()) + " " + format("0" + value.getHours()) + ":" + format("0" + value.getMinutes())
    }

    const format = (text: string) => {
        return text.substring(text.length - 2);
    }

    return (
        <li className="unread">
            <a title={`${data.title}`}>
                <div className="d-flex">
                    <div className="chat-user-img align-self-center me-3 ms-0">
                        <img src={require("../../../../../assets/images/icons8-news-64.png")}
                            className="rounded-circle avatar-xs" alt="" />
                        {/* <span className="user-status"></span> */}
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-15 mb-1">{data.title}</h5>
                        <p className="chat-user-message text-truncate mb-0"><i className="ri-article-line align-middle me-1 ms-0"></i>{formatDate(new Date(data.pudDate))}</p>
                    </div>
                    <div className="contacts-lst" onClick={() => {
                        window.open(data.link, '_blank');
                    }}>
                        <i className="ri-eye-line"></i>
                    </div>
                </div>
            </a>
        </li>
    )
}
