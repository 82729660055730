import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { UserInfoService } from '../../../../services/UserInfoService';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { updateUserInfo } from '../../../../reducers/userSlice';

export default function EditProfile(props: any) {

    const { onClose } = props;

    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const dispatch = useAppDispatch();

    const [model, setModel] = useState(
        {
            ...userInfo,
            dob: userInfo?.dob ? dayjs(userInfo.dob).format("YYYY-MM-DD") : dayjs(new Date()).format("YYYY-MM-DD")
        }
    );

    const handleChange = (event: any) => {
        setModel({
            ...model,
            [event.target.name]: event.target.value
        })
    }

    const handleOnChange = (event: any) => {
        setModel({
            ...model,
            [event.target.name]: event.target.checked
        })
    }

    const handleSave = () => {
        if (chk()) {
            UserInfoService.getInstance().updateInfo({ ...model })
                .then((resp: any) => {
                    if (resp.data.status == CommonConstant.RESULT_OK) {
                        dispatch(updateUserInfo({ ...model }))
                        onClose();
                    }
                })
                .catch((err: any) => {

                })
        }
    }

    const chk = () => {
        if (model.fullName == undefined || model.fullName == "") {
            toast.error("Full name cannot be blank")
            return false;
        }
        if (model.phone == undefined || model.phone == "") {
            toast.error("Phone number can not be left blank")
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="modal-body p-4">
                <form>
                    <div className="mb-3">
                        <label className="form-label">Full name <strong className='color-red'>*</strong></label>
                        <input type="text" onChange={handleChange} name='fullName' value={model.fullName} className="form-control" placeholder="Enter full name" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Gender</label>
                        <div className="form-check form-switch">
                            <label>Female</label>
                            <input onChange={handleOnChange} type="checkbox" name='gender' checked={model.gender} className="form-check-input" id="idGender" />
                            <label className="form-check-label" htmlFor="idGender"></label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Birthday <strong className='color-red'>*</strong></label>
                        <input type="date" value={model.dob} name='dob' onChange={handleChange} className="form-control" placeholder="Enter birthday" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Phone <strong className='color-red'>*</strong></label>
                        <input type="text" onChange={handleChange} name='phone' value={model.phone} className="form-control" placeholder="Enter phone" />
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
            </div>
        </>
    )
}
