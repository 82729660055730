import React, { useEffect, useRef, useState } from 'react'
import ItemContact from './comp/ItemContact'
import { ContactService } from '../../../services/chat/contacts/ContactService';
import { CommonConstant } from '../../../constants/CommonConstant';

export default function Contacts() {
    const listInnerRef = useRef<any>();
    const [contacts, setContacts] = useState<any>([])
    const [keySearch, setKeySearch] = useState("");
    const [tabContact, setTabContact] = useState(true)
    const isLoad = useRef(true);
    const offset = useRef(0);
    const searchType = useRef("NEWS_CONTACT")

    const loadMore = (type: number) => {
        isLoad.current = false;
        ContactService.getInstance().getListContacts({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: keySearch, searchType: searchType.current })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    if (resp.data.responseData.length == CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    if (type == 0) {
                        setContacts(resp.data.responseData)
                    } else {
                        setContacts((prev: any) => {
                            return [...prev, ...resp.data.responseData]
                        });
                    }
                } else {
                    // toast.error("Server error")
                }
            })
            .catch((err: any) => {
                // toast.error("Server error")
            })
        offset.current += CommonConstant.LIMIT;
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    useEffect(() => {
        if (!tabContact) {
            searchType.current = "FRIENDS"
        } else {
            searchType.current = "NEWS_CONTACT"
        }
        offset.current = 0
        loadMore(0);
    }, [tabContact])

    useEffect(() => {
        listInnerRef.current = document.getElementById('_contact-list')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [contacts])

    const handleChange = (event: any) => {
        setKeySearch(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            offset.current = 0;
            loadMore(0);
        }
    }

    const handleSearch = () => {
        offset.current = 0;
        loadMore(0);
    }

    return (
        <div>
            <div className="p-4">
                <div className="user-chat-nav float-end">
                    {/* <div title="Add Contact">
                        <span className="badge bg-info rounded-pill">
                            <i className="ri-user-add-line"></i>
                        </span>
                    </div> */}
                    <div className="form-check form-switch">
                        <label>Friends</label>
                        <input onChange={() => setTabContact(!tabContact)} type="checkbox" name='listFriend' checked={!tabContact} className="form-check-input" id="listFriend" />
                        <label className="form-check-label" htmlFor="listFriend"></label>
                    </div>
                </div>
                <h4 className="mb-4">Contacts</h4>

                <div className="search-box chat-search-box">
                    <div className="input-group bg-light  input-group-lg rounded-3">
                        <div className="input-group-prepend">
                            <button className="btn btn-link text-decoration-none text-muted pe-1 ps-3" type="button" onClick={handleSearch}>
                                <i className="ri-search-line search-icon font-size-18"></i>
                            </button>
                        </div>
                        <input type="text" value={keySearch} onKeyUp={handleKeyPress} onChange={handleChange} className="form-control bg-light" placeholder="Search users.." />
                    </div>
                </div>
            </div>
            <div id='_contact-list' className="px-2 chat-contact-list" data-simplebar>
                <div>
                    <ul className="list-unstyled chat-list contact-list-curson">
                        {
                            contacts.length > 0 &&
                            contacts.map((p: any) => {
                                return <ItemContact key={p.userUid} data={p} />
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
