import React from 'react'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { CommonUtil } from '../../../../utils/CommonUtil'
import axios from 'axios'
import { FileUtil } from '../../../../utils/FileUtil'
import { HeadersUtil } from '../../../../utils/headersUtil'

export default function ItemAttached(props: any) {

    const { item } = props

    const genarateAttachIcon = (messType: string) => {
        switch (messType) {
            case CommonConstant.MESS_TYPE_AUDIO: {
                return <i className="ri-folder-music-line"></i>
            }
            case CommonConstant.MESS_TYPE_VIDEO: {
                return <i className="ri-video-chat-line"></i>
            }
            case CommonConstant.MESS_TYPE_FLE: {
                return <i className="ri-file-text-fill"></i>
            }
            case CommonConstant.MESS_TYPE_IMG: {
                return <i className="ri-image-fill"></i>
            }
        }
    }

    const downloadFile = () => {
        axios.get(FileUtil.genarateUrlByteDownload(item.messPath), {
            responseType: "blob",
            headers: HeadersUtil.getHeadersAuth()
        }).then(rep => {
            if (rep.status == 200) {
                let url: any = window.URL || window.webkitURL;
                let anchor = document.createElement("a");
                anchor.href = url.createObjectURL(rep.data);
                anchor.download = item.mess;
                document.body.append(anchor);
                anchor.click();
                anchor.remove();
                window.URL.revokeObjectURL(url);
            }
        }).catch(err => {

        })
    }

    return (
        <div className="card p-2 border mb-2">
            <div className="d-flex align-items-center">
                <div className="avatar-sm me-3 ms-0">
                    <div className="avatar-title bg-primary-subtle text-primary rounded font-size-20 avatar-sm" style={{ width: "3rem" }}>
                        {
                            genarateAttachIcon(item.messType)
                        }
                    </div>
                </div>
                <div className="flex-grow-1 text-truncate">
                    <div className="text-start">
                        <h5 className="font-size-14 mb-1 text-truncate">{item.mess}</h5>
                        <p className="text-muted font-size-13 mb-0">{CommonUtil.convertByte(item.messSize)}</p>
                    </div>
                </div>

                <div className="ms-4 me-0">
                    <ul className="list-inline mb-0 font-size-18">
                        <li className="list-inline-item">
                            <a className="text-muted px-1 cuson-pointer" onClick={downloadFile}>
                                <i className="ri-download-2-line"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
