import React from 'react'
import { FileUtil } from '../../../../utils/FileUtil'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { CommonUtil } from '../../../../utils/CommonUtil'
import dayjs from 'dayjs'
import { useAppSelector } from '../../../../store/hook'
import { AseUtil } from '../../../../utils/AseUtil'

export default function ItemInbox(props: any) {
    const { item, deleteMess } = props
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const handleDelete = () => {
        deleteMess(item.id)
    }

    return (
        <>
            <li className={`${item.userUid === userInfo.userUid ? 'right' : ''}`}>
                <div className="conversation-list">
                    <div className="chat-avatar">
                        {
                            (item.imgPath && item.imgPath !== '') ? (
                                <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="rounded-circle avatar-xs" alt="" />
                            ) : (
                                <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {
                                            CommonUtil.generateCharacterAvatar(item.fullName)
                                        }
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    <div className="user-chat-content">
                        <div className="ctext-wrap">
                            <div className="ctext-wrap-content">
                                <p className="mb-0">
                                    {AseUtil.decryptedAES(item.userUid, item.mess || "")}
                                </p>
                                <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{(item.createdDate ? dayjs(item.createdDate).format('YYYY-MM-DD HH:mm') : 'underfined')}</span></p>
                            </div>
                            {
                                (item.userUid === userInfo.userUid && item.messType !== CommonConstant.MESS_TYPE_RECALL) && (
                                    <div className="dropdown align-self-start">
                                        <a className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="ri-more-2-fill"></i>
                                        </a>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" onClick={handleDelete}>Remove <i className="ri-delete-bin-line float-end text-muted"></i></a>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="conversation-name">{item.userUid !== userInfo.userUid ? item?.fullName : <>&nbsp;</>}</div>
                    </div>
                </div>
            </li>
        </>
    )
}
