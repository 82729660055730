import React, { useEffect, useRef, useState } from 'react'
import { showAndHideSpinner } from '../../../reducers/spinnerSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import axios from 'axios'
import { HeadersUtil } from '../../../utils/headersUtil'
import { CommonConstant } from '../../../constants/CommonConstant'
import { CommonUtil } from '../../../utils/CommonUtil'
import { FileUtil } from '../../../utils/FileUtil'
import Plyr from 'plyr-react'
import { toast } from 'react-toastify'
import { NewsService } from '../../../services/NewsService'

export default function NewPost(props: any) {

    const { callBack } = props

    const dispatch = useAppDispatch()
    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);

    const [listPhoto, setListPhoto] = useState<any>([])
    const [listVideo, setListVideo] = useState<any>([])
    const [hashTags, setHashTags] = useState<any>([])

    const inputPhotoRef = useRef<any>()
    const inputVideoRef = useRef<any>()
    const handleClickAttch = (type: string) => {
        if ("VIDEO" == type) {
            inputVideoRef.current.click()
        } else {
            inputPhotoRef.current.click()
        }
    }
    const handleChangeFile = (e: any) => {
        const file = e.currentTarget.files[0];
        if (file !== null && file !== undefined) {
            var fileKey = CommonUtil.generateUUID();
            var numChunk = 0;
            processFile(file, 0, fileKey, numChunk);
        }
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    const processFile = async (file: any, start: any, fileKey: string, numChunk: number) => {
        var size = file.size;
        var sliceSize = (2097152 + (2 * 1024)); // 2MB + 2Kb
        var end = start + sliceSize;

        if (size - end < 0) {
            end = size;
        }

        // var s = slice(file, start, end);
        var s = file.slice(start, end);

        send(s, start, end, fileKey + "_" + numChunk);

        if (end < size) {
            numChunk++;
            // start += sliceSize;
            await sleep(50);
            processFile(file, end, fileKey, numChunk);
        } else {
            callJoinFiles(file, fileKey, numChunk);
        }
    }

    const callJoinFiles = (file: any, fileKey: string, numChunk: number) => {
        let fileName = file.name;
        let fileSize = file.size;

        let obj = {
            fleNm: fileName,
            fleSz: fileSize,
            fleKey: fileKey,
            fleChunk: numChunk
        }

        dispatch(showAndHideSpinner(1))

        axios.post(process.env.REACT_APP_API_URL + "/file/chunk/merge.exclude", obj, {
            headers: HeadersUtil.getHeadersAuth()
        }).then(rep => {
            if (rep.data.status === CommonConstant.RESULT_OK) {
                let fleEx: string = rep.data.responseData.fleTp
                switch (fleEx.toLocaleLowerCase()) {
                    case "jpg":
                    case "png":
                    case "gif":
                    case "jpeg": {
                        setListPhoto([...listPhoto, rep.data.responseData])
                        break;
                    }
                    case "mp4":
                    case "avi":
                    case "mov": {
                        setListVideo([...listVideo, rep.data.responseData])
                        break;
                    }
                    default: {

                    }
                }

            }
            dispatch(showAndHideSpinner(-1))
        }).catch(err => {
            dispatch(showAndHideSpinner(-1))
        })

    }

    const send = (piece: any, start: any, end: any, fileKey: string) => {
        const data = new FormData();
        data.append('file', piece);
        data.append('start', start);
        data.append('end', end);
        axios.post(process.env.REACT_APP_API_URL + "/file/chunk/upload.exclude?fileKey=" + fileKey, data, {
            headers: HeadersUtil.getHeadersAuthFormData(),
            onUploadProgress: (progressEvent: any) => {

            },
        }).then(rep => {
            if (rep.data.status === CommonConstant.RESULT_OK) {

            }
        }).catch(err => {

        })
    }

    const slice = (file: any, start: any, end: any) => {
        var slice = file.mozSlice ? file.mozSlice :
            file.webkitSlice ? file.webkitSlice :
                file.slice ? file.slice : noop;

        return slice.bind(file)(start, end);
    }

    const noop = () => {

    }

    const [tags, setTags] = useState("");
    const [content, setContent] = useState("");

    const handleChange = (event: any) => {
        setTags(event.target.value)
    }

    const handleChangeText = (event: any) => {
        setContent(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            if (tags != "" && tags.trim() != "") {
                const found = hashTags.findIndex((element: any) => element == tags.trim());
                if (found == -1) {
                    setHashTags([...hashTags, tags.trim()])
                }
            }
            setTags("")
        }
    }

    const removeTags = (tag: string) => {
        var array: any = [...hashTags]
        const index = array.findIndex((element: any) => element == tag);
        if (index != -1) {
            array.splice(index, 1)
            setHashTags([...array])
        }
    }

    const removeMedia = (atchFleSeq: string, type: string) => {
        if ("PHOTO" == type) {
            var array: any = [...listPhoto]
            const index = array.findIndex((element: any) => element.atchFleSeq == atchFleSeq);
            if (index != -1) {
                array.splice(index, 1)
                setListPhoto([...array])
            }
        } else {
            var array: any = [...listVideo]
            const index = array.findIndex((element: any) => element.atchFleSeq == atchFleSeq);
            if (index != -1) {
                array.splice(index, 1)
                setListVideo([...array])
            }
        }
    }

    const handleSave = () => {
        if (content.trim() != "") {
            let obj = {
                content: content,
                tags: hashTags,
                photos: listPhoto,
                videos: listVideo
            }
            dispatch(showAndHideSpinner(0))
            NewsService.getInstance().createNewsFeed(obj).then((resp: any) => {
                if (resp.data.status === CommonConstant.RESULT_OK) {
                    callBack({ ...obj, id: resp.data.responseData.id, totalInteract: 0 })
                }
                dispatch(showAndHideSpinner(-1))
            }).catch((error: any) => {
                dispatch(showAndHideSpinner(-1))
            })

        } else {
            toast.error("What's on your mind ?")
        }
    }

    const [plyrs, setPlyrs] = useState<any>([])

    useEffect(() => {
        const newPlyrs = listVideo.map((p: any, index: number) => {
            return (
                {
                    atchFleSeq: p.atchFleSeq,
                    jsxEle: <Plyr
                        key={p.atchFleSeq}
                        source={{
                            type: 'video',
                            sources: [{
                                src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm
                            }]
                        }}
                    />
                }
            );
        });
        setPlyrs(newPlyrs);
        return () => {
            // You may need to implement logic here to destroy Plyr instances if necessary
        };
    }, [listVideo]);

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3" style={{ overflow: "auto" }}>
            <div className="form-group mb-0 icon-input mt-2">
                <i className="feather-edit-3 font-sm text-grey-400"></i>
                <input type="text" value={tags} onKeyUp={handleKeyPress} onChange={handleChange} placeholder="Press enter to add hash tags" style={{ width: "100%", outline: "none" }} className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl theme-dark-bg" />
            </div>

            <div className="card-body d-block p-0">
                <div className="row ps-2 pe-2">
                    {
                        hashTags.length > 0 &&
                        hashTags.map((p: any) => {
                            return <a key={p} className="p-2 pe-3 lh-20 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl me-1 mt-1 ms-1"
                                style={{ width: "auto", position: "relative", color: "#7269ef" }}>{p}
                                <i onClick={() => removeTags(p)} className="ri-close-circle-line vts-curson" style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "18px" }}></i>
                            </a>
                        })
                    }
                </div>
            </div>

            <div className="card-body p-0 mt-3 position-relative">
                <figure className="avatar position-absolute ms-2 mt-1 top-5"><img
                    src={userInfo?.imgPath ? FileUtil.genarateUrlGetImage(userInfo.imgPath, CommonConstant.FILE_THUMB)
                        : require("../../../../assets/images/users/user-dummy-img.jpg")}
                    alt="image" className="shadow-sm rounded-circle w30" onError={(event: any) => {
                        event.target.src = require("../../../../assets/images/users/user-dummy-img.jpg")
                    }} /></figure>
                <textarea name="message" style={{ outline: "none" }} value={content} onChange={handleChangeText}
                    className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 text-grey-500 fw-500 border-light-md theme-dark-bg" cols={30} rows={10} placeholder="What's on your mind?"></textarea>
            </div>

            <div className='d-flex'>
                <div className="card-body d-flex p-0 mt-0">
                    {
                        listVideo.length == 0 && <a onClick={() => handleClickAttch("PHOTO")} className="d-flex align-items-center fw-600 ls-1 text-grey-700 text-dark pe-4 vts-curson">
                            <i className="font-md text-success feather-image me-2"></i><span className="d-none-xs">Photo</span>
                        </a>
                    }
                    {
                        listPhoto.length == 0 && <a onClick={() => handleClickAttch("VIDEO")} className="d-flex align-items-center fw-600 ls-1 text-grey-700 text-dark pe-4 vts-curson">
                            <i className="font-md text-warning feather-camera me-2"></i><span className="d-none-xs">Video</span>
                        </a>
                    }
                </div>
                <div style={{ textAlign: "end" }}>
                    <a type="button" className="p-2 lh-20 w100 bg-gold-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={handleSave}>
                        <i className="feather-save"></i> Save</a>
                </div>
            </div>
            <input type="file" ref={inputPhotoRef} onChange={handleChangeFile} accept='.jpg,.png,.gif,.jpeg' hidden id="__photo" />
            <input type="file" ref={inputVideoRef} onChange={handleChangeFile} accept='.mp4,.avi,.mov' hidden id="__video" />

            <div className="card-body d-block p-0">
                <div className="row ps-2 pe-2">
                    {
                        listPhoto.length > 0 &&
                        listPhoto.map((p: any) => {
                            return <div key={p.atchFleSeq} className="col-xs-4 col-sm-4 p-1" style={{ position: "relative" }}>
                                <a data-lightbox="roadtrip">
                                    <img src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                        onError={(event: any) => {
                                            event.target.src = require("../../../../assets/images/not_image.png")
                                        }}
                                        className="rounded-3 w-100" alt="image" />
                                </a>
                                <i onClick={() => removeMedia(p.atchFleSeq, "PHOTO")} className="ri-close-circle-line vts-curson" style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "18px", color: "orangered" }}></i>
                            </div>
                        })
                    }

                    {
                        listVideo.length > 0 &&
                        // listVideo.map((p: any) => {
                        //     return <div key={p.atchFleSeq} className="col-xs-12 col-sm-12 p-1" style={{ position: "relative" }}>
                        //         <Plyr
                        //             source={{
                        //                 type: 'video',
                        //                 sources: [{
                        //                     src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm
                        //                 }]
                        //             }} />
                        //         <i onClick={() => removeMedia(p.atchFleSeq, "VIDEO")} className="ri-close-circle-line vts-curson" style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "18px", color: "orangered" }}></i>
                        //     </div>
                        // })
                        plyrs.map((plyr: any, index: number) => (
                            <div key={plyr.atchFleSeq} className="col-xs-12 col-sm-12 p-1" style={{ position: "relative" }}>
                                {plyr.jsxEle}
                                <i onClick={() => removeMedia(plyr.atchFleSeq, "VIDEO")} className="ri-close-circle-line vts-curson" style={{ position: "absolute", top: "-5px", right: "-5px", fontSize: "18px", color: "orangered" }}></i>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
