import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    repplyCommentId: 0
}

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        setRepplyCommentId: (state, action) => {
            state.repplyCommentId = action.payload
        },
    }
})

export default commentSlice.reducer
export const { setRepplyCommentId } = commentSlice.actions