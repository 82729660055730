import axios from "axios";
import { ApiUrlUtil } from "../../../utils/apiUrlUtil";
import { HeadersUtil } from "../../../utils/headersUtil";
import { SettingDto } from "../../../model/SettingDto";

export class SettingService {

    private static _setting: SettingService;

    public static getInstance(): SettingService {
        if (!SettingService._setting) {
            SettingService._setting = new SettingService();
        }
        return SettingService._setting;
    }

    public updateSetting(setting: SettingDto) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/setting/update");
        return axios.post(url, setting, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}