import CryptoJS from 'crypto-js';
import { CommonConstant } from '../constants/CommonConstant';
import { SecurityConstant } from '../constants/SecurityConstant';

const encryptedAES = (passPhrase: string, plainText: string) => {
    var ciphertext = CryptoJS.AES.encrypt(plainText, SecurityConstant.SECRET_KEY + "_" + passPhrase).toString();
    return ciphertext;
}

const decryptedAES = (passPhrase: string, cipherText: any) => {
    var bytes  = CryptoJS.AES.decrypt(cipherText, SecurityConstant.SECRET_KEY + "_" + passPhrase);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

export const AseUtil = {
    encryptedAES: encryptedAES,
    decryptedAES: decryptedAES
}