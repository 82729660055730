export class CommonConstant {

    public static readonly LIMIT = 15;

    public static readonly YES = 'Y';
    public static readonly NO = 'N';
    public static readonly LANG_KOREA = 'kr';
    public static readonly LANG_VIET = 'vi';
    public static readonly LANG_ENGLISH = 'en';

    public static readonly RESULT_OK = 'OK';
    public static readonly RESULT_WN = 'WN';
    public static readonly RESULT_NG = 'NG';

    public static readonly REQ_FRIEND = '00-04';
    public static readonly UN_REQ_FRIEND = '00-05';

    public static readonly NOTICE_REQ_FREIND = '01-01';
    public static readonly NOTICE_BIRTHDAY = '01-02';

    public static readonly FILE_ORIGIN = 'ORIGIN';
    public static readonly FILE_CROP = 'CROP';
    public static readonly FILE_THUMB = 'THUMB';

    public static readonly APPROVED_FRI_STA = '00-02';
    public static readonly REJECTED_FRI_STA = '00-03';

    public static readonly ROOM_SINGLE = '02-01';
    public static readonly ROOM_GROUP = '02-02';

    public static readonly MESS_TYPE_TEXT = '03-01';
    public static readonly MESS_TYPE_NOTICE = '03-03';
    public static readonly MESS_TYPE_RECALL = '03-04';
    public static readonly MESS_TYPE_IMG = '03-02-01';
    public static readonly MESS_TYPE_AUDIO = '03-02-02';
    public static readonly MESS_TYPE_VIDEO = '03-02-03';
    public static readonly MESS_TYPE_FLE = '03-02-04';
    public static readonly MESS_TYPE_ICON = '03-02-05';

    public static readonly INTERACT_LIKE = '04-01';
    public static readonly INTERACT_FAVARITE = '04-02';
    public static readonly INTERACT_03 = '04-03';
    public static readonly INTERACT_04 = '04-04';

    public static readonly UNICODE = 'QWERTYUIOPLKJHGFDSAZXCVBNMqwertyuioplkjhgfdsazxcvbnm0987654321';
}