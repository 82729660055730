import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthConstant } from '../constants/AuthConstant'
import Cookies from 'universal-cookie';

export default function AuthGuard(props: any) {

    const cookie = new Cookies();
    const navigate = useNavigate()

    const [auth, setAuth] = useState(false)

    // const [accessToken, setAccessToken] = useSearchParams()
    // const _token = accessToken.get(AuthConstant.ACCESS_TOKEN)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _token = params.get('access_token');

    if (_token) {
        const expires = new Date();
        expires.setDate(expires.getDate() + AuthConstant.EXPIRES_TOKEN)
        cookie.remove(AuthConstant._TOKEN)
        cookie.remove(AuthConstant.PUBLIC_KEY)
        cookie.set(AuthConstant._TOKEN, _token, { path: '/', expires: expires })
        window.location.href = "/"
    }

    useEffect(() => {
        if (cookie.get(AuthConstant._TOKEN) !== undefined && cookie.get(AuthConstant._TOKEN) !== '') {
            setAuth(true)
        } else {
            let domain = window.location.origin;
            window.location.href = process.env.REACT_APP_AUTH_URL + "/auth/login?redirectUri=" + domain || ""
        }
    }, [navigate])

    return (
        auth && props.children
    )
}
