
import Stomp from "stompjs";
import SockJS from "sockjs-client";
export class WebSocketService {

    private static _webSocketService: WebSocketService;

    public socketClient: any
    public ws: any;

    public static getInstance(): WebSocketService {
        if (!WebSocketService._webSocketService) {
            WebSocketService._webSocketService = new WebSocketService();
        }
        return WebSocketService._webSocketService;
    }

    public initSocket() {
        // if (this.chkConnection()) {
        //     return;
        // }
        this.close();
        this.ws = new SockJS(process.env.REACT_APP_API_WS_URL + "");
        this.socketClient = Stomp.over(this.ws);
        this.socketClient.debug = null;
    }

    public chkConnection() {
        if (this.socketClient !== null && this.socketClient != undefined) {
            // console.log("Connected");
            return true;
        }
        return false;
    }

    public close() {
        if (this.socketClient !== null && this.socketClient != undefined) {
            this.socketClient.disconnect();
            this.socketClient = null;
        }
        // console.log("Disconnected");
    }
}
