import React from 'react'
import { AseUtil } from '../../../../utils/AseUtil'

export default function ItemInboxNotice(props: any) {
    const { item } = props
    return (
        <li>
            <div className="chat-day-title">
                <span className="title">{AseUtil.decryptedAES(item.userUid, item.mess || "")}</span>
            </div>
        </li>
    )
}
