import React, { useEffect } from 'react'
import SideBarLeft from './SideBarLeft'
import ChatContent from './ChatContent'
import { Outlet, useLocation } from 'react-router-dom'

// import $ from 'jquery';
import { useAppSelector } from '../../../store/hook';
import Newsfeed from './Newsfeed';
import ProfilePage from '../profile/ProfilePage';

export default function LayoutChat() {

    const chat = useAppSelector(state => state.chat.selectedRoom)
    const spinner = useAppSelector(state => state.spinner.loading)

    const location = useLocation()
    const url = location.pathname

    useEffect(() => {
        if (spinner == 1) {
            const $this = $("#upload-percet")
            $({
                countNum: $this.text()
            }).delay(0).animate({
                countNum: 96
            }, {
                duration: 8000,
                easing: "linear",
                step: function (this: any) {
                    $this.text(Math.floor(this.countNum) + " %");
                },
                complete: function () {
                    $this.text(this.countNum + " %");
                }
            });
        }
    }, [spinner])

    return (

        <>
            {
                spinner != -1 && (
                    <div className="progress-spinner text-center">
                        {/* <div className="loader-inner">
                            <div className="lds-roller mb-3">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <h6 style={{ color: "#fff" }}>Please wait</h6>
                        </div> */}
                        <div className="story-loader">
                            <h6 style={{ color: "#fff", position: "absolute" }} id='upload-percet'></h6>
                        </div>
                    </div>
                )
            }
            <div className="layout-wrapper d-lg-flex">
                <SideBarLeft />
                <div className="chat-leftsidebar me-lg-1 ms-lg-0">
                    <div className="tab-content">
                        <Outlet />
                    </div>
                </div>
                <div className={`user-chat w-100 overflow-hidden ${chat.roomId != 0 ? 'user-chat-show' : ''}`}>
                    {
                        chat.roomId != 0 ? <div className='d-lg-flex'><ChatContent /></div> : url.startsWith("/chat/profile") ? <ProfilePage /> : <Newsfeed />
                    }
                </div>
            </div>
        </>
    )
}
