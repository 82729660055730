import React, { useRef, useState, useEffect } from 'react'
import { CommonUtil } from '../../utils/CommonUtil';
import { showAndHideSpinner } from '../../reducers/spinnerSlice';
import axios from 'axios';
import { HeadersUtil } from '../../utils/headersUtil';
import { CommonConstant } from '../../constants/CommonConstant';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import ModalComp from '../../components/ModalComp';
import Plyr from 'plyr-react';
import StoryItem from './comp/StoryItem';
import { NewsService } from '../../services/NewsService';
import { FileUtil } from '../../utils/FileUtil';

export default function Story() {

    const inputVideoRef = useRef<any>()
    const tccoFile = useRef<any>()
    const dispatch = useAppDispatch()
    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);
    const [stories, setStory] = useState<any>([])
    const offset = useRef(0);
    const limit = useRef(3);
    const isFirstLoad = useRef(true)
    const isLoadMore = useRef(true)


    useEffect(() => {
        getListStory();
    }, [])

    const getListStory = () => {
        NewsService.getInstance().getListStory({ limit: limit.current, offset: offset.current, keySearch: "" }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                if (isFirstLoad.current) {
                    isFirstLoad.current = false;
                    if (resp.data.responseData.length < limit.current) {
                        isLoadMore.current = false;
                    }
                    limit.current = 4;
                    offset.current--;
                    setStory(resp.data.responseData)
                } else {
                    if (resp.data.responseData.length < limit.current) {
                        isLoadMore.current = false;
                    }
                    setStory([...stories, ...resp.data.responseData])
                }
                offset.current += 4;
            }
        }).catch((error: any) => {

        })
    }

    const loadmore = () => {
        if (isLoadMore.current) {
            getListStory();
        }
    }

    const addStory = () => {
        inputVideoRef.current.click()
    }

    const handleChangeFile = (e: any) => {
        const file = e.currentTarget.files[0];
        if (file !== null && file !== undefined) {
            var fileKey = CommonUtil.generateUUID();
            var numChunk = 0;
            processFile(file, 0, fileKey, numChunk);
        }
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    const processFile = async (file: any, start: any, fileKey: string, numChunk: number) => {
        var size = file.size;
        var sliceSize = (2097152 + (2 * 1024)); // 2MB + 2Kb
        var end = start + sliceSize;

        if (size - end < 0) {
            end = size;
        }

        // var s = slice(file, start, end);
        var s = file.slice(start, end);

        send(s, start, end, fileKey + "_" + numChunk);

        if (end < size) {
            numChunk++;
            // start += sliceSize;
            await sleep(50);
            processFile(file, end, fileKey, numChunk);
        } else {
            callJoinFiles(file, fileKey, numChunk);
        }
    }

    const callJoinFiles = (file: any, fileKey: string, numChunk: number) => {
        let fileName = file.name;
        let fileSize = file.size;

        let obj = {
            fleNm: fileName,
            fleSz: fileSize,
            fleKey: fileKey,
            fleChunk: numChunk
        }

        dispatch(showAndHideSpinner(1))

        axios.post(process.env.REACT_APP_API_URL + "/file/chunk/merge.exclude", obj, {
            headers: HeadersUtil.getHeadersAuth()
        }).then(rep => {
            if (rep.data.status === CommonConstant.RESULT_OK) {
                tccoFile.current = rep.data.responseData
                setPreviewStory(true);
            }
            dispatch(showAndHideSpinner(-1))
        }).catch(err => {
            dispatch(showAndHideSpinner(-1))
        })

    }

    const [showPreviewStory, setPreviewStory] = useState(false)
    useEffect(() => {
        if (showPreviewStory) {
            $("#previewStory").modal("show");
        }
    }, [showPreviewStory])

    const send = (piece: any, start: any, end: any, fileKey: string) => {
        const data = new FormData();
        data.append('file', piece);
        data.append('start', start);
        data.append('end', end);
        axios.post(process.env.REACT_APP_API_URL + "/file/chunk/upload.exclude?fileKey=" + fileKey, data, {
            headers: HeadersUtil.getHeadersAuthFormData(),
            onUploadProgress: (progressEvent: any) => {

            },
        }).then(rep => {
            if (rep.data.status === CommonConstant.RESULT_OK) {

            }
        }).catch(err => {

        })
    }

    const pudStory = () => {
        NewsService.getInstance().saveStory({ ...tccoFile.current }).then((resp: any) => {

        }).catch((error: any) => {

        })
        setStory([{
            id: new Date().getTime(),
            fullName: userInfo.fullName,
            imgPath: userInfo.imgPath,
            createdDate: new Date(),
            newFleNm: tccoFile.current.newFleNm,
            isWatched: "Y",
            userUid: userInfo.userUid
        }, ...stories])
        $("#previewStory").modal("hide");
        setPreviewStory(false)
    }

    return (
        <>
            {
                showPreviewStory && (
                    <ModalComp id="previewStory" onClose={() => {
                        $("#previewStory").modal("hide");
                        setPreviewStory(false)
                    }} classSize="modal-lg" title="Add story">
                        <div className='p-2' >
                            <Plyr
                                source={{
                                    type: 'video',
                                    sources: [{
                                        src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + tccoFile.current.newFleNm
                                    }]
                                }} />
                            <div style={{ textAlign: "right" }}>
                                <button type="button" className="btn btn-primary mt-2" onClick={pudStory}>Save</button>
                            </div>
                        </div>
                    </ModalComp>
                )
            }
            <input type="file" ref={inputVideoRef} onChange={handleChangeFile} accept='.mp4,.avi,.mov' hidden id="___video_story" />
            <div className="row ps-2 pe-2">
                <div className="col-md-3 col-xss-6 pe-2 ps-2 col-6">
                    <div className="card h300 d-block border-0 shadow-xss rounded-3 bg-gradiant-bottom overflow-hidden mb-3">
                        <img className="rounded-3 w-100" alt="image"
                            src={FileUtil.genarateUrlGetImage(userInfo.imgPath, CommonConstant.FILE_CROP)}
                            onError={(event: any) => {
                                event.target.src = require("../../../assets/images/users/user-dummy-img.jpg")
                            }} />
                        <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                            <span className="btn-round-lg bg-white">
                                {/* <i className="feather-plus font-lg"></i> */}
                            </span>
                            <div className="clearfix"></div>
                            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1 cuson-pointer" onClick={addStory}>Add story</h4>
                        </div>
                    </div>
                </div>
                {
                    stories.length > 0 &&
                    stories.map((e: any) => {
                        return <StoryItem key={e.id} e={e} />
                    })
                }
            </div>
            {
                isLoadMore.current && (
                    <div className='p-2' style={{ textAlign: "right", marginTop: "-1rem" }}>
                        <a className="fw-600 text-grey-500 ms-2 vts-curson" onClick={loadmore}><i className='feather-chevrons-down'></i></a>
                    </div>
                )
            }
        </>
    )
}
