import axios from "axios";
import { ApiUrlUtil } from "../utils/apiUrlUtil";
import { ParamUtil, RequestParam } from "../utils/paramUtil";
import { HeadersUtil } from "../utils/headersUtil";

export class CommonService {

    private static _commonService: CommonService;

    public static getInstance(): CommonService {
        if (!CommonService._commonService) {
            CommonService._commonService = new CommonService();
        }
        return CommonService._commonService;
    }

    public getTccoStdByUpCommCd(model: any) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/common/getTccoStdByUpCommCd", params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}