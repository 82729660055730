import { CommonConstant } from "../constants/CommonConstant";

export class CommonUtil {
    public static generateCharacterAvatar(fullName: string) {
        if (!fullName) {
            return "";
        }
        let arrs: string[] = fullName.split(" ");
        return arrs[arrs.length - 1][0].toUpperCase();
    }

    public static getFirstName(fullName: string) {
        if (!fullName) {
            return "";
        }
        let arrs: string[] = fullName.split(" ");
        return arrs[arrs.length - 1];
    }

    public static dateComparation(date: Date) {
        if (!date) return "";
        var now = new Date();
        const duration = now.getTime() - date.getTime();
        const second = Math.floor(duration / 1000);
        const minute = Math.floor(second / 60);
        const hour = Math.floor(minute / 60);
        const day = Math.floor(hour / 24);
        const week = Math.floor(day / 7);
        const year = Math.floor(day / 365);
        if (year > 0) return year + (year > 1 ? " years ago" : " year ago");
        else if (week > 0) return week + (week > 1 ? " weeks ago" : " week ago");
        else if (day > 0) return day + (day > 1 ? " days ago" : " day ago");
        else if (hour > 0) return hour + (hour > 1 ? " hours ago" : " hour ago");
        else if (minute > 0) return minute + (minute > 1 ? " minutes ago" : " minute ago");
        else if (second > 30) return second + " seconds ago";
        else return "just now";
    }

    public static convertGender(gender: boolean) {
        return gender == true ? "Famale" : "Male"
    }

    public static convertMessTypeMedia(type: string) {
        switch (type) {
            case CommonConstant.MESS_TYPE_IMG: {
                return { icon: 'ri-image-fill', text: 'Images' }
            }
            case CommonConstant.MESS_TYPE_AUDIO: {
                return { icon: 'ri-folder-music-line', text: 'Audio' }
            }
            case CommonConstant.MESS_TYPE_VIDEO: {
                return { icon: 'ri-video-chat-line', text: 'Video' }
            }
            case CommonConstant.MESS_TYPE_FLE: {
                return { icon: 'ri-attachment-line', text: 'File' }
            }
            case CommonConstant.MESS_TYPE_ICON: {
                return { icon: 'ri-remixicon-line', text: 'Icons' }
            }
            default: {
                return {}
            }
        }
    }

    public static checkImageType(type: string) {
        switch (type) {
            case "image/jpeg":
            case "image/png":
            case "image/gif":
            case "image/svg+xml":
                return true;
            default:
                return false;
        }
    }

    public static convertByte(size: number) {
        if (!size) {
            return "0B";
        }
        const byte = size;
        const kb = Math.floor(byte / 1024);
        const mb = Math.floor(kb / 1024);
        const gb = Math.floor(mb / 1024);

        if (gb > 0) return gb + " GB"
        else if (mb > 0) return mb + " MB"
        else if (kb > 0) return kb + " KB"
        else if (byte > 0) return byte + " B"
    }

    public static checkOnline(arr: any, listUsers: any) {
        let index = -1;
        if (arr == undefined || listUsers == undefined) return "away"
        for (let i = 0; i < listUsers.length; i++) {
            index = arr.findIndex((e: any) => e == listUsers[i]['userUid'])
            if (index != -1) {
                return "online"
            }
        }
        return "away"
    }

    public static generateUUID() {
        let array = []
        for (let i = 0; i < 6; i++) {
            let uuid = "";
            for (let j = 0; j < 5; j++) {
                uuid += CommonConstant.UNICODE.charAt(this.getRandomInt(CommonConstant.UNICODE.length))
            }
            array.push(uuid);
        }
        return array.join("-");
    }

    public static getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }
}