import { FileUtil } from '../../../../utils/FileUtil'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { useAppSelector } from '../../../../store/hook'
import dayjs from 'dayjs'
import axios from 'axios'
import { HeadersUtil } from '../../../../utils/headersUtil'

export default function ItemInboxTypeImg(props: any) {
    const { item, onSelectMess, deleteMess } = props
    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const viewImg = () => {
        onSelectMess(item)
    }

    const downloadFile = () => {
        axios.get(FileUtil.genarateUrlByteDownload(item.messPath), {
            responseType: "blob",
            headers: HeadersUtil.getHeadersAuth()
        }).then(rep => {
            if (rep.status === 200) {
                let url: any = window.URL || window.webkitURL;
                let anchor = document.createElement("a");
                anchor.href = url.createObjectURL(rep.data);
                anchor.download = item.mess;
                document.body.append(anchor);
                anchor.click();
                anchor.remove();
                window.URL.revokeObjectURL(url);
            }
        }).catch(err => {

        })
    }

    const handleDelete = () => {
        deleteMess(item.id)
    }

    return (
        <li className={`${item.userUid === userInfo.userUid ? 'right' : ''}`}>
            <div className="conversation-list">
                <div className="chat-avatar">
                    {
                        (item.imgPath && item.imgPath !== '') ? (
                            <img src={FileUtil.genarateUrlGetImage(item.imgPath, CommonConstant.FILE_THUMB)}
                                onError={(event: any) => {
                                    event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                }}
                                className="rounded-circle avatar-xs" alt="" />
                        ) : (
                            <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                    {
                                        CommonUtil.generateCharacterAvatar(item.fullName)
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>

                <div className="user-chat-content">
                    <div className="ctext-wrap">

                        <div className="ctext-wrap-content">
                            <ul className="list-inline message-img  mb-0">
                                <li className="list-inline-item message-img-list me-2 ms-0">
                                    <div>
                                        <a className="popup-img d-inline-block m-1 cuson-pointer" title="Project 1" onClick={viewImg}>
                                            <img src={FileUtil.genarateUrlGetImage(item.messPath, CommonConstant.FILE_THUMB)}
                                                // onLoad={(e) => { onLoadedImage() }}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../../../assets/images/not_image.png")
                                                }}
                                                className="rounded border img-thumb-default-height" alt="" />
                                        </a>
                                    </div>
                                    <div className="message-img-link">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <a className="fw-medium cuson-pointer cuson-pointer" onClick={downloadFile}>
                                                    <i className="ri-download-2-line"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{(item.createdDate ? dayjs(item.createdDate).format('YYYY-MM-DD HH:mm') : 'underfined')}</span></p>
                        </div>
                        {
                            item.userUid === userInfo.userUid && (<div className="dropdown align-self-start">
                                <a className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ri-more-2-fill"></i>
                                </a>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" onClick={handleDelete}>Remove <i className="ri-delete-bin-line float-end text-muted"></i></a>
                                </div>
                            </div>)
                        }
                    </div>

                    <div className="conversation-name">{item.userUid !== userInfo.userUid ? item?.fullName : <>&nbsp;</>}</div>
                </div>

            </div>
        </li>
    )
}
