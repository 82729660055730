import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { HeadersUtil } from "../utils/headersUtil";
import { CommonConstant } from "../constants/CommonConstant";

const initialState: any = {
    onlineUsers: []
}

export const getOnlineUsers = createAsyncThunk('online/getOnlineUsers', async () => {
    return axios.get(process.env.REACT_APP_API_URL + "/online/getOnlineUsers", {
        headers: HeadersUtil.getHeadersAuth()
    })
});

const onlineSlice = createSlice({
    name: 'online',
    initialState,
    reducers: {
        updateListOnlineUser: (state, action) => {
            if (action.payload.type == 1) {
                state.onlineUsers = [...state.onlineUsers, action.payload.userUid]
            } else if (action.payload.type == 0) {
                let arr: any = [...state.onlineUsers]
                let index = arr.findIndex((e: any) => e == action.payload.userUid)
                if (index != -1) {
                    arr.splice(index, 1)
                    state.onlineUsers = arr
                }
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(getOnlineUsers.pending, state => {
        })
        builder.addCase(getOnlineUsers.fulfilled, (state, action: any) => {
            if (action.payload.data.status == CommonConstant.RESULT_OK) {
                state.onlineUsers = action.payload.data.responseData || []
            }
        })
        builder.addCase(getOnlineUsers.rejected, (state, action: any) => {
            state.onlineUsers = []
        })
    },
});

export default onlineSlice.reducer
export const { updateListOnlineUser } = onlineSlice.actions