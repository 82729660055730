import React, { useRef, useState, useEffect } from 'react'
import UserFav from './comp/UserFav'
import { CommonConstant } from '../../../constants/CommonConstant';
import { RoomService } from '../../../services/chat/mess/RoomService';
import ItemMess from './comp/ItemMess';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { MessService } from '../../../services/chat/mess/MessService';
import { ContactService } from '../../../services/chat/contacts/ContactService';

export default function Messager() {

    const listInnerRef = useRef<any>();
    const [rooms, setRooms] = useState<any>([])
    const [favs, setFav] = useState<any>([])
    const isLoad = useRef(true);
    const roomIdSel = useRef(0);
    const offset = useRef(0);

    const chat = useAppSelector(state => state.chat.selectedRoom)

    useEffect(() => {
        if (chat.roomId != 0) {
            roomIdSel.current = chat.roomId
        }
    }, [chat])

    useEffect(() => {
        const tempSend = MessService.getInstance()._sendMess.subscribe((messDto: any) => {
            if (messDto.id) {
                var array: any = [...rooms]
                var index = array.findIndex((e: any) => e.id == messDto.roomId)
                if (index == -1) return;
                array[index]['messDto'] = messDto
                let room = array[index];
                room['updatedDate'] = new Date();
                room['lastViewed'] = 'Y';
                array.splice(index, 1)
                setRooms([room, ...array])
                MessService.getInstance().change({})
            }
        })
        const tempReceive = MessService.getInstance()._receiveMess.subscribe((messDto: any) => {
            if (messDto.id) {

                if (messDto.roomId == roomIdSel.current) {
                    MessService.getInstance().updateLastView(roomIdSel.current).then((resp: any) => {

                    }).catch((err: any) => {

                    })
                }

                var array: any = [...rooms]
                var index = array.findIndex((e: any) => e.id == messDto.roomId)
                if (index == -1) return;
                array[index]['messDto'] = messDto
                let room = array[index];
                room['updatedDate'] = new Date();
                if (array[index]['lastViewed'] == 'Y' && array[index]['id'] != roomIdSel.current) {
                    array[index]['lastViewed'] = 'N'
                }
                array.splice(index, 1)
                MessService.getInstance().changeReceiver({})
                setRooms([room, ...array])
            }
        })

        const unsubSendRoom = RoomService.getInstance()._sendRoomModel.subscribe((resp: any) => {
            if (resp.id) {
                setRooms([resp, ...rooms]);
                RoomService.getInstance().change({});
            } else if (resp.leaveRoomId) {
                var array: any = [...rooms]
                var index = array.findIndex((e: any) => e.id == resp.leaveRoomId);
                if (index == -1) return;
                array.splice(index, 1)
                setRooms(array);
                RoomService.getInstance().change({});
            }
        })

        return () => {
            tempSend.unsubscribe();
            tempReceive.unsubscribe();
            unsubSendRoom.unsubscribe();
        }

    }, [rooms])

    const loadMore = (type: number) => {
        isLoad.current = false;
        RoomService.getInstance().getListRooms({ limit: CommonConstant.LIMIT, offset: offset.current, keySearch: "" })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    if (resp.data.responseData.length == CommonConstant.LIMIT) {
                        isLoad.current = true;
                    }
                    if (type == 0) {
                        setRooms(resp.data.responseData)
                    } else {
                        setRooms((prev: any) => {
                            return [...prev, ...resp.data.responseData]
                        });
                    }
                } else {

                }
            })
            .catch((err: any) => {

            })
        offset.current += CommonConstant.LIMIT;
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isNearBottom = scrollTop + clientHeight + 1 >= scrollHeight;
            if (isNearBottom) {
                // DO SOMETHING HERE
                if (isLoad.current) {
                    loadMore(1)
                }
            }
        }
    };

    useEffect(() => {
        loadMore(0);
        ContactService.getInstance().getListFav().then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                setFav(resp.data.responseData);
            }
        }).catch((err: any) => {

        })
    }, [])

    useEffect(() => {
        listInnerRef.current = document.getElementById('_recent-mess-list')?.getElementsByClassName("simplebar-content-wrapper")[0];
        const listInnerElement: any = listInnerRef.current;
        if (listInnerElement) {
            listInnerElement.addEventListener("scroll", onScroll);
            // Clean-up
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [rooms])

    return (
        <div>
            <div className="px-4 pt-4">
                <h4 className="mb-4">Chats</h4>

            </div>
            <div className="px-4 pb-4" dir="ltr">
                <div className="owl-carousel owl-theme" id="user-status-carousel" style={{ display: "block" }}>
                    <div className="owl-stage-outer">
                        <div className="owl-stage row">
                            {
                                favs.length > 0 &&
                                favs.map((p: any) => {
                                    return <UserFav key={p.userUid} item={p} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <h5 className="mb-3 px-3 font-size-16">Recent</h5>
                <div id='_recent-mess-list' className="chat-message-list-2 px-2" data-simplebar>
                    <ul className="list-unstyled chat-list chat-user-list">
                        {
                            rooms.length > 0 &&
                            rooms.map((p: any) => {
                                return <ItemMess key={p.id} data={p} />
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
