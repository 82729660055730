import React, { useEffect, useState } from 'react'

export default function NewsHeader(props: any) {
    const { onSearch, textSearch } = props

    const [keySearch, setKeySearch] = useState("");
    const handleChangeText = (event: any) => {
        setKeySearch(event.target.value)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            onSearch(keySearch)
        }
    }

    useEffect(() => {
        if (textSearch !== "" && textSearch != keySearch) {
            setKeySearch(textSearch)
        }
    }, [textSearch])

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-3 pe-4 pb-3">
            <div className="form-group mb-0 icon-input">
                <i className="feather-search font-sm text-grey-400"></i>
                <input type="text" value={keySearch} onChange={handleChangeText} onKeyUp={handleKeyPress}
                    placeholder="Start typing to search..." style={{ width: "100%", outline: "none" }} className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl theme-dark-bg" />
            </div>
        </div>
    )
}
