import React from 'react'
import AppCropper from '../../../../components/AppCropper'
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { CommonConstant } from '../../../../constants/CommonConstant';
import { updateUserInfo } from '../../../../reducers/userSlice';

export default function EditAvatar(props: any) {

    const { onClose } = props;

    const userInfo = useAppSelector((state) => state.userInfo.userInfo);

    const dispatch = useAppDispatch();

    const handleSaveAvatar = (tccoFile: any) => {
        dispatch(updateUserInfo({
            ...userInfo,
            imgPath: tccoFile.newFleNm
        }))
        onClose();
    }

    return (
        <div className="modal-body p-4">
            <AppCropper aspect={1} typeImage={CommonConstant.FILE_ORIGIN} imgPath={userInfo?.imgPath} returnTccoFile={handleSaveAvatar} />
        </div>
    )
}
