import axios from "axios";
import { Paging } from "../../../model/Paging";
import { ApiUrlUtil } from "../../../utils/apiUrlUtil";
import { ParamUtil, RequestParam } from "../../../utils/paramUtil";
import { HeadersUtil } from "../../../utils/headersUtil";
import { Subject } from 'rxjs';

export class MessService {

    private static _messService: MessService;

    public _sendMess = new Subject();
    public _receiveMess = new Subject();

    public static getInstance(): MessService {
        if (!MessService._messService) {
            MessService._messService = new MessService();
        }
        return MessService._messService;
    }

    public getListMessByRoomId(model: Paging, roomId: number) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/mess/getListMess/${roomId}/getListMessByRoomId`, params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public countNewMess() {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + "/vts/mess/countNewMess");
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public updateLastView(roomId: number) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/mess/update/${roomId}/updateLastView`);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public getListAttachByRoomId(model: Paging, roomId: number) {
        const params: RequestParam[] = ParamUtil.toRequestParams(model);
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/mess/getListMess/${roomId}/getListAttachByRoomId`, params);
        return axios.get(url, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }

    public change(messDto: any) {
        MessService._messService._sendMess.next(messDto);
    }

    public changeReceiver(messDto: any) {
        MessService._messService._receiveMess.next(messDto);
    }

    public messageRecall(dto: any) {
        const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_API_URL + `/vts/mess/messageRecall`);
        return axios.post(url, dto, {
            headers: HeadersUtil.getHeadersAuth()
        })
    }
}