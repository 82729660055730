import React, { useState, useEffect } from 'react'
import { CommonUtil } from '../../../../utils/CommonUtil'
import dayjs from 'dayjs'
import { NotificationService } from '../../../../services/chat/notifications/NotificationService'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { FileUtil } from '../../../../utils/FileUtil'

export default function PopupNotification(props: any) {

    const { data, callBack, onClose } = props
    const [err, setErr] = useState(0);
    const [model, setModel] = useState({ userUid: "", imgPath: "", fullName: "T", dob: new Date(), gender: true, email: "", phone: "" })

    useEffect(() => {
        NotificationService.getInstance().getNotificationDetail({ isNew: data.isNew, noticeType: data.noticeType }, data.id).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                setModel((prev: any) => {
                    return {
                        ...prev,
                        ...resp.data.responseData
                    }
                })
            } else {
                setErr(1);
            }
        }).catch((err: any) => {
            setErr(1);
        })
    }, [])

    useEffect(() => {
        if (err == 1) {
            callBack(data.id)
        }
    }, [err])

    const handleFriendReq = (type: number) => {
        NotificationService.getInstance().reqFriend({ receiverUid: model.userUid, reqType: (type == 0 ? CommonConstant.REJECTED_FRI_STA : CommonConstant.APPROVED_FRI_STA) }).then((resp: any) => {
            if (resp.data.status == CommonConstant.RESULT_OK) {
                onClose()
                callBack(data.id)
            }
        }).catch((err: any) => {

        })
    }
    return (
        <>
            {
                err == 0 ? (
                    <>
                        <div className="modal-body">
                            <div className="text-center">
                                <div className="mb-4">
                                    {
                                        (model.imgPath && model.imgPath != '') ? (
                                            <div className="chat-user-img contact align-self-center me-3 ms-0">
                                                <img src={FileUtil.genarateUrlGetImage(model.imgPath, CommonConstant.FILE_THUMB)}
                                                    onError={(event: any) => {
                                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                                    }}
                                                    className="rounded-circle avatar-xl-96" alt="" />
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <div className='contact'>
                                                    <div className="avatar-xl-96">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-primary display-6">
                                                            {
                                                                CommonUtil.generateCharacterAvatar(model.fullName)
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                <h5 className="font-size-16 mb-1 text-truncate">{model.fullName}</h5>

                                <div className="ps-4 pe-4">
                                    <div className="text-muted">
                                        <p>{data.content}</p>
                                    </div>

                                    <div className="row">
                                        <div className='col-12 col-lg-5 row text-start'>
                                            <div className="col-12">
                                                <p className="text-muted mb-1">
                                                    Birthday
                                                </p>
                                                <p className="font-size-14">
                                                    <span className="me-1">
                                                        <i className="ri-cake-2-line"></i>
                                                    </span>
                                                    {dayjs(model.dob).format("YYYY-MM-DD")}</p>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-muted mb-1">
                                                    Gender
                                                </p>
                                                <p className="font-size-14">
                                                    <span className="me-1">
                                                        {
                                                            !model.gender ? <i className="ri-men-line"></i> : <i className="ri-women-line"></i>
                                                        }
                                                    </span>
                                                    {CommonUtil.convertGender(model.gender)}</p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-7 row text-start'>
                                            <div className="col-12">
                                                <p className="text-muted mb-1">
                                                    Email
                                                </p>
                                                <p className="font-size-14">
                                                    <span className="me-1">
                                                        <i className="ri-mail-send-line"></i>
                                                    </span>
                                                    {model.email}</p>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-muted mb-1">
                                                    Phone
                                                </p>
                                                <p className="font-size-14">
                                                    <span className="me-1">
                                                        <i className="ri-phone-line"></i>
                                                    </span>
                                                    {model.phone || "unavailable"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => handleFriendReq(0)}>Reject</button>
                            <button type="button" className="btn btn-primary" onClick={() => handleFriendReq(1)}>Approve</button>
                        </div>
                    </>
                ) : (
                    <div className="modal-body">
                        <div className="text-center border-bottom">
                            <div className="mb-4">
                                <img src={require("../../../../../assets/images/404.gif")}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/404.gif")
                                    }}
                                    alt="" style={{ maxWidth: "400px" }} />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
