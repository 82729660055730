import React, { useState } from 'react'
import { ContactService } from '../../../../services/chat/contacts/ContactService'
import { CommonConstant } from '../../../../constants/CommonConstant'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { CommonUtil } from '../../../../utils/CommonUtil'
import { FileUtil } from '../../../../utils/FileUtil'
import { useNavigate } from 'react-router-dom'
import { setSelectedRoom } from '../../../../reducers/chatSlice'

export default function ItemContact(props: any) {
    const { data } = props

    const [model, setModel] = useState(data)

    const userInfo = useAppSelector((state) => state.userInfo.userInfo);
    const onlineUsers = useAppSelector(state => state.onlineUsers.onlineUsers)

    const reqOrUnReqFriend = () => {
        const reqType = model.reqFriend;
        setModel((prev: any) => {
            return {
                ...prev,
                reqFriend: prev.reqFriend == 'N' ? 'Y' : 'N',
                reqUserUid: prev.reqUserUid == '' ? userInfo.userUid : ''
            }
        })
        ContactService.getInstance().reqFriend({ receiverUid: model.userUid, reqType: reqType == 'N' ? CommonConstant.REQ_FRIEND : CommonConstant.UN_REQ_FRIEND })
            .then((resp: any) => {
                if (resp.data.status == CommonConstant.RESULT_OK) {

                } else {
                    // toast.error("Server error")
                    setModel((prev: any) => {
                        return {
                            ...prev,
                            reqFriend: prev.reqFriend == 'N' ? 'Y' : 'N',
                            reqUserUid: prev.reqUserUid == '' ? userInfo.userUid : ''
                        }
                    })
                }
            })
            .catch((err: any) => {
                // toast.error("Server error")
                setModel((prev: any) => {
                    return {
                        ...prev,
                        reqFriend: prev.reqFriend == 'N' ? 'Y' : 'N',
                        reqUserUid: prev.reqUserUid == '' ? userInfo.userUid : ''
                    }
                })
            })
    }

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return (
        <li className="unread">
            <a title={`Add friend ${model.fullName}`}>
                <div className="d-flex">
                    {
                        (model.imgPath && model.imgPath != '') ? (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: model.userUid }])}`}>
                                <img src={FileUtil.genarateUrlGetImage(model.imgPath, CommonConstant.FILE_THUMB)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../../../assets/images/users/user-dummy-img.jpg")
                                    }}
                                    className="rounded-circle avatar-xs" alt="" />
                                <span className="user-status"></span>
                            </div>
                        ) : (
                            <div className={`chat-user-img align-self-center me-3 ms-0 ${CommonUtil.checkOnline(onlineUsers, [{ userUid: model.userUid }])}`}>
                                <div className="avatar-xs">
                                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {
                                            CommonUtil.generateCharacterAvatar(model.fullName)
                                        }
                                    </span>
                                </div>
                                <span className="user-status"></span>
                            </div>
                        )
                    }
                    <div className="flex-grow-1 overflow-hidden">
                        <h5 onClick={() => {
                            dispatch(setSelectedRoom({
                                roomId: 0,
                                roomType: '',
                                roomName: '',
                                roomImg: ''
                            }))
                            navigate("/chat/profile?profileId=" + model.userUid)
                        }} className="text-truncate font-size-15 mb-1">{model.fullName}</h5>
                        <p className="chat-user-message text-truncate mb-0"><i className="ri-phone-fill align-middle me-1 ms-0"></i> {model.phone || "unavailable"}</p>
                    </div>
                    <div className="contacts-lst" onClick={reqOrUnReqFriend}>
                        {
                            model.reqFriend == 'N' ? <i className="ri-user-add-line"></i> : ((userInfo?.userUid && userInfo.userUid == model.reqUserUid) ? <i className="ri-user-unfollow-line" style={{ color: "#ff9c1c" }}></i> : <></>)
                        }
                    </div>
                </div>
            </a>
        </li>
    )
}
