import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CommonUtil } from '../../utils/CommonUtil';
import { FileUtil } from '../../utils/FileUtil';
import { CommonConstant } from '../../constants/CommonConstant';
import Plyr from 'plyr-react'
import { NewsService } from '../../services/NewsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hook';
import Swal from 'sweetalert2';

export default function NewsItem(props: any) {
    const { selectItem, removeNewsfeedFnc, tagClick } = props
    const [item, setItem] = useState(props.item);
    const [focusInterac, setForcusInterac] = useState(0);
    const [listInteract, setListInteract] = useState<any>([]);
    const firstTime = useRef(true)

    const userInfo = useAppSelector((state: any) => state.userInfo.userInfo);
    const location = useLocation()
    const url = location.pathname

    const clickInterac = (type: number) => {
        setForcusInterac(focusInterac == type ? 0 : type)
    }

    useEffect(() => {
        if (focusInterac == 2 && firstTime.current) {
            NewsService.getInstance().getListInteract(item.id).then(resp => {
                if (resp.data.status == CommonConstant.RESULT_OK) {
                    setListInteract(resp.data.responseData)
                    // firstTime.current = false;
                } else if (resp.data.status == CommonConstant.RESULT_NG) {
                    setListInteract([])
                }
            }).catch(error => {

            })
        }
    }, [focusInterac])

    const viewDetail = () => {
        selectItem(item)
    }

    const eventListener = useCallback((e: any) => {
        var container = $(".vts-interac .emoji-bttn");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            setForcusInterac(0)
        }
    }, [])

    const removeNewsfeed = () => {

        Swal.fire({
            title: `Confirm`,
            text: `Do you want to delete this post ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#89B449',
            cancelButtonColor: '#E68A8C',
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                removeNewsfeedFnc(item.id)
            }
        })
    }

    useEffect(() => {
        document.addEventListener('mouseup', eventListener);
        return () => {
            document.removeEventListener('mouseup', eventListener);
        }
    }, [])

    // const generate = useCallback(() => {

    // }, [])

    const [plyrs, setPlyrs] = useState<any>([]);

    useEffect(() => {
        const newPlyrs = item.videos.map((p: any, index: number) => {
            return (
                <Plyr
                    key={index}
                    options={{
                        controls: [
                            "play",
                            "play-large",
                            "progress",
                            "current-time",
                            "duration",
                            "volume",
                            "fullscreen",
                            "mute",
                        ]
                    }}
                    source={
                        {
                            type: 'video',
                            sources: [{ src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm }]
                        }}
                />
            );
        });
        setPlyrs(newPlyrs);
        return () => {
            // You may need to implement logic here to destroy Plyr instances if necessary
        };
    }, []);

    const generate = () => {
        if (item.photos.length != 0) {
            if (item.photos.length >= 5) {
                return <>
                    <div className="row ps-2 pe-2">
                        {
                            item.photos.map((p: any, index: number) => {
                                if (index < 2) {
                                    return <div key={p.atchFleSeq} className="col-xs-6 col-sm-6 p-1 bg-image">
                                        <a onClick={viewDetail} data-lightbox="roadtrip" className='newfeed-post'>
                                            <img className="rounded-3 w-100 img-height" alt="image"
                                                src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../assets/images/not_image.png")
                                                }} />
                                        </a>
                                    </div>
                                }
                            })
                        }
                    </div>
                    <div className="row ps-2 pe-2">
                        {
                            item.photos.map((p: any, index: number) => {
                                if (index > 1 && index < 5) {
                                    return <div key={p.atchFleSeq} className="col-xs-4 col-sm-4 p-1 bg-image">
                                        <a onClick={viewDetail} data-lightbox="roadtrip" className={`${(item.photos.length > 5 && index == 4) ? 'position-relative' : ''} newfeed-post`}>
                                            <img className="rounded-3 w-100 img-height" alt="image"
                                                src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../assets/images/not_image.png")
                                                }} />
                                            {
                                                (item.photos.length > 5 && index == 4) && <span className="img-count font-sm text-white ls-3 fw-600"><b>+{item.photos.length - 5}</b></span>
                                            }
                                        </a>
                                    </div>
                                }
                            })
                        }
                    </div>
                </>
            } else if (item.photos.length >= 3) {
                return <>
                    <div className="row ps-2 pe-2">
                        {
                            item.photos.map((p: any, index: number) => {
                                if (index < 3) {
                                    return <div key={p.atchFleSeq} className="col-xs-4 col-sm-4 p-1 bg-image">
                                        <a onClick={viewDetail} data-lightbox="roadtrip" className={`${(item.photos.length > 3 && index == 2) ? 'position-relative' : ''} newfeed-post`}>
                                            <img className="rounded-3 w-100 img-height" alt="image"
                                                src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../assets/images/not_image.png")
                                                }} />
                                            {
                                                (item.photos.length > 3 && index == 2) && <span className="img-count font-sm text-white ls-3 fw-600"><b>+1</b></span>
                                            }
                                        </a>
                                    </div>
                                }
                            })
                        }
                    </div>
                </>
            } else if (item.photos.length >= 2) {
                return <>
                    <div className="row ps-2 pe-2">
                        {
                            item.photos.map((p: any) => {
                                return <div key={p.atchFleSeq} className="col-xs-6 col-sm-6 p-1 bg-image">
                                    <a onClick={viewDetail} className='newfeed-post' data-lightbox="roadtrip">
                                        <img
                                            src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                            onError={(event: any) => {
                                                event.target.src = require("../../../assets/images/not_image.png")
                                            }}
                                            className="rounded-3 w-100 img-height" alt="image" /></a></div>
                            })
                        }
                    </div>
                </>
            } else {
                return <>
                    <div className="row ps-2 pe-2">
                        {
                            item.photos.map((p: any) => {
                                return <div key={p.atchFleSeq} className="col-xs-12 col-sm-12 p-1 bg-image"><a onClick={viewDetail} data-lightbox="roadtrip"><img
                                    src={FileUtil.genarateUrlGetImage(p.newFleNm, CommonConstant.FILE_ORIGIN)}
                                    onError={(event: any) => {
                                        event.target.src = require("../../../assets/images/not_image.png")
                                    }}
                                    className="rounded-3" alt="image" style={{ maxWidth: "100%", padding: "1rem" }} /></a></div>
                            })
                        }
                    </div>
                </>
            }
        } else if (item.videos.length != 0) {
            return <div className="row ps-2 pe-2">
                {
                    plyrs.map((plyr: any, index: number) => (
                        <div className={`col-xs-${item.videos.length % 2 != 0 && index == item.videos.length - 1 ? '12' : '6'} col-sm-${item.videos.length % 2 != 0 && index == item.videos.length - 1 ? '12' : '6'} p-1`} key={index}>
                            {plyr}
                        </div>
                    ))
                    // item.videos.map((p: any, index: number) => {
                    //     return <div key={p.atchFleSeq} className={`col-xs-${item.videos.length % 2 != 0 && index == item.videos.length - 1 ? '12' : '6'} col-sm-${item.videos.length % 2 != 0 && index == item.videos.length - 1 ? '12' : '6'} p-1`}>
                    //         <Plyr
                    //             options={{
                    //                 controls: [
                    //                     "play",
                    //                     "play-large",
                    //                     "progress",
                    //                     "current-time",
                    //                     "duration",
                    //                     "volume",
                    //                     "fullscreen",
                    //                     "mute",
                    //                 ]
                    //             }}
                    //             source={{
                    //                 type: 'video',
                    //                 sources: [{
                    //                     src: process.env.REACT_APP_API_MEDIA_URL + "/public/media/load?atchFleSeqNm=" + p.newFleNm
                    //                 }]
                    //             }} />
                    //     </div>
                    // })
                }
            </div>
        }
    }

    const interact = (type: any) => {
        if (item.interact != type) {
            setItem({
                ...item,
                interact: type,
                totalInteract: (type == "04-00" && (item.interact != "04-00" && item.interact != undefined)) ? item.totalInteract - 1 : (type != "04-00" && (item.interact == "04-00" || item.interact == undefined)) ? item.totalInteract + 1 : item.totalInteract
            })
            NewsService.getInstance().interact({ id: item.id, interactType: type }).then((resp: any) => {

            }).catch((error: any) => {

            })
        }
    }

    const navigate = useNavigate()

    const generateInteract = (interactType: string) => {
        if (interactType == CommonConstant.INTERACT_LIKE) {
            // return <i className="feather-thumbs-up text-white vts-curson bg-primary-gradiant btn-round-xs"></i>
            return <img className='vts-curson' src={require("../../../assets/images/interact/icons8-facebook-like-48.png")} width={25} alt="image" />
        } else if (interactType == CommonConstant.INTERACT_FAVARITE) {
            // return <i className="feather-heart text-white vts-curson bg-red-gradiant btn-round-xs font-xss"></i>
            return <img className='vts-curson' src={require("../../../assets/images/interact/icons8-love-circled-48.png")} width={25} alt="image" />
        } else if (interactType == CommonConstant.INTERACT_03) {
            // return <i className="mdi mdi-emoticon-excited text-white vts-curson bg-2-gradiant btn-round-xs font-xss"></i>
            return <img className='vts-curson' src={require("../../../assets/images/interact/icons8-haha-94.png")} width={25} alt="image" />
        } else if (interactType == CommonConstant.INTERACT_04) {
            // return <i className="mdi mdi-emoticon-sad mdi-dark text-white vts-curson bg-3-gradiant btn-round-xs font-xss"></i>
            return <img className='vts-curson' src={require("../../../assets/images/interact/icons8-angry-94.png")} width={25} alt="image" />
        }
    }

    const generateClass = (interactType: string) => {
        switch (interactType) {
            case CommonConstant.INTERACT_LIKE: {
                // return "feather-thumbs-up bg-primary-gradiant"
                return <img className='vts-curson me-1' onClick={() => clickInterac(1)} src={require("../../../assets/images/interact/icons8-facebook-like-48.png")} width={32} alt="image" />
            }
            case CommonConstant.INTERACT_FAVARITE: {
                // return "feather-heart bg-red-gradiant"
                return <img className='vts-curson me-1' onClick={() => clickInterac(1)} src={require("../../../assets/images/interact/icons8-love-circled-48.png")} width={32} alt="image" />
            }
            case CommonConstant.INTERACT_03: {
                // return "mdi mdi-emoticon-excited bg-2-gradiant"
                return <img className='vts-curson me-1' onClick={() => clickInterac(1)} src={require("../../../assets/images/interact/icons8-haha-94.png")} width={32} alt="image" />
            }
            case CommonConstant.INTERACT_04: {
                // return "mdi mdi-emoticon-sad mdi-dark bg-3-gradiant"
                return <img className='vts-curson me-1' onClick={() => clickInterac(1)} src={require("../../../assets/images/interact/icons8-angry-94.png")} width={32} alt="image" />
            }
            default: {
                // return "feather-thumbs-up bg-like"
                return <img className='vts-curson me-1' onClick={() => clickInterac(1)} src={require("../../../assets/images/interact/icons8-like-default.png")} width={27} alt="image" />
            }
        }
    }

    const caculator = useMemo(() => {
        let map: any = {}
        for (let i = 0; i < listInteract.length; i++) {
            if (map[listInteract[i].INTERACT_TYPE] != undefined) {
                map[listInteract[i].INTERACT_TYPE] = map[listInteract[i].INTERACT_TYPE] + 1;
            } else {
                map[listInteract[i].INTERACT_TYPE] = 1
            }
        }
        return map;
    }, [listInteract])

    const clickTags = (tagNm: string) => {
        tagClick(tagNm)
    }

    const extractUrls = (text: string) => {
        text = text.replace(/<[^>]*>/g, '')
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urls = text.match(urlRegex) || [];
        for (let i = 0; i < urls.length; i++) {
            text = text.replace(urls[i], `<a href='${urls[i]}' class='new-items-hover' target='_blank'>${urls[i]}</a>`)
        }
        text = text.replaceAll("\n", "<br/>");
        return text;
    }

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
            <div className="card-body p-0 d-flex">
                <figure className="avatar me-3">
                    <img src={FileUtil.genarateUrlGetImage(item.createdBy?.imgPath, CommonConstant.FILE_THUMB)}
                        onError={(event: any) => {
                            event.target.src = require("../../../assets/images/users/user-dummy-img.jpg")
                        }}
                        alt="image" className="shadow-sm rounded-circle w45" />
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 vts-curson"><span onClick={() => {
                    navigate("/chat/profile?profileId=" + item.createdBy.userUid)
                }} className='new-items-hover'>{item.createdBy?.fullName}</span> <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{CommonUtil.dateComparation(new Date(item.pudDate))} <i className="feather-clock"></i></span></h4>
                {
                    (userInfo?.userUid == item.createdBy.userUid && url.startsWith("/chat/profile")) && <a onClick={removeNewsfeed} className="ms-auto vts-curson">
                        <i className="mdi mdi-close text-grey-900 bg-greylight font-xss" style={{ width: "30px", height: "30px", borderRadius: "30px", display: "inline-block", lineHeight: "30px", textAlign: "center" }}></i>
                    </a>
                }
            </div>
            <div className="card-body p-0 me-lg-5">
                <p className="fw-500 text-grey-500 lh-26 w-100 mb-1" style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: extractUrls(item.content) }}></p>
            </div>
            <div className="card-body p-0">
                {
                    item.tags.length > 0 &&
                    item.tags.map((p: any, index: number) => {
                        return <a key={index} onClick={() => clickTags(p)} className="fw-600 text-primary me-2 vts-curson new-items-hover" style={{ fontSize: "12px" }}>#{p}</a>
                    })
                }
            </div>
            <div className="card-body d-block p-0">
                {
                    generate()
                }
            </div>
            <div className="card-body d-flex p-0 mt-3 vts-interac">
                <a className="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                    {/* <i onClick={() => clickInterac(1)} className={`${generateClass(item.interact)} vts-curson text-white me-1 btn-round-xs font-xss`}></i> */}
                    {
                        generateClass(item.interact)
                    }
                    <span className="vts-curson new-items-hover" onClick={() => clickInterac(2)}>{item.totalInteract} Interact</span>
                </a>
                <div className={`emoji-wrap ${focusInterac == 1 ? 'active' : ''}`}>
                    <ul className="emojis list-inline mb-0">
                        <li className="emoji list-inline-item">
                            <img className='vts-curson' onClick={() => interact(CommonConstant.INTERACT_LIKE)} src={require("../../../assets/images/interact/icons8-facebook-like-48.png")} width={32} alt="image" />
                            {/* <i onClick={() => interact(CommonConstant.INTERACT_LIKE)} className="feather-thumbs-up text-white vts-curson bg-primary-gradiant btn-round-xs font-xss"></i> */}
                        </li>
                        <li className="emoji list-inline-item">
                            <img className='vts-curson' onClick={() => interact(CommonConstant.INTERACT_FAVARITE)} src={require("../../../assets/images/interact/icons8-love-circled-48.png")} width={32} alt="image" />
                            {/* <i onClick={() => interact(CommonConstant.INTERACT_FAVARITE)} className="feather-heart text-white vts-curson bg-red-gradiant btn-round-xs font-xss"></i> */}
                        </li>
                        <li className="emoji list-inline-item">
                            <img className='vts-curson' onClick={() => interact(CommonConstant.INTERACT_03)} src={require("../../../assets/images/interact/icons8-haha-94.png")} width={32} alt="image" />
                            {/* <i onClick={() => interact(CommonConstant.INTERACT_03)} className="mdi mdi-emoticon-excited text-white vts-curson bg-2-gradiant btn-round-xs font-xss"></i> */}
                        </li>
                        <li className="emoji list-inline-item">
                            <img className='vts-curson' onClick={() => interact(CommonConstant.INTERACT_04)} src={require("../../../assets/images/interact/icons8-angry-94.png")} width={32} alt="image" />
                            {/* <i onClick={() => interact(CommonConstant.INTERACT_04)} className="mdi mdi-emoticon-sad mdi-dark text-white vts-curson bg-3-gradiant btn-round-xs font-xss"></i> */}
                        </li>
                        <li className="emoji list-inline-item">
                            <img className='vts-curson' onClick={() => interact("04-00")} src={require("../../../assets/images/interact/icons8-cancel-48.png")} width={32} alt="image" />
                            {/* <i onClick={() => interact("04-00")} className="feather-x-circle text-white vts-curson bg-like btn-round-xs font-xss"></i> */}
                        </li>
                    </ul>
                </div>

                <div className={`vts-scroll emoji-wrap ${focusInterac == 2 ? 'active' : ''}`} style={{ padding: "5px 5px", borderRadius: "5px" }}>
                    <ul className="emojis mb-0 vts-scroll-interact" style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li >
                            <div className="card-body d-flex">
                                <a className="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                    {/* <i className={`feather-thumbs-up bg-primary-gradiant vts-curson text-white me-1 btn-round-xs font-xss`}></i> */}
                                    <img className='vts-curson' src={require("../../../assets/images/interact/icons8-facebook-like-48.png")} width={32} alt="image" />
                                    <span >{caculator[CommonConstant.INTERACT_LIKE] || 0}</span>
                                </a>
                                <a className="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                    {/* <i className={`feather-heart bg-red-gradiant vts-curson text-white me-1 btn-round-xs font-xss`}></i> */}
                                    <img className='vts-curson' src={require("../../../assets/images/interact/icons8-love-circled-48.png")} width={32} alt="image" />
                                    <span >{caculator[CommonConstant.INTERACT_FAVARITE] || 0}</span>
                                </a>
                                <a className="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                    {/* <i className={`mdi mdi-emoticon-excited bg-2-gradiant vts-curson text-white me-1 btn-round-xs font-xss`}></i> */}
                                    <img className='vts-curson' src={require("../../../assets/images/interact/icons8-haha-94.png")} width={32} alt="image" />
                                    <span >{caculator[CommonConstant.INTERACT_03] || 0}</span>
                                </a>
                                <a className="emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                    {/* <i className={`mdi mdi-emoticon-sad mdi-dark bg-3-gradiant vts-curson text-white me-1 btn-round-xs font-xss`}></i> */}
                                    <img className='vts-curson' src={require("../../../assets/images/interact/icons8-angry-94.png")} width={32} alt="image" />
                                    <span >{caculator[CommonConstant.INTERACT_04] || 0}</span>
                                </a>
                            </div>
                        </li>
                        {
                            listInteract.length > 0 &&
                            listInteract.map((p: any) => {
                                return <li key={p.USER_UID} className="emoji">
                                    <div className="card-body d-flex" style={{ padding: "1px 16px" }}>
                                        <figure className="avatar me-3" style={{ marginBottom: "2px", position: "relative" }}>
                                            <img src={FileUtil.genarateUrlGetImage(p.imgPath, CommonConstant.FILE_THUMB)}
                                                onError={(event: any) => {
                                                    event.target.src = require("../../../assets/images/users/user-dummy-img.jpg")
                                                }}
                                                alt="image" className="shadow-sm rounded-circle w45" />
                                            <span className="ms-1 font-xssss fw-500 mt-1 lh-3 text-grey-500" style={{
                                                position: "absolute",
                                                bottom: 0,
                                                right: 0
                                            }}>
                                                {
                                                    generateInteract(p.INTERACT_TYPE)
                                                }
                                            </span>
                                        </figure>
                                        <h4 onClick={() => {
                                            navigate("/chat/profile?profileId=" + p.USER_UID)
                                        }} className="fw-700 text-grey-900 font-xssss mt-1 vts-curson">{p.FULL_NAME}

                                        </h4>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>

                <a onClick={viewDetail} className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss vts-curson"><i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span className="d-none-xss new-items-hover">{item.totalComment} Comment</span></a>
                <a className="ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span className="d-none-xs">Share</span></a>
            </div>
        </div>
    )
}
